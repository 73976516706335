import React, { useEffect, useState } from 'react'
import { Router } from 'react-router-dom'

import auth from '../components/authentic'
import AuthPages from '../containers/Login/AuthPages'
import history from '../shared/history'

const authLinks = [
  '/login', '/signup', '/change-password-request', '/change-password', '/confirm'
]

const AuthGuard = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(auth.isLoggedIn())

  useEffect(() => {
    const firstPath = `/${history.location.pathname.split('/')[1]}`
    if (!isLoggedIn && !authLinks.includes(firstPath)) {
      history.push('/login')
    }

    auth.client.verifyToken(function (err) {
      if (err) {
        setIsLoggedIn(false)
        auth.client.logout()
      }
    })
  })
  return (
    <Router history={history}>
      {!isLoggedIn
        ? <AuthPages setIsLoggedIn={setIsLoggedIn} />
        : props.children}
    </Router>
  )
}

export default AuthGuard
