import React from 'react'

const Hiring = ({ hiring }) => {
  return (
    <>
      <h2>Hiring</h2>
      <p>Developer Type</p>
      <p>Stage 1 / Stage 2 / Hired</p>

      <h3> Node </h3>
      <p> {hiring.nodeS1} / {hiring.nodeS2} / {hiring.nodeHired} </p>

      <h3> React </h3>
      <p> {hiring.reactS1} / {hiring.reactS2} / {hiring.reactHired} </p>

      <h3> QA </h3>
      <p> {hiring.qaS1} / {hiring.qaS2} / {hiring.qaHired} </p>
    </>
  )
}

export default Hiring
