import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Typography, Paper, IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import SlackSequenceForm from './SlackSequenceForm'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'

function SlackSequenceEdit () {
  const { id } = useParams()
  const history = useHistory()
  const [sequence, setSequence] = useState(null)
  const [developers, setDevelopers] = useState([])
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    fetchSequence()
    fetchDevelopers()
  }, [])

  const fetchSequence = () => {
    onboarder.fetchSlackSequence(id, (err, data) => {
      if (err) {
        setError('Failed to fetch sequence')
      } else {
        setSequence(data)
      }
      setLoaded(true)
    })
  }

  const fetchDevelopers = () => {
    onboarder.listDevs((err, data) => {
      if (err) {
        setError('Failed to fetch developers')
      } else {
        setDevelopers(data)
      }
    })
  }

  const handleSubmit = (updatedSequence) => {
    onboarder.updateSlackSequence(id, updatedSequence, (err) => {
      if (err) {
        setError('Failed to update sequence')
      } else {
        history.push('/onboarder/slack-sequences')
      }
    })
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (!loaded) {
    return <Spinner />
  }

  if (!sequence || developers.length === 0) {
    return <div>Loading developers...</div>
  }

  return (
    <Paper style={{ padding: '2rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <IconButton onClick={() => history.push('/onboarder/slack-sequences')} aria-label='back'>
          <ArrowBack />
        </IconButton>
        <Typography variant='h4' style={{ marginLeft: '1rem' }}>
          Edit Slack Sequence
        </Typography>
      </div>
      <SlackSequenceForm
        initialSequence={sequence}
        onSubmit={handleSubmit}
        submitButtonText='Update Sequence'
        developers={developers}
      />
    </Paper>
  )
}

export default SlackSequenceEdit
