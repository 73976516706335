import qs from 'query-string'
import React, { useState, useEffect } from 'react'
import { Grid, Button, useTheme, useMediaQuery } from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'
import MaterialTable from 'material-table'

import { tableIcons } from '../Devs/icons'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'
import { calcDaysLeft } from '../../../utils/dateUtils'
import CustomTableCell from '../../CustomTableCell/CustomTableCell'

const FILTERS = [
  {
    field: 'title',
    type: 'string'
  },
  {
    field: 'startDate',
    type: 'string'
  },
  {
    field: 'estimatedEndDate',
    type: 'string'
  },
  {
    field: 'slackWorkspace',
    type: 'array'
  },
  {
    field: 'done',
    type: 'array'
  },
  {
    field: 'currentWeekHours',
    type: 'number'
  },
  {
    field: 'previousWeekHours',
    type: 'number'
  },
  {
    field: 'estimatedHours',
    type: 'number'
  },
  {
    field: 'actualHours',
    type: 'number'
  },
  {
    field: 'percentageHours',
    type: 'number'
  },
  {
    field: 'daysLeft',
    type: 'number'
  },
  {
    field: 'epic',
    type: 'array'
  }
]

export default function ProjectsList () {
  document.title = 'Projects | Superstruct Platform'
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const defaultFilters = {}
  FILTERS.forEach((filter) => {
    const urlValue = searchParams.get(filter.field) || filter.default || ''
    defaultFilters[filter.field] =
      filter.type === 'array' ? urlValue.split(',').filter((x) => x) : urlValue
  })

  const [projects, setProjects] = useState([])
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [filters, setFilters] = useState(defaultFilters)
  const maxBodyHeight = useDynamicMaxBodyHeight(230)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const fn = (error, response) => {
    if (response) {
      const filteredProjects = response.map((row) => {
        const actualHours = row.currentWeekHours + row.previousWeekHours
        const percentageHours = (actualHours / row.estimatedHours) * 100
        const daysLeft = row.estimatedEndDate
          ? calcDaysLeft(row.estimatedEndDate)
          : null
        return {
          ...row,
          slackWorkspace: row.slackWorkspace?.Name,
          actualHours: actualHours || 0,
          percentageHours,
          daysLeft: row.done ? '' : daysLeft,
          epic: row.epic?.title
        }
      })
      setProjects(filteredProjects)
      setLoaded(true)
    } else {
      setLoaded(true)
      setThrownError(error)
    }
  }

  function onFilterEffect () {
    const query = {}

    FILTERS.forEach((filter) => {
      const value = filters[filter.field]
      if (!value || !value.length) return

      query[filter.field] = filter.type === 'array' ? value.join(',') : value
    })

    history.replace(`?${qs.stringify(query)}`)
  }

  function handleFilterChange (changes) {
    const newFilters = {}

    FILTERS.forEach((filter) => {
      const change = changes.find(
        (change) => change.column.field === filter.field
      )
      newFilters[filter.field] = change ? change.value : ''
    })

    setFilters(newFilters)
  }

  useEffect(() => {
    onboarder.listProjects(fn)
  }, [])

  useEffect(onFilterEffect, [filters])

  if (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  return (
    <>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginBottom: '10px' }}
            component={Link}
            to='/onboarder/projects/create'
          >
            Add Projects
          </Button>
          <MaterialTable
            onFilterChange={handleFilterChange}
            style={{ fontSize: isMobile ? '90%' : '75%' }}
            icons={tableIcons}
            title='Projects'
            columns={[
              {
                title: 'Title',
                field: 'title',
                defaultFilter: filters.title,
                render: (rowData) => (
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={rowData.link}
                  >
                    {rowData.title}
                  </a>
                )
              },
              {
                title: 'Slack Workspace',
                field: 'slackWorkspace',
                defaultFilter: filters.slackWorkspace,
                lookup: projects.reduce((acc, project) => {
                  acc[project.slackWorkspace] = project.slackWorkspace
                  return acc
                }, {})
              },
              {
                title: 'Epic',
                field: 'epic',
                defaultFilter: filters.epic,
                lookup: projects.reduce((acc, project) => {
                  acc[project.epic] = project.epic
                  return acc
                }, {})
              },
              {
                title: 'Planning document',
                render: (rowData) => (
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={rowData.planningDocLink}
                  >
                    Planning Doc
                  </a>
                )
              },
              {
                title: `Previous ${"Week's"} Hours`,
                field: 'previousWeekHours',
                defaultFilter: filters.previousWeekHours
              },
              {
                title: `Current ${"Week's"} Hours`,
                field: 'currentWeekHours',
                defaultFilter: filters.currentWeekHours
              },
              {
                title: 'Actual Hours',
                field: 'actualHours',
                defaultFilter: filters.actualHours
              },
              {
                title: 'Estimated Hours',
                field: 'estimatedHours',
                defaultFilter: filters.estimatedHours
              },
              {
                title: 'Percentage Hours',
                field: 'percentageHours',
                defaultFilter: filters.percentageHours,
                render: (rowData) => {
                  const percentage = rowData.percentageHours
                    ? `${Math.round(rowData.percentageHours)} %`
                    : '-'
                  return percentage
                }
              },
              {
                title: 'Start Date',
                field: 'startDate',
                defaultSort: 'desc',
                defaultFilter: filters.startDate
              },
              {
                title: 'Estimated End Date',
                field: 'estimatedEndDate',
                defaultFilter: filters.estimatedEndDate
              },
              {
                title: 'Done?',
                field: 'done',
                type: 'boolean',
                render: (rowData) => (rowData.done ? '✅' : '🚫'),
                defaultFilter: filters.done,
                lookup: { true: '✅', false: '🚫' }
              },
              {
                title: 'Days Left',
                field: 'daysLeft',
                defaultFilter: filters.daysLeft
              }
            ]}
            data={projects}
            options={{
              search: true,
              paging: false,
              filtering: true,
              maxBodyHeight
            }}
            actions={[
              (rowData) => ({
                icon: () => (
                  <Link
                    to={`/onboarder/projects/${rowData.id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <tableIcons.Edit />
                  </Link>
                ),
                tooltip: 'Edit'
              })
            ]}
            components={{
              Cell: (props) =>
                <CustomTableCell props={props} isMobile={isMobile} />
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
