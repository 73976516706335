import React, { useState, useEffect, useCallback } from 'react'
import {
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  useMediaQuery,
  useTheme,
  Checkbox,
  MenuItem
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Add, Delete, ArrowUpward, ArrowDownward, Remove } from '@material-ui/icons'
import fuzzysearch from 'fuzzysearch'

const useStyles = makeStyles((theme) => ({
  monospaceTextArea: {
    '& .MuiInputBase-input': {
      fontFamily: 'monospace',
      fontSize: '0.875rem',
      lineHeight: '1.5',
      minHeight: '100px',
      resize: 'vertical'
    }
  },
  startingNodeLabel: {
    display: 'inline-block',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'rgba(255, 255, 0, 0.2)',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem'
  },
  devListContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginBottom: theme.spacing(2)
    }
  },
  devListHeader: {
    marginBottom: theme.spacing(1)
  },
  devList: {
    flexGrow: 1,
    overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    height: '250px',
    maxHeight: '250px'
  },
  devListItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(6)
  },
  devName: {
    fontSize: '0.9rem'
  },
  devCompany: {
    fontSize: '0.8rem',
    marginLeft: theme.spacing(1)
  }
}))

const renderDevName = (dev, classes) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <span className={classes.devName}>{dev.name || dev.email || 'Unknown'}</span>
    {dev.slackWorkspace?.Name && (
      <Typography variant='caption' className={classes.devCompany}>
        {dev.slackWorkspace.Name}
      </Typography>
    )}
  </div>
)

// Add this array of common timezones
const commonTimezones = [
  'America/Los_Angeles',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'Europe/London',
  'Europe/Paris',
  'Asia/Tokyo',
  'Asia/Shanghai',
  'Australia/Sydney',
  'Pacific/Auckland'
]

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

function SlackSequenceForm ({ initialSequence, onSubmit, submitButtonText, developers = [] }) {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [sequence, setSequence] = useState({
    ...initialSequence,
    schedule: initialSequence.schedule || '',
    timezone: initialSequence.timezone || 'America/Los_Angeles'
  })
  const [error, setError] = useState(null)
  const [nodeErrors, setNodeErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const [nodeIds, setNodeIds] = useState({})
  const [selectedDevs, setSelectedDevs] = useState(initialSequence.devIds || [])
  const [searchTerm, setSearchTerm] = useState('')

  const [scheduleTime, setScheduleTime] = useState({
    hour: '09',
    minute: '00',
    ampm: 'AM'
  })
  const [scheduleDays, setScheduleDays] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
    Sunday: false
  })

  useEffect(() => {
    if (initialSequence.schedule) {
      const [minute, hour, , , days] = initialSequence.schedule.split(' ')
      setScheduleTime({
        hour: parseInt(hour) > 12 ? (parseInt(hour) - 12).toString().padStart(2, '0') : hour,
        minute: (Math.round(parseInt(minute) / 5) * 5).toString().padStart(2, '0'),
        ampm: parseInt(hour) >= 12 ? 'PM' : 'AM'
      })
      setScheduleDays({
        Monday: days.includes('1'),
        Tuesday: days.includes('2'),
        Wednesday: days.includes('3'),
        Thursday: days.includes('4'),
        Friday: days.includes('5'),
        Saturday: days.includes('6'),
        Sunday: days.includes('0')
      })
    }
  }, [initialSequence.schedule])

  const handleTimeChange = (event) => {
    const { name, value } = event.target
    if (name === 'minute') {
      const minuteValue = Math.round(parseInt(value) / 5) * 5
      setScheduleTime(prev => ({ ...prev, [name]: minuteValue.toString().padStart(2, '0') }))
    } else {
      setScheduleTime(prev => ({ ...prev, [name]: value }))
    }
  }

  const handleDayChange = (event) => {
    const { name, checked } = event.target
    setScheduleDays(prev => ({ ...prev, [name]: checked }))
  }

  const generateCronExpression = () => {
    const { hour, minute, ampm } = scheduleTime
    let cronHour = parseInt(hour)
    if (ampm === 'PM' && cronHour !== 12) {
      cronHour += 12
    } else if (ampm === 'AM' && cronHour === 12) {
      cronHour = 0
    }
    cronHour = cronHour.toString().padStart(2, '0')

    const cronDays = Object.entries(scheduleDays)
      .map(([day, isSelected], index) => isSelected ? (index + 1) % 7 : null)
      .filter(day => day !== null)
      .join(',')
    return `${minute} ${cronHour} * * ${cronDays}`
  }

  const sortedDevelopers = React.useMemo(() => {
    if (!Array.isArray(developers)) return []
    return [...developers].sort((a, b) => {
      const companyA = a.slackWorkspace?.Name || ''
      const companyB = b.slackWorkspace?.Name || ''
      if (companyA !== companyB) {
        return companyA.localeCompare(companyB)
      }
      const nameA = a.name || a.email || ''
      const nameB = b.name || b.email || ''
      return nameA.localeCompare(nameB)
    })
  }, [developers])

  useEffect(() => {
    if (sequence) {
      const ids = sequence.nodes.reduce((acc, node) => {
        acc[node.id] = node.id
        return acc
      }, {})
      setNodeIds(ids)
    }
  }, [sequence])

  const validateNodeReferences = useCallback((nodes) => {
    const errors = {}
    const nodeIds = nodes.map(node => node.id)

    nodes.forEach((node, index) => {
      if (node.onSuccess && !nodeIds.includes(node.onSuccess)) {
        errors[`${index}-${node.id}-onSuccess`] = `Node ID "${node.onSuccess}" does not exist`
      }
      if (node.onFail && !nodeIds.includes(node.onFail)) {
        errors[`${index}-${node.id}-onFail`] = `Node ID "${node.onFail}" does not exist`
      }
      if (nodes.filter(n => n.id === node.id).length > 1) {
        errors[node.id] = `Node ID "${node.id}" is not unique`
      }
    })

    return errors
  }, [])

  useEffect(() => {
    if (sequence) {
      const errors = validateNodeReferences(sequence.nodes)
      setNodeErrors(errors)
      setHasErrors(Object.keys(errors).length > 0)
    }
  }, [sequence, validateNodeReferences])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setSequence({ ...sequence, [name]: value })
  }

  const handleNodeChange = (index, field, value) => {
    const updatedNodes = [...sequence.nodes]

    if (field === 'evaluation') {
      if (value.name === 'none') {
        const { evaluation, ...restNode } = updatedNodes[index]
        updatedNodes[index] = restNode
      } else {
        updatedNodes[index].evaluation = {
          type: 'function',
          name: value.name,
          args: updatedNodes[index].evaluation?.args || [''],
          ...value
        }
      }
    } else if (field === 'evaluation.args') {
      updatedNodes[index].evaluation = {
        ...updatedNodes[index].evaluation,
        args: [value] // Store the entire text as a single string in the args array
      }
    } else if (field === 'onSuccess' || field === 'onFail') {
      updatedNodes[index][field] = value.trim() || null
    } else {
      updatedNodes[index] = { ...updatedNodes[index], [field]: value }
    }

    setSequence({ ...sequence, nodes: updatedNodes })
  }

  const handleNodeIdChange = useCallback((index, value) => {
    setNodeIds(prev => ({ ...prev, [sequence.nodes[index].id]: value }))
  }, [sequence])

  const handleNodeIdBlur = useCallback((index) => {
    const oldId = sequence.nodes[index].id
    const newId = nodeIds[oldId]

    if (oldId !== newId) {
      if (sequence.nodes.some((node, i) => i !== index && node.id === newId)) {
        setNodeErrors(prev => ({ ...prev, [oldId]: `Node ID "${newId}" already exists. Please use a unique ID.` }))
        setHasErrors(true)
        setNodeIds(prev => ({ ...prev, [oldId]: oldId }))
      } else {
        const updatedNodes = sequence.nodes.map((node, i) =>
          i === index ? { ...node, id: newId } : node
        )
        setSequence({ ...sequence, nodes: updatedNodes })
      }
    }
  }, [sequence, nodeIds])

  const addNode = () => {
    const newNode = {
      id: `node_${Date.now()}`,
      type: 'question',
      text: '',
      onSuccess: '',
      onFail: ''
    }
    setSequence(prevSequence => ({
      ...prevSequence,
      nodes: [...prevSequence.nodes, newNode]
    }))
  }

  const removeNode = (index) => {
    setSequence(prevSequence => ({
      ...prevSequence,
      nodes: prevSequence.nodes.filter((_, i) => i !== index)
    }))
  }

  const moveNode = (index, direction) => {
    setSequence(prevSequence => {
      const updatedNodes = [...prevSequence.nodes]
      const newIndex = index + direction
      if (newIndex >= 0 && newIndex < updatedNodes.length) {
        [updatedNodes[index], updatedNodes[newIndex]] = [updatedNodes[newIndex], updatedNodes[index]]
      }
      return { ...prevSequence, nodes: updatedNodes }
    })
  }

  const handleAddDev = (dev) => {
    setSelectedDevs([...selectedDevs, dev.id])
  }

  const handleRemoveDev = (devId) => {
    setSelectedDevs(selectedDevs.filter(id => id !== devId))
  }

  const filteredDevelopers = React.useMemo(() => {
    return sortedDevelopers.filter(dev => {
      const searchName = (dev.name || dev.email || '').toLowerCase()
      const searchCompany = (dev.slackWorkspace?.Name || '').toLowerCase()
      const term = searchTerm.toLowerCase()
      return fuzzysearch(term, searchName) || fuzzysearch(term, searchCompany)
    })
  }, [sortedDevelopers, searchTerm])

  const filteredSelectedDevs = filteredDevelopers.filter(dev => selectedDevs.includes(dev.id) && !dev.archived)
  const filteredAvailableDevs = filteredDevelopers.filter(dev => !selectedDevs.includes(dev.id) && !dev.archived)

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const referenceErrors = validateNodeReferences(sequence.nodes)
    setNodeErrors(referenceErrors)

    if (Object.keys(referenceErrors).length > 0) {
      setError('Please fix all node errors before submitting.')
      setHasErrors(true)
      return
    }

    const cleanedSequence = {
      ...sequence,
      timeoutStr: sequence.timeoutStr || null,
      finishMessage: sequence.finishMessage || null,
      devIds: selectedDevs,
      schedule: generateCronExpression(),
      nodes: sequence.nodes.map(node => ({
        ...node,
        onSuccess: node.onSuccess || null,
        onFail: node.onFail || null,
        evaluation: node.evaluation
          ? {
              ...node.evaluation,
              args: node.evaluation.args.filter(arg => arg.trim() !== '')
            }
          : null
      }))
    }

    onSubmit(cleanedSequence)
  }

  const handleTimezoneChange = (event, newValue) => {
    setSequence({ ...sequence, timezone: newValue })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Name'
            name='name'
            value={sequence.name}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Schedule</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                fullWidth
                label='Hour'
                name='hour'
                value={scheduleTime.hour}
                onChange={handleTimeChange}
              >
                {[...Array(12)].map((_, i) => (
                  <MenuItem key={i} value={(i + 1).toString().padStart(2, '0')}>
                    {(i + 1).toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                fullWidth
                label='Minute'
                name='minute'
                value={scheduleTime.minute}
                onChange={handleTimeChange}
              >
                {[...Array(12)].map((_, i) => (
                  <MenuItem key={i} value={(i * 5).toString().padStart(2, '0')}>
                    {(i * 5).toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                select
                fullWidth
                label='AM/PM'
                name='ampm'
                value={scheduleTime.ampm}
                onChange={handleTimeChange}
              >
                <MenuItem value='AM'>AM</MenuItem>
                <MenuItem value='PM'>PM</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={commonTimezones}
                value={sequence.timezone}
                onChange={handleTimezoneChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Timezone'
                    name='timezone'
                    placeholder='America/Los_Angeles'
                  />
                )}
                freeSolo
              />
            </Grid>
          </Grid>
          <FormControl component='fieldset' style={{ marginTop: '1rem' }}>
            <FormLabel component='legend'>Days of Week</FormLabel>
            <Grid container spacing={1}>
              {daysOfWeek.map((day, index) => (
                <React.Fragment key={day}>
                  <Grid item xs={6} sm={4} md={2} lg={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scheduleDays[day]}
                          onChange={handleDayChange}
                          name={day}
                        />
                      }
                      label={day}
                    />
                  </Grid>
                  {index === 4 && (
                    <Grid item xs={6} sm={4} md={2} lg={2} /> // Invisible spacer after Friday
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </FormControl>
          <Typography variant='body2' style={{ marginTop: '0.5rem' }}>
            Cron Pattern: {generateCronExpression()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Timeout'
            name='timeoutStr'
            value={sequence.timeoutStr}
            onChange={handleInputChange}
            placeholder='30 minutes'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label='Finish Message'
            name='finishMessage'
            value={sequence.finishMessage || ''}
            onChange={handleInputChange}
            className={classes.monospaceTextArea}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Select Developers</Typography>
          <TextField
            fullWidth
            label='Search Developers'
            value={searchTerm}
            onChange={handleSearchChange}
            margin='normal'
          />
          <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
            <Grid item xs={12} md={6}>
              <div className={classes.devListContainer}>
                <Typography variant='subtitle1' className={classes.devListHeader}>
                  Selected Developers
                </Typography>
                <List className={classes.devList}>
                  {filteredSelectedDevs.map((dev) => (
                    <ListItem key={dev.id} className={classes.devListItem} dense>
                      <ListItemText primary={renderDevName(dev, classes)} />
                      <ListItemSecondaryAction>
                        <IconButton edge='end' onClick={() => handleRemoveDev(dev.id)} size='small'>
                          <Remove fontSize='small' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.devListContainer}>
                <Typography variant='subtitle1' className={classes.devListHeader}>
                  Available Developers
                </Typography>
                <List className={classes.devList}>
                  {filteredAvailableDevs.map((dev) => (
                    <ListItem key={dev.id} className={classes.devListItem} dense>
                      <ListItemText primary={renderDevName(dev, classes)} />
                      <ListItemSecondaryAction>
                        <IconButton edge='end' onClick={() => handleAddDev(dev)} size='small'>
                          <Add fontSize='small' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Nodes</Typography>
          {sequence.nodes.map((node, index) => (
            <Paper
              key={node.id}
              style={{
                padding: '1rem',
                marginBottom: '1rem',
                position: 'relative'
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem'
              }}
              >
                <div>
                  {index === 0 && (
                    <div className={classes.startingNodeLabel}>Starting Node</div>
                  )}
                </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
                >
                  <IconButton size='small' onClick={() => moveNode(index, -1)} disabled={index === 0}>
                    <ArrowUpward />
                  </IconButton>
                  <IconButton size='small' onClick={() => moveNode(index, 1)} disabled={index === sequence.nodes.length - 1}>
                    <ArrowDownward />
                  </IconButton>
                  <IconButton size='small' onClick={() => removeNode(index)}>
                    <Delete />
                  </IconButton>
                </div>
              </div>
              <TextField
                fullWidth
                label='ID'
                value={nodeIds[node.id] || node.id}
                onChange={(e) => handleNodeIdChange(index, e.target.value)}
                onBlur={() => handleNodeIdBlur(index)}
                style={{ marginBottom: '1rem' }}
                error={!!nodeErrors[node.id]}
                helperText={nodeErrors[node.id] || ''}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl component='fieldset' fullWidth>
                    <FormLabel component='legend'>Type</FormLabel>
                    <RadioGroup
                      row
                      aria-label='type'
                      name='type'
                      value={node.type}
                      onChange={(e) => handleNodeChange(index, 'type', e.target.value)}
                    >
                      <FormControlLabel value='question' control={<Radio />} label='Question' />
                      <FormControlLabel value='statement' control={<Radio />} label='Statement' />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label='Text'
                    value={node.text}
                    onChange={(e) => handleNodeChange(index, 'text', e.target.value)}
                    className={classes.monospaceTextArea}
                    style={{ marginTop: '1rem' }}
                  />
                  <Autocomplete
                    options={sequence.nodes.map(n => n.id).filter(id => id !== node.id)}
                    value={node.onSuccess || null}
                    onChange={(_, newValue) => handleNodeChange(index, 'onSuccess', newValue || '')}
                    onInputChange={(_, newInputValue) => {
                      handleNodeChange(index, 'onSuccess', newInputValue || '')
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label='On Success'
                        error={!!nodeErrors[`${index}-${node.id}-onSuccess`]}
                        helperText={nodeErrors[`${index}-${node.id}-onSuccess`] || ''}
                      />
                    )}
                    freeSolo
                    style={{ marginTop: '1rem' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl component='fieldset' fullWidth>
                    <FormLabel component='legend'>Evaluation Name</FormLabel>
                    <RadioGroup
                      row
                      aria-label='evaluation-name'
                      name='evaluation-name'
                      value={node.evaluation?.name || 'none'}
                      onChange={(e) => handleNodeChange(index, 'evaluation', { name: e.target.value })}
                    >
                      <FormControlLabel value='none' control={<Radio />} label='None' />
                      <FormControlLabel value='prompt' control={<Radio />} label='Prompt' />
                      <FormControlLabel value='truthy' control={<Radio />} label='Truthy' />
                    </RadioGroup>
                  </FormControl>
                  {node.evaluation && (
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label='Evaluation Args'
                      value={node.evaluation.args[0] || ''} // Display the first (and only) string in the args array
                      onChange={(e) => handleNodeChange(index, 'evaluation.args', e.target.value)}
                      className={classes.monospaceTextArea}
                      style={{ marginTop: '1rem' }}
                    />
                  )}
                  {node.evaluation && node.evaluation.name !== 'none' && (
                    <Autocomplete
                      options={sequence.nodes.map(n => n.id).filter(id => id !== node.id)}
                      value={node.onFail || null}
                      onChange={(_, newValue) => handleNodeChange(index, 'onFail', newValue || '')}
                      onInputChange={(_, newInputValue) => {
                        handleNodeChange(index, 'onFail', newInputValue || '')
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label='On Fail'
                          error={!!nodeErrors[`${index}-${node.id}-onFail`]}
                          helperText={nodeErrors[`${index}-${node.id}-onFail`] || ''}
                        />
                      )}
                      freeSolo
                      style={{ marginTop: '1rem' }}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          ))}
          <Button startIcon={<Add />} onClick={addNode}>Add Node</Button>
        </Grid>
        <Grid item xs={12}>
          <Button type='submit' variant='contained' color='primary' disabled={hasErrors || !!error}>
            {submitButtonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default SlackSequenceForm
