import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router'
import qs from 'qs'
import _ from 'lodash'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core'
import { getCompanyAnswersByDate } from '../../api'
import { getBehindDate } from '../../utils/dateUtils'
import ZeroAcceptReject from './ZeroAcceptReject'
import VagueWordingReport from './VagueWordingReport'
import NoResponseReport from './NoReponseReport'
import Spinner from '../Spinner/Spinner'

const useStyles = makeStyles((theme) => ({
  textField: {
    '& > *': {
      marginRight: theme.spacing(1),
      width: '96px'
    }
  },
  dateTextField: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  paperItems: {
    marginTop: '20px'
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '20px'
  },
  selectInput: {
    margin: '20px 0px',
    width: '100%',
    maxWidth: '470px'
  }
}))

const ActivityReports = props => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const { search } = useLocation()
  const query = qs.parse(search, { ignoreQueryPrefix: true })

  const initUser = query.user || 'all'
  const initDateStart = query.start || getBehindDate(6).toISOString().slice(0, 10)
  const initDateEnd = query.end || new Date().toISOString().slice(0, 10)

  const [dateStart, setDateStart] = useState(initDateStart)
  const [dateEnd, setDateEnd] = useState(initDateEnd)
  const [records, setRecords] = useState([])
  const [activeFilter, setActiveFilter] = useState(initUser)
  const [filteredReports, setFilteredReports] = useState([])
  const [loaded, setLoaded] = useState(false)

  const handleActiveFilterChange = (event) => {
    const selected = event.target.value
    setActiveFilter(selected)
    const filteredRecords = selected === 'all'
      ? records
      : records.filter(e => e.userId === selected)
    setFilteredReports(filteredRecords)
    updateQuery({ user: selected, start: dateStart, end: dateEnd }, history)
  }

  const handleSearchSubmit = e => {
    e.preventDefault()
    const dateStartValue = e.target.dateStart.value
    const dateEndValue = e.target.dateEnd.value
    setDateStart(dateStartValue)
    setDateEnd(dateEndValue)
    updateQuery({
      user: activeFilter,
      start: dateStartValue,
      end: dateEndValue
    }, history)

    fetchAnswers(dateStartValue, dateEndValue)
  }

  const handleReset = () => {
    const defaultDateStart = getBehindDate(6).toISOString().slice(0, 10)
    const defaultDateEnd = new Date().toISOString().slice(0, 10)
    const defaultUser = 'all'
    setDateStart(defaultDateStart)
    setDateEnd(defaultDateEnd)
    setActiveFilter(defaultUser)
    updateQuery({
      user: defaultUser,
      start: defaultDateStart,
      end: defaultDateEnd
    }, history)
    fetchAnswers(defaultDateStart, defaultDateEnd)
  }

  const fn = useCallback((error, response) => {
    if (error) {
      throw error
    } else {
      const groupedByUser = prepareData(response.data)
      setRecords(groupedByUser)

      const filteredRecords = groupedByUser.filter(e => e.userId === activeFilter)

      if (filteredRecords.length) setFilteredReports(filteredRecords)
      if (activeFilter === 'all' || !filteredRecords.length) {
        setFilteredReports(groupedByUser)
        setActiveFilter('all')
      }
    }
    setLoaded(true)
  }, [activeFilter])

  const fetchAnswers = useCallback((dateStart, dateEnd) => {
    setLoaded(false)
    getCompanyAnswersByDate({
      companyId: id,
      dateStart,
      dateEnd
    }, fn)
  }, [id, fn])

  useEffect(() => {
    fetchAnswers(dateStart, dateEnd)
  }, [id])  // eslint-disable-line

  return (
    <>
      <Paper className={classes.paper} variant='outlined' elevation={3}>
        <form noValidate onSubmit={handleSearchSubmit}>
          <Grid container spacing={1}>
            <Grid md={2} item>
              <Typography variant='subtitle1' component='h5'>
                Date Range
              </Typography>
            </Grid>
            <Grid className={classes.dateTextField} item>
              <TextField
                label='Date Start'
                id='dateStart'
                name='dateStart'
                type='date'
                defaultValue={dateStart}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Date End'
                id='dateEnd'
                name='dateEnd'
                type='date'
                defaultValue={dateEnd}
                variant='outlined'
                size='small'
              />
            </Grid>
          </Grid>
          <div className={classes.paperItems}>
            <Button
              style={{ marginRight: '10px' }}
              variant='contained'
              color='primary'
              size='small'
              type='submit'
            >Search
            </Button>
            <Button
              variant='contained'
              size='small'
              type='reset'
              onClick={handleReset}
            >Reset
            </Button>
          </div>
        </form>
        <Grid container spacing={1} alignItems='center'>
          <Grid item className={classes.selectInput}>
            <FormControl fullWidth>
              <InputLabel id='active-status-select-label'>Active Status</InputLabel>
              <Select
                labelId='active-status-select-label'
                id='active-status-select'
                value={activeFilter}
                label='Status'
                onChange={handleActiveFilterChange}
              >
                <MenuItem value='all'>All</MenuItem>
                {records.map((record, index) => (
                  <MenuItem key={index} value={record.userId}>
                    {record.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <h1>Engineer Activity Report</h1>
      {loaded
        ? (
          <div>
            <ZeroAcceptReject
              userReport={filteredReports}
              dateStart={dateStart}
              dateEnd={dateEnd}
            />
            <VagueWordingReport companyId={id} userReport={filteredReports} />
            <NoResponseReport
              userReport={filteredReports}
              dateStart={dateStart}
              dateEnd={dateEnd}
            />
          </div>
          )
        : <Spinner />}

    </>
  )
}

function prepareData (data) {
  return _
    .chain(data)
    .groupBy('username')
    .map((value, key) => ({
      name: key,
      userId: value[0].user_id,
      vagueWording: value.filter(e => hasVague(e.answer)),
      noResponse: _
        .chain(value)
        .groupBy('report_id')
        .map(getLastReportDay)
        .value()
        .filter(report =>
          report.filter(answer => ['', '\n'].includes(answer.answer)).length > 3
        ),
      zeroAcceptReject: _
        .chain(value)
        .groupBy('report_id')
        .map(getLastReportDay)
        .value()
        .filter(report =>
          report.slice(0, 2).every(e => !/^[A-Za-z1-9_.]+$/.test(e.answer))
        )
    }))
    .value()
}

function getLastReportDay (reports) {
  const dateGroup = _.groupBy(reports, 'created')
  const getLastDate = new Date(Math.max(
    ...Object.keys(dateGroup).map(e => new Date(e))
  ))
  return dateGroup[getLastDate.toISOString()]
}

function hasVague (lineRaw) {
  const line = lineRaw
    .replace(/^\W+/, '')
    .replace(/^i will\s+/i, '')
    .replace(/^i plan to\s+/i, '')
  const watchFor = [
    /^work on/i,
    /^continue/i,
    /^start/i,
    /^explore/i,
    /^understand/i,
    /^look(ing)? (into|for)/i
  ]

  let hasWarning = false

  watchFor.forEach(function (re) {
    if (line.match(re)) hasWarning = true
  })

  return hasWarning
}

function updateQuery (queryObject, history) {
  history.replace({ search: qs.stringify(queryObject) })
}

export default ActivityReports
