
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import Spinner from './Spinner/Spinner'

const StateHandler = ({ isErr, isLoaded, children }) => {
  if (isErr) {
    return (
      <Grid>
        <Typography variant='overline' display='block' gutterBottom>
          {isErr.message || isErr.toString()}
        </Typography>
      </Grid>
    )
  }

  if (!isLoaded) return <Spinner p={1} />

  return children
}

export default StateHandler
