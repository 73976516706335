import _ from 'lodash'
import { convertDateToPT, getTimeDifference } from './timeUtils'
import { calculateMedian } from './mathUtils'
import { getNameFromEmail } from './commonUtils'
import { CHECK_IN_CHECKLIST } from '../constants/check-ins'

export {
  getCheckInStats,
  calculateStats,
  replaceMentions,
  getAverageCoverage,
  countChecklistOccurence,
  getDevInsightsHeader
}
function getCheckInStats (messages = []) {
  const responseTimes = {
    dev: [],
    manager: []
  }
  const wordCount = {
    dev: [],
    manager: []
  }

  _.forEach(messages, (message, index) => {
    const responder = message.name ? 'dev' : 'manager'
    if (index > 0 && message.name !== messages[index - 1].name) {
      const timeDiff = getTimeDifference(
        messages[index - 1].timestamp_utc,
        message.timestamp_utc
      )
      responseTimes[responder].push(timeDiff)
    }
    const words = message.text.trim().split(/\s+/).filter(Boolean)
    wordCount[responder].push(words.length)
  })

  const firstMessage = messages[0]
  const lastMessage = messages[messages.length - 1]

  const stats = {
    startTime: convertDateToPT(firstMessage?.timestamp_utc),
    endTime: convertDateToPT(lastMessage?.timestamp_utc),
    duration:
      getTimeDifference(firstMessage?.timestamp_utc, lastMessage?.timestamp_utc),
    responseTime: {
      dev: calculateStats(responseTimes.dev),
      manager: calculateStats(responseTimes.manager)
    },
    wordCount: calculateWordCount(wordCount)
  }
  return stats
}

function calculateStats (numbers) {
  if (!numbers?.length) return { min: 0, max: 0, average: 0, median: 0 }

  const sortedNumbers = _.sortBy(numbers)

  const min = _.head(sortedNumbers)
  const max = _.last(sortedNumbers)
  const average = _.mean(sortedNumbers)
  const median = calculateMedian(sortedNumbers)

  return { min, max, average, median }
}

function calculateWordCount (words) {
  const wordCount = {
    total: _.sum(_.flatten(_.values(words))),
    dev: {
      total: _.sum(words.dev),
      average: words.dev.length ? _.mean(words.dev) : 0,
      count: words.dev.length
    },
    manager: {
      total: _.sum(words.manager),
      average: words.manager.length ? _.mean(words.manager) : 0
    }
  }
  return wordCount
}

function replaceMentions (messageText, dev) {
  let updatedText = messageText.replace(
    new RegExp(`<@${dev.slackUserId}>`, 'gi'),
    `@${dev.name || getNameFromEmail(dev.email)}`
  )

  if (dev.manager?.slackUserId) {
    updatedText = updatedText.replace(
      new RegExp(`<@${dev.manager.slackUserId}>`, 'gi'),
      `@${dev.manager.name}`
    )
  }

  return updatedText
}

function getAverageCoverage (checkIns) {
  if (!checkIns.length) return 0
  const gptChecklist = _(checkIns)
    .map('gptChecklist')
    .filter()
    .map(checklist => {
      const coveredCount = checklist.covered?.length || 0
      const notCoveredCount = checklist.notCovered?.length || 0
      const totalItems = coveredCount + notCoveredCount
      return totalItems ? (coveredCount / totalItems) * 100 : 0
    })
    .value()
  const mean = parseFloat(_.mean(gptChecklist).toFixed(2))
  return mean % 1 === 0 ? parseInt(mean, 10) : mean
}

function countChecklistOccurence (checkIns) {
  const coveredItems = _.flatMap(checkIns, checkIn => {
    return _.get(checkIn, 'gptChecklist.covered', [])
  })
  const coveredItemsTitle = coveredItems.map(phrase => {
    const [key] = phrase.split(':')
    return key.trim()
  })
  const coveredCounts = _.countBy(coveredItemsTitle)

  const occurrences = CHECK_IN_CHECKLIST.map(checklist => ({
    name: checklist,
    occurrence: coveredCounts[checklist] || 0
  }))

  return occurrences
}

function getDevInsightsHeader (checkIns) {
  if (!checkIns.length) return ''

  const { name, email, slackWorkspace } = checkIns[0].dev || {}
  const devName = name || getNameFromEmail(email)
  const slackWorkspaceName = slackWorkspace?.Name || ''
  const separator = (devName && slackWorkspaceName) ? ' | ' : ''
  return `${devName + separator + slackWorkspaceName}`
}
