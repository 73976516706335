import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import MaterialTable from 'material-table'

import { tableIcons } from '../Devs/icons'
import { getCheckInStats } from '../../../utils/checkInUtils'
import { formatSeconds } from '../../../utils/timeUtils'

const EngagementMetrics = ({ checkIns, filters, handleFilterChange }) => {
  const filteredCheckIns = useMemo(() => {
    return checkIns.map(row => {
      const { wordCount, responseTime } = getCheckInStats(row.messages)
      return {
        ...row,
        date: row.date,
        totalWordCount: wordCount.dev.total,
        averageWordCount: wordCount.dev.average.toFixed(2),
        minResponseTime: responseTime.dev.min,
        maxResponseTime: responseTime.dev.max,
        medianResponseTime: responseTime.dev.median,
        averageResponseTime: responseTime.dev.average
      }
    })
  }, [checkIns])

  return (
    <Grid container style={{ padding: '30px' }}>
      <Grid item xs={12}>
        <MaterialTable
          onFilterChange={handleFilterChange}
          style={{ fontSize: '75%' }}
          icons={tableIcons}
          title='Engagement metrics'
          columns={[
            {
              title: 'Date',
              field: 'date',
              defaultSort: 'desc',
              defaultFilter: filters.date
            },
            {
              title: 'Total word count',
              field: 'totalWordCount',
              defaultFilter: filters.totalWordCount
            },
            {
              title: 'Average word count',
              field: 'averageWordCount',
              defaultFilter: filters.averageWordCount
            },
            {
              title: 'Min response time',
              field: 'minResponseTime',
              defaultFilter: filters.minResponseTime,
              render: rowData => formatSeconds(rowData.minResponseTime),
              customFilterAndSearch: (term, rowData) => {
                const humanFormat = formatSeconds(rowData.minResponseTime)
                return humanFormat.includes(term)
              }
            },
            {
              title: 'Max response time',
              field: 'maxResponseTime',
              defaultFilter: filters.maxResponseTime,
              render: rowData => formatSeconds(rowData.maxResponseTime),
              customFilterAndSearch: (term, rowData) => {
                const humanFormat = formatSeconds(rowData.maxResponseTime)
                return humanFormat.includes(term)
              }
            },
            {
              title: 'Average response time',
              field: 'averageResponseTime',
              defaultFilter: filters.averageResponseTime,
              render: rowData => formatSeconds(rowData.averageResponseTime),
              customFilterAndSearch: (term, rowData) => {
                const humanFormat = formatSeconds(rowData.averageResponseTime)
                return humanFormat.includes(term)
              }
            },
            {
              title: 'Median response time',
              field: 'medianResponseTime',
              defaultFilter: filters.medianResponseTime,
              render: rowData => formatSeconds(rowData.medianResponseTime),
              customFilterAndSearch: (term, rowData) => {
                const humanFormat = formatSeconds(rowData.medianResponseTime)
                return humanFormat.includes(term)
              }
            }
          ]}
          data={filteredCheckIns}
          options={{
            search: true,
            paging: false,
            filtering: true
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EngagementMetrics
