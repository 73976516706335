export function getReadableDateFromISO (date) {
  return new Date(date).getUTCMonth() + 1 + '/' +
  new Date(date).getUTCDate() + '/' +
  new Date(date).getUTCFullYear()
}

export function getMonthAndDay (date) {
  const d = new Date(date)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
    'Sep', 'Oct', 'Nov', 'Dec']
  return monthNames[d.getMonth()] + ' ' + d.getDate()
}

export function getBehindDate (behind) {
  const now = new Date()
  now.setDate(now.getDate() - behind)
  now.setHours(0)
  now.setMinutes(1)
  return now
}

export function getWeekCount (date1, date2) {
  const diff = new Date(date2).getTime() - new Date(date1).getTime()
  return Math.ceil(diff / (1000 * 60 * 60 * 24 * 7))
}

export function getDayOfWeek (date) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return days[new Date(date).getDay()]
}

export function getCurrentDate () {
  const date = new Date()
  return date.toISOString().slice(0, 10)
}

export function getNDaysAgo (n) {
  const date = new Date()
  date.setDate(date.getDate() - n)
  return date.toISOString().slice(0, 10)
}

export function monthsBetween (dateString) {
  const givenDate = new Date(dateString)
  const today = new Date()

  const dayDifference = Math.floor((today - givenDate) / (24 * 60 * 60 * 1000))

  let years = today.getFullYear() - givenDate.getFullYear()
  let months = today.getMonth() - givenDate.getMonth()
  let days = today.getDate() - givenDate.getDate()

  if (days < 0) {
    months--
    days += new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0).getDate()
  }
  if (months < 0) {
    years--
    months += 12
  }

  months += years * 12

  let result = ''
  if (months > 0) result += `${months} month${months > 1 ? 's' : ''}, `
  if (dayDifference > 0) result += `${days} day${days > 1 ? 's' : ''} ago`
  else result = 'Today'

  return result
}

export function calcDaysLeft (dateAhead) {
  const now = new Date()
  const endDate = new Date(dateAhead)
  const diff = Math.floor((endDate - now) / (1000 * 60 * 60 * 24))
  return diff
}
