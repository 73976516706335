import React from 'react'
import { Grid, Button, useMediaQuery, useTheme } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import MaterialTable from 'material-table'

import { tableIcons } from './icons'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'
import CustomTableCell from '../../CustomTableCell/CustomTableCell'

export default function DevsList () {
  document.title = 'Devs | Superstruct Platform'

  const { id: companyId } = useParams()
  const [devs, setDevs] = React.useState([])
  const [thrownError, setThrownError] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const maxBodyHeight = useDynamicMaxBodyHeight(230)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const fn = (error, response) => {
    if (response) {
      setLoaded(true)
      setDevs(response)
    } else {
      setLoaded(true)
      setThrownError(error)
    }
  }

  React.useEffect(() => {
    onboarder.listDevs(companyId, fn)
  }, []) //eslint-disable-line

  if (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  return (
    <>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginBottom: '10px' }}
            component={Link}
            to='/onboarder/devs/create'
          >
            Add Dev
          </Button>
          <MaterialTable
            style={{ fontSize: isMobile ? '90%' : '75%' }}
            icons={tableIcons}
            title='Devs'
            columns={[
              { title: 'Email', field: 'email' },
              {
                title: 'Slack Workspace',
                field: 'slackWorkspace.Name',
                lookup: devs.reduce((acc, dev) => {
                  acc[dev.slackWorkspace?.Name] = dev.slackWorkspace?.Name
                  return acc
                }, {})
              },
              {
                title: 'Manager',
                field: 'manager.name',
                lookup: devs.reduce((acc, dev) => {
                  acc[dev.manager?.name] = dev.manager?.name
                  return acc
                }, {})
              },
              {
                title: 'Script',
                field: 'script.name',
                lookup: devs.reduce((acc, dev) => {
                  acc[dev.script?.name] = dev.script?.name
                  return acc
                }, {})
              },
              {
                title: 'AI Review?',
                field: 'aiPlanReview',
                type: 'boolean'
              },
              {
                title: 'Archived?',
                field: 'archived',
                type: 'boolean',
                defaultFilter: 'unchecked' // Filter out archived devs by default
              },
              {
                title: 'Action',
                filtering: false,
                render: (rowData) =>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`dev-insight/${rowData._id}`}
                  >
                    Dev Insights
                  </a>
              }
            ]}
            data={devs}
            options={{
              search: true,
              paging: false,
              filtering: true,
              maxBodyHeight
            }}
            actions={[
              rowData => ({
                icon: () => (
                  <Link
                    to={`/onboarder/devs/${rowData.email}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <tableIcons.Edit />
                  </Link>
                ),
                tooltip: 'Edit'
              })
            ]}
            components={{
              Cell: (props) =>
                <CustomTableCell props={props} isMobile={isMobile} />
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
