import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { onboarder } from '../../../api'
import ReportIcon from '@material-ui/icons/Assessment'

function SlackSequencesList () {
  const [sequences, setSequences] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchSequences()
  }, [])

  const fetchSequences = () => {
    setLoading(true)
    onboarder.listSlackSequences((err, data) => {
      setLoading(false)
      if (err) {
        console.error('Error fetching slack sequences:', err)
        setError('Failed to load slack sequences. Please try again.')
      } else {
        setSequences(data)
      }
    })
  }

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Typography color='error'>{error}</Typography>
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Slack Sequences
      </Typography>
      <Button
        component={Link}
        to='/onboarder/slack-sequences/create'
        variant='contained'
        color='primary'
        style={{ marginBottom: '1rem' }}
      >
        Create New Sequence
      </Button>
      {/* Remove this button
      <Button
        component={Link}
        to='/onboarder/slack-sequences/report'
        variant='contained'
        color='secondary'
        startIcon={<ReportIcon />}
        style={{ marginBottom: '1rem' }}
      >
        View Report
      </Button>
      */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='right'>Nodes</TableCell>
              <TableCell align='right'>Timeout</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sequences.map((sequence) => (
              <TableRow key={sequence.id}>
                <TableCell component='th' scope='row'>
                  {sequence.name}
                </TableCell>
                <TableCell align='right'>{sequence.nodes.length}</TableCell>
                <TableCell align='right'>{sequence.timeoutStr || '30m'}</TableCell>
                <TableCell align='right'>
                  <Button
                    component={Link}
                    to={`/onboarder/slack-sequences/${sequence.id}`}
                    color='primary'
                    style={{ marginRight: '0.5rem' }}
                  >
                    Edit
                  </Button>
                  <Button
                    component={Link}
                    to={`/onboarder/slack-sequences/${sequence.id}/report`}
                    color='secondary'
                    startIcon={<ReportIcon />}
                  >
                    Report
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SlackSequencesList
