import React from 'react'
import { MTableCell } from 'material-table'

export default function CustomTableCell ({ props, isMobile }) {
  return (
    <MTableCell
      style={{
        padding: isMobile ? '8px' : '16px'
      }}
      {...props}
    />
  )
}
