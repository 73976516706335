import React from 'react'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

export default function DevNameInput ({ devData, handleEditDev }) {
  return (
    <div>
      {devData?.name}
      <IconButton
        aria-label='edit'
        onClick={() => handleEditDev(devData)}
      >
        <EditIcon fontSize='small' />
      </IconButton>
    </div>
  )
}
