import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Typography
} from '@material-ui/core'
import { getReportAnswers, putFlagStandupAnswer } from '../../api'
import _ from 'lodash'
import Spinner from '../Spinner/Spinner'
import CustomTable from '../CustomTable/CustomTable'
import { DAILY_STANDUP_QUESTION_ORDER } from '../../utils/standUpUtils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  textarea: {
    width: '99%',
    minHeight: 100,
    marginBottom: theme.spacing(2)
  },
  submitButton: {
    marginTop: theme.spacing(2)
  }
}))

const CollapseComponent = ({ rowIndex, userObject, setUserObject }) => {
  const classes = useStyles()
  const [response, setResponse] = useState(null)
  const [report, setReport] = useState(userObject[rowIndex])
  const [formData, setFormData] = useState({
    question: '',
    notes: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }
  useEffect(() => {
    setResponse(
      report.responses.find(
        (response) => response.question === formData.question
      )
    )
  }, [formData.question, report])

  const fn = (error, response) => {
    if (error) {
      throw error
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!response?.id) return
    const updatedDataArray = userObject.map((report) => {
      const updatedResponses = report.responses.map((resp) => {
        if (resp.id === response.id) {
          return {
            ...resp,
            flagged: !response.flagged,
            flagged_notes: formData.notes
          }
        }
        return resp
      })
      return { ...report, responses: updatedResponses }
    })
    setUserObject(updatedDataArray)
    setReport(updatedDataArray[rowIndex])
    putFlagStandupAnswer(
      {
        id: response.id,
        flagged: !response.flagged,
        flaggedNotes: formData.notes
      },
      fn
    )
  }

  return (
    <div
      style={{
        margin: '30px',
        width: '100%'
      }}
    >
      <Typography variant='h6'>Flag Standup response</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='question'>Select the Question</InputLabel>
          <Select
            id='question'
            name='question'
            value={formData.question || ''}
            onChange={handleChange}
          >
            {report.responses.map((response) => {
              return (
                <MenuItem key={response.id} value={response.question}>
                  {response.question}
                </MenuItem>
              )
            })}
          </Select>
          {formData.question && (
            <Typography>Answer: {response?.answer || 'No answer'}</Typography>
          )}
        </FormControl>
        <TextareaAutosize
          className={classes.textarea}
          name='notes'
          minRows={1}
          placeholder='Write Note...'
          value={formData.notes || response?.flagged_notes}
          onChange={handleChange}
        />
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submitButton}
        >
          {response?.flagged ? 'Undo Flag' : 'Flag'}
        </Button>
      </form>
    </div>
  )
}

export const Report = (props) => {
  const { companyId, date, reportId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [newData, setData] = useState({})
  const [userObject, setUserObject] = useState([])

  // Get data from id of param's 'reportId'
  useEffect(() => {
    const fn = async (err, response) => {
      if (err) {
        throw err
      }
      await setData(response.data)
    }

    getReportAnswers({ companyId, date, reportId }, fn)
  }, [companyId, date, reportId])

  // if newData is altered, search, filter, sort, and concat newData into UserObj.
  useEffect(() => {
    if (newData?.answers?.length > 1) {
      const groupedByUser = _.chain(newData.answers)
        .groupBy('username')
        .map((value, key) => {
          if (value.length > 5) {
            value.splice(0, 4)
          }
          return {
            user: key,
            responses: value.map((response, index) => ({
              ...response,
              answerId: index
            }))
          }
        })
        .sortBy('user')
        .value()

      setUserObject(groupedByUser)
      setLoaded(true)
    }
  }, [newData])

  const columns = [
    { id: 'name', label: 'Name' },
    ...DAILY_STANDUP_QUESTION_ORDER.map((que, i) => {
      return { id: `colored${i}`, label: que }
    })
  ]

  const tableData = userObject.map((standupResponse) => {
    const rowData = {
      name: standupResponse.user
    }

    columns
      .filter((col) => col.id !== 'name')
      .forEach((column) => {
        const response = standupResponse.responses.find(
          (resp) => resp.question === column.label
        )
        rowData[column.id] = {
          text: response?.answer || '-',
          color: response?.flagged ? 'red' : null
        }
      })
    return rowData
  })

  const reportDate = new Date(newData?.scheduled_at)

  return (
    <Grid>
      {!loaded
        ? (
          <Spinner />
          )
        : (
          <Grid container>
            <h1 style={{ margin: '0 auto' }}>
              {newData.name} for {reportDate.getMonth() + 1}/
              {reportDate.getDate()}/{reportDate.getFullYear()}
            </h1>
            <CustomTable
              data={tableData}
              columns={columns}
              parseLink
              collapseComponent={
                <CollapseComponent
                  userObject={userObject}
                  setUserObject={setUserObject}
                />
              }
              collapsible
            />
          </Grid>
          )}
    </Grid>
  )
}
