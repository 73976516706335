import React, { useState, useEffect, useMemo } from 'react'
import { onboarder } from '../../../api'
import { useParams } from 'react-router-dom'
import Spinner from '../../Spinner/Spinner'
import {
  Grid,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText
} from '@material-ui/core'
import Message from './Message'
import { getNameFromEmail } from '../../../utils/commonUtils'
import { getCheckInStats } from '../../../utils/checkInUtils'
import { formatSeconds } from '../../../utils/timeUtils'

const View = () => {
  const { id } = useParams()
  const [checkIn, setCheckIn] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [reviewLoading, setReviewLoading] = useState(false)
  const [thrownError, setThrownError] = useState(null)

  useEffect(() => {
    onboarder.listCheckIns({ id }, (error, response) => {
      setLoaded(true)
      if (error) return setThrownError(error)
      if (response) {
        setCheckIn(response[0])
      }
    })
  }, [id])

  const handleSubmit = () => {
    setReviewLoading(true)
    onboarder.reviewCheckIn(checkIn._id, (error, response) => {
      setReviewLoading(false) // Move setReviewLoading(false) inside the if blocks to ensure it only stops loading when a response or error is received
      if (error) {
        return setThrownError(error)
      }
      if (response) {
        setCheckIn(prev => ({ ...prev, gptChecklist: response }))
      }
    })
  }

  const stats = useMemo(() => {
    return getCheckInStats(checkIn?.messages)
  }, [checkIn])

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded || !checkIn) return <Spinner />

  return (
    <Grid container direction='column'>
      <Grid
        style={{
          backgroundColor: '#E5E5E5',
          borderRadius: '4px',
          padding: '5px'
        }}
      >
        <Typography variant='h4' color='primary'>
          {checkIn.dev.name || getNameFromEmail(checkIn.dev.email)}
        </Typography>
        <Typography variant='subtitle1'>
          {`${checkIn.dev.checkInDay}, ${checkIn.date}`}
        </Typography>
      </Grid>
      <Paper
        style={{
          margin: '20px 0px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        variant='outlined'
        elevation={3}
      >
        <Typography variant='h5' gutterBottom>
          Stats
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Grid container direction='column' alignItems='center' spacing={1}>
              <Grid item>
                <Typography variant='h6'>Dev</Typography>
              </Grid>
              <Paper
                variant='outlined'
                elevation={3}
                style={{ padding: '10px' }}
              >
                <Grid item>
                  {Object.entries(stats.responseTime.dev).map(
                    ([key, value]) => (
                      <Typography variant='body2' key={key} gutterBottom>
                        {`${
                          key.charAt(0).toUpperCase() + key.slice(1)
                        } response time: ${formatSeconds(value)}`}
                      </Typography>
                    )
                  )}
                  <Typography variant='body2' gutterBottom>
                    {`Total word count: ${stats.wordCount.dev.total}`}
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    {`Average word count: ${stats.wordCount.dev.average}`}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction='column' alignItems='center' spacing={1}>
              <Grid item>
                <Typography variant='h6'>Check-In</Typography>
              </Grid>
              <Paper
                variant='outlined'
                elevation={3}
                style={{ padding: '10px' }}
              >
                <Grid>
                  <Typography variant='body2'>
                    {`Start time: ${stats.startTime}`}
                  </Typography>
                  <Typography variant='body2'>
                    {`End time: ${stats.endTime}`}
                  </Typography>
                  <Typography variant='body2'>
                    {`Duration: ${stats.duration}`}
                  </Typography>
                  <Typography variant='body2'>
                    {`Total word count: ${stats.wordCount.total}`}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction='column' alignItems='center' spacing={1}>
              <Grid item>
                <Typography variant='h6'>Manager</Typography>
              </Grid>
              <Paper
                variant='outlined'
                elevation={3}
                style={{ padding: '10px' }}
              >
                <Grid item>
                  {Object.entries(stats.responseTime.manager).map(
                    ([key, value]) => (
                      <Typography variant='body2' key={key} gutterBottom>
                        {`${
                          key.charAt(0).toUpperCase() + key.slice(1)
                        } response time: ${formatSeconds(value)}`}
                      </Typography>
                    )
                  )}
                  <Typography variant='body2' gutterBottom>
                    {`Total word count: ${stats.wordCount.manager.total}`}
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    {`Average word count: ${stats.wordCount.manager.average}`}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        style={{
          margin: '20px 0px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        variant='outlined'
        elevation={3}
      >
        <Typography variant='h6'>AI Generated Checklist</Typography>
        {(() => {
          if (reviewLoading) {
            return <Spinner />
          } else if (checkIn.gptChecklist) {
            return (
              <>
                <Grid>
                  <List>
                    {checkIn.gptChecklist?.covered.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <Checkbox checked disabled />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                    {checkIn.gptChecklist?.notCovered.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <Checkbox checked={false} disabled />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ margin: '10px 0px' }}
                  onClick={handleSubmit}
                >
                  Regenerate
                </Button>
              </>
            )
          } else {
            return (
              <Button
                variant='contained'
                color='primary'
                style={{ margin: '10px 0px' }}
                onClick={handleSubmit}
              >
                Generate
              </Button>
            )
          }
        })()}
      </Paper>
      <Grid container direction='column' style={{ margin: '10px' }}>
        {checkIn.messages.map(message => {
          return (
            <Message key={message._id} message={message} dev={checkIn.dev} />
          )
        })}
      </Grid>
    </Grid>
  )
}

export default View
