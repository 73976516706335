const _ = {
  filter: require('lodash/filter'),
  map: require('lodash/map'),
  find: require('lodash/find')
}
const React = require('react')
const createReactClass = require('create-react-class')
const DataFrame = require('dataframe')
const Emitter = require('wildemitter')

const partial = require('./partial')
const download = require('./download')
const getValue = require('./get-value')
const PivotTable = require('./pivot-table.jsx')
const Dimensions = require('./dimensions.jsx')
const ColumnControl = require('./column-control.jsx')

module.exports = createReactClass({
  displayName: 'ReactPivot',
  getDefaultProps: function () {
    return {
      rows: [],
      dimensions: [],
      activeDimensions: [],
      reduce: function () {},
      tableClassName: '',
      csvDownloadFileName: 'table.csv',
      csvTemplateFormat: false,
      defaultStyles: true,
      nPaginateRows: 25,
      solo: {},
      hiddenColumns: [],
      hideRows: null,
      sortBy: null,
      sortDir: 'asc',
      eventBus: new Emitter(),
      compact: false,
      excludeSummaryFromExport: false,
      onData: function () {},
      soloText: 'solo',
      subDimensionText: 'Sub Dimension...'
    }
  },

  getInitialState: function () {
    const allDimensions = this.props.dimensions
    const activeDimensions = _.filter(
      this.props.activeDimensions,
      function (title) {
        return _.find(allDimensions, function (col) {
          return col.title === title
        })
      }
    )

    return {
      dimensions: activeDimensions,
      calculations: {},
      sortBy: this.props.sortBy,
      sortDir: this.props.sortDir,
      hiddenColumns: this.props.hiddenColumns,
      solo: this.props.solo,
      hideRows: this.props.hideRows,
      rows: []
    }
  },

  UNSAFE_componentWillMount: function () {
    this.dataFrame = DataFrame({
      rows: this.props.rows,
      dimensions: this.props.dimensions,
      reduce: this.props.reduce
    })

    this.updateRows()
  },

  UNSAFE_componentWillReceiveProps: function (newProps) {
    if (newProps.hiddenColumns !== this.props.hiddenColumns) {
      this.handleHiddenColumns(newProps.hiddenColumns)
    }

    if (newProps.rows !== this.props.rows) {
      this.dataFrame = DataFrame({
        rows: newProps.rows,
        dimensions: newProps.dimensions,
        reduce: newProps.reduce
      })

      this.updateRows()
    }
  },

  getColumns: function () {
    const self = this
    const columns = []

    this.state.dimensions.forEach(function (title) {
      const d = _.find(self.props.dimensions, function (col) {
        return col.title === title
      })

      columns.push({
        type: 'dimension',
        title: d.title,
        value: d.value,
        className: d.className,
        template: d.template,
        sortBy: d.sortBy
      })
    })

    this.props.calculations.forEach(function (c) {
      if (self.state.hiddenColumns.indexOf(c.title) >= 0) return

      columns.push({
        type: 'calculation',
        title: c.title,
        template: c.template,
        value: c.value,
        className: c.className,
        sortBy: c.sortBy
      })
    })

    return columns
  },

  render: function () {
    const self = this

    const html = (
      <div className='reactPivot'>
        {this.props.hideDimensionFilter
          ? ''
          : <Dimensions
              dimensions={this.props.dimensions}
              subDimensionText={this.props.subDimensionText}
              selectedDimensions={this.state.dimensions}
              onChange={this.setDimensions} // eslint-disable-line
              downloadCSV={this.downloadCSV}
              rows={this.state.rows}
            />}

        <ColumnControl
          hiddenColumns={this.state.hiddenColumns}
          onChange={this.handleHiddenColumns}
        />

        {Object.keys(this.state.solo).map(function (title) {
          const value = self.state.solo[title]

          return (
            <div
              style={{ clear: 'both' }}
              className='reactPivot-soloDisplay'
              key={'solo-' + title}
            >
              <span
                className='reactPivot-clearSolo'
                onClick={partial(self.clearSolo, title)}
              >
                &times;
              </span>
              {title}: {value}
            </div>
          )
        })}

        <PivotTable
          columns={this.getColumns()}
          rows={this.state.rows}
          sortBy={this.state.sortBy}
          sortDir={this.state.sortDir}
          onSort={this.setSort} // eslint-disable-line
          onColumnHide={this.hideColumn} // eslint-disable-line
          nPaginateRows={this.props.nPaginateRows}
          tableClassName={this.props.tableClassName}
          onSolo={this.setSolo} // eslint-disable-line
          soloText={this.props.soloText}
        />
      </div>
    )

    return html
  },

  updateRows: function () {
    const columns = this.getColumns()

    const sortByTitle = this.state.sortBy
    const sortCol =
      _.find(columns, function (col) {
        return col.title === sortByTitle
      }) || {}
    const sortBy =
      sortCol.sortBy ||
      (sortCol.type === 'dimension' ? sortCol.title : sortCol.value)
    const sortDir = this.state.sortDir
    const hideRows = this.state.hideRows

    const calcOpts = {
      dimensions: this.state.dimensions,
      sortBy: sortBy,
      sortDir: sortDir,
      compact: this.props.compact
    }

    const filter = this.state.solo
    if (filter) {
      calcOpts.filter = function (dVals) {
        let pass = true
        Object.keys(filter).forEach(function (title) {
          if (dVals[title] !== filter[title]) pass = false
        })
        return pass
      }
    }

    const rows = this.dataFrame.calculate(calcOpts).filter(function (row) {
      return hideRows ? !hideRows(row) : true
    })
    this.setState({ rows: rows })
    this.props.onData(rows)
  },

  setDimensions: function (updatedDimensions) {
    this.props.eventBus.emit('activeDimensions', updatedDimensions)
    this.setState({ dimensions: updatedDimensions })
    setTimeout(this.updateRows, 0)
  },

  handleHiddenColumns: function (hidden) {
    this.props.eventBus.emit('hiddenColumns', hidden)
    this.setState({ hiddenColumns: hidden })
    setTimeout(this.updateRows, 0)
  },

  setSort: function (cTitle) {
    let sortBy = this.state.sortBy
    let sortDir = this.state.sortDir
    if (sortBy === cTitle) {
      sortDir = sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      sortBy = cTitle
      sortDir = 'asc'
    }

    this.props.eventBus.emit('sortBy', sortBy)
    this.props.eventBus.emit('sortDir', sortDir)
    this.setState({ sortBy: sortBy, sortDir: sortDir })
    setTimeout(this.updateRows, 0)
  },

  setSolo: function (solo) {
    const newSolo = this.state.solo
    newSolo[solo.title] = solo.value
    this.props.eventBus.emit('solo', newSolo)
    this.setState({ solo: newSolo })
    setTimeout(this.updateRows, 0)
  },

  clearSolo: function (title) {
    const oldSolo = this.state.solo
    const newSolo = {}
    Object.keys(oldSolo).forEach(function (k) {
      if (k !== title) newSolo[k] = oldSolo[k]
    })
    this.props.eventBus.emit('solo', newSolo)
    this.setState({ solo: newSolo })
    setTimeout(this.updateRows, 0)
  },

  hideColumn: function (cTitle) {
    const hidden = this.state.hiddenColumns.concat([cTitle])
    this.handleHiddenColumns(hidden)
    setTimeout(this.updateRows, 0)
  },

  downloadCSV: function (rows) {
    const self = this

    const columns = this.getColumns()

    let csv =
      _.map(columns, 'title').map(JSON.stringify.bind(JSON)).join(',') + '\n'

    const maxLevel = this.state.dimensions.length - 1
    const excludeSummary = this.props.excludeSummaryFromExport

    rows.forEach(function (row) {
      if (excludeSummary && row._level < maxLevel) return

      const vals = columns.map(function (col) {
        let val
        if (col.type === 'dimension') {
          val = row[col.title]
        } else {
          val = getValue(col, row)
        }

        if (col.template && self.props.csvTemplateFormat) {
          val = col.template(val)
        }

        return JSON.stringify(val)
      })
      csv += vals.join(',') + '\n'
    })

    download(csv, this.props.csvDownloadFileName, 'text/csv')
  }
})
