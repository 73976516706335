import { useState, useEffect } from 'react'

export default function useDynamicMaxBodyHeight (subtractHeight = 230) {
  const [maxBodyHeight, setMaxBodyHeight] = useState('400px')

  useEffect(() => {
    const updateMaxBodyHeight = () => {
      const calculatedHeight = `${window.innerHeight - subtractHeight}px`
      setMaxBodyHeight(calculatedHeight)
    }

    updateMaxBodyHeight()

    window.addEventListener('resize', updateMaxBodyHeight)

    return () => window.removeEventListener('resize', updateMaxBodyHeight)
  }, [subtractHeight])

  return maxBodyHeight
}
