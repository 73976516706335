import config from './config'
import qs from 'qs'

import auth from './components/authentic'
const { apiUrl, onboarderApiUrl } = config

export const onboarder = {
  listDevs,
  updateDev,
  createDev,
  listScripts,
  createScript,
  updateScript,
  listSlackWorkspaces,
  createSlackWorkspace,
  updateSlackWorkspace,
  listArticles,
  createArticle,
  updateArticle,
  listPlans,
  listCheckIns,
  fetchSlackFile,
  reviewPlan,
  reviewCheckIn,
  listProjects,
  createProject,
  updateProject,
  fetchSlackSequence,
  listSlackSequences,
  createSlackSequence,
  updateSlackSequence,
  listManagers,
  createManager,
  updateManager,
  getSlackSequenceReport
}

function listDevs (opts, cb) {
  console.log(opts)
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/devs${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}

function updateDev (dev, cb) {
  const url = `${onboarderApiUrl}/admin/devs/${dev.id}`
  return auth.put(url, dev, cb)
}

function createDev (dev, cb) {
  const url = `${onboarderApiUrl}/admin/devs`
  return auth.post(url, dev, cb)
}

function listArticles (opts, cb) {
  console.log(opts)
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/articles${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}

function updateArticle (article, cb) {
  const url = `${onboarderApiUrl}/admin/articles/${article.id}`
  return auth.put(url, article, cb)
}

function createArticle (article, cb) {
  const url = `${onboarderApiUrl}/admin/articles`
  return auth.post(url, article, cb)
}

function listScripts (opts, cb) {
  console.log(opts)
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/scripts${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}

function updateScript (script, cb) {
  const url = `${onboarderApiUrl}/admin/scripts/${script.id}`
  return auth.put(url, script, cb)
}

function createScript (script, cb) {
  const url = `${onboarderApiUrl}/admin/scripts`
  return auth.post(url, script, cb)
}

function listSlackWorkspaces (opts, cb) {
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/slack-workspaces${
    query ? `?${query}` : ''
  }`
  return auth.get(url, cb)
}

function updateSlackWorkspace (slackWorkspace, cb) {
  const url = `${onboarderApiUrl}/admin/slack-workspaces/${slackWorkspace.id}`
  return auth.put(url, slackWorkspace, cb)
}

function createSlackWorkspace (slackWorkspace, cb) {
  const url = `${onboarderApiUrl}/admin/slack-workspaces`
  return auth.post(url, slackWorkspace, cb)
}

function listPlans (opts, cb) {
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/plans${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}

function listCheckIns (opts, cb) {
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/check-ins${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}

export const getCompanies = fn => {
  // GET Companies
  const url = `${apiUrl}/companies`
  return auth.get(url, fn)
}

export const getSingleCompany = (id, fn) => {
  const url = `${apiUrl}/companies/${id}`
  // Init Auth
  return auth.get(url, fn)
}

export const createCompany = (name, fn) => {
  const url = `${apiUrl}/companies`
  return auth.post(url, { name }, fn)
}

export const getCompanyReports = ({ id, startDate, endDate }, fn) => {
  const url = `${apiUrl}/companies/${id}/reports/${startDate}/${endDate}`
  // Init Auth
  return auth.get(url, fn)
}

export const getCompanyEngineers = (id, fn) => {
  const url = `${apiUrl}/companies/${id}/engineers`
  // Init Auth
  return auth.get(url, fn)
}

export const getReportAnswers = ({ companyId, date, reportId }, fn) => {
  const url = `${apiUrl}/answers/${companyId}/report/${date}/${reportId}`
  return auth.get(url, fn)
}

export const getCompanyAnswersByDate = (
  { companyId, dateStart, dateEnd },
  fn
) => {
  const url = `${apiUrl}/answers/${companyId}/date/${dateStart}/${dateEnd}`
  return auth.get(url, fn)
}

export const getEngineer = (companyId, engineerId, fn) => {
  return auth.get(`${apiUrl}/engineers/${companyId}/${engineerId}`, fn)
}

export const updateEngineer = ({ engineerId, companyId, details }, fn) => {
  return auth.put(
    `${apiUrl}/engineers/${companyId}/${engineerId}`,
    { ...details },
    fn
  )
}

export const getEngineerStanduplyAnswers = (
  { companyId, engineerId, startDate, endDate },
  fn
) => {
  return auth.get(
    `${apiUrl}/answers/${companyId}/${engineerId}/${startDate}/${endDate}`,

    fn
  )
}

export const putFlagStandupAnswer = ({ id, flagged, flaggedNotes }, fn) => {
  return auth.put(
    `${apiUrl}/answers/flag/${id}`,
    { flagged, flaggedNotes },

    fn
  )
}

export const getPRs = ({ companyId, dateStart, dateEnd }, fn) => {
  const url = `${apiUrl}/pull-requests/${companyId}/${dateStart}/${dateEnd}`
  return auth.post(url, {}, fn)
}

export const getIssues = (
  { companyId, repo, label, dateStart, dateEnd },
  fn
) => {
  const url = `${apiUrl}/issues/${companyId}/${dateStart}/${dateEnd}`
  return auth.post(url, { repo, label }, fn)
}

function fetchSlackFile (fileUrl, filename) {
  return `${onboarderApiUrl}/slack/fetch-file?fileUrl=${encodeURIComponent(
    fileUrl
  )}&filename=${encodeURIComponent(filename)}`
}

function reviewPlan (plan, cb) {
  const url = `${onboarderApiUrl}/admin/gpt/review-plan`
  return auth.post(url, plan, cb)
}

function reviewCheckIn (checkInId, cb) {
  const url = `${onboarderApiUrl}/admin/gpt/review-check-in/${checkInId}`
  return auth.get(url, cb)
}

function listProjects (opts, cb) {
  console.log(opts)
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/projects${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}

function updateProject (project, cb) {
  const url = `${onboarderApiUrl}/admin/projects/${project.id}`
  return auth.put(url, project, cb)
}

function createProject (project, cb) {
  const url = `${onboarderApiUrl}/admin/projects`
  return auth.post(url, project, cb)
}

function fetchSlackSequence (id, cb) {
  const url = `${onboarderApiUrl}/admin/slack-sequences/${id}`
  return auth.get(url, cb)
}

function listSlackSequences (opts, cb) {
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/slack-sequences${
    query ? `?${query}` : ''
  }`
  return auth.get(url, cb)
}

function createSlackSequence (slackSequence, cb) {
  const url = `${onboarderApiUrl}/admin/slack-sequences`
  return auth.post(url, slackSequence, cb)
}

function updateSlackSequence (id, slackSequence, cb) {
  const url = `${onboarderApiUrl}/admin/slack-sequences/${id}`
  return auth.put(url, slackSequence, cb)
}

function listManagers (opts, cb) {
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/managers${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}

function updateManager (manager, cb) {
  const url = `${onboarderApiUrl}/admin/managers/${manager.id}`
  return auth.put(url, manager, cb)
}

function createManager (manager, cb) {
  const url = `${onboarderApiUrl}/admin/managers`
  return auth.post(url, manager, cb)
}

function getSlackSequenceReport (id, opts, cb) {
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  opts.limit = 5000

  const query = qs.stringify(opts)
  const url = `${onboarderApiUrl}/admin/slack-sequences/${id}/report${query ? `?${query}` : ''}`
  return auth.get(url, cb)
}
