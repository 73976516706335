import React from 'react'
import { Grid, Button, useTheme, useMediaQuery } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import MaterialTable from 'material-table'

import { tableIcons } from '../Devs/icons'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'
import CustomTableCell from '../../CustomTableCell/CustomTableCell'

export default function ArticlesList () {
  document.title = 'Articles | Superstruct Platform'

  const { id: companyId } = useParams()
  const [articles, setArticles] = React.useState([])
  const [thrownError, setThrownError] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const maxBodyHeight = useDynamicMaxBodyHeight(230)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    onboarder.listArticles(companyId, (error, response) => {
      if (response) {
        setLoaded(true)
        setArticles(response)
      } else {
        setLoaded(true)
        setThrownError(error)
      }
    })
  }, []) //eslint-disable-line

  if (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  return (
    <>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginBottom: '10px' }}
            component={Link}
            to='/onboarder/articles/create'
          >
            Add Article
          </Button>
          <MaterialTable
            style={{ fontSize: isMobile ? '90%' : '75%' }}
            icons={tableIcons}
            title='Articles'
            columns={[
              { title: 'Title', field: 'title' },
              {
                title: 'URL',
                field: 'url',
                cellStyle: {
                  maxWidth: 200,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }
              },
              {
                title: 'Body',
                field: 'body',
                cellStyle: {
                  maxWidth: 200,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }
              },
              { title: 'Questions', field: 'questions.length', emptyValue: '0' }
            ]}
            data={articles}
            options={{
              search: true,
              paging: false,
              filtering: true,
              maxBodyHeight
            }}
            actions={[
              rowData => ({
                icon: () => (
                  <Link
                    to={`/onboarder/articles/${rowData.id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <tableIcons.Edit />
                  </Link>
                ),
                tooltip: 'Edit'
              })
            ]}
            components={{
              Cell: (props) =>
                <CustomTableCell props={props} isMobile={isMobile} />
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
