import qs from 'qs'

const config = {
  apiUrl:
    process.env.REACT_APP_FRONTEND_DOMAIN ||
    'https://api.platform.superstruct.tech',
  onboarderApiUrl:
    process.env.REACT_APP_ONBOARDER_API_URL || 'https://api.onboarder.ai',
  testFlag: false
}

Object.keys(config).forEach(key => {
  const lsVal = localStorage.getItem(key)
  if (lsVal) config[key] = lsVal

  const qsVal = qs.parse(window.location.search.slice(1))[key]
  if (qsVal) {
    config[key] = qsVal
    localStorage.setItem(key, qsVal)
  }

  if (config[key] === 'true') config[key] = true
  if (config[key] === 'false') config[key] = false
})

export default config
