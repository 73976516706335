import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'

const DraggableTable = ({ actions, columns, rows, setRows, handleEdit }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const newRows = Array.from(rows)
    const [reorderedItem] = newRows.splice(result.source.index, 1)
    newRows.splice(result.destination.index, 0, reorderedItem)
    setRows(newRows)
  }

  const handleDelete = (id) => {
    const newRows = rows.filter((row) => row.id !== id)
    setRows(newRows)
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <TableContainer
              component={Paper}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Table style={{ width: '100%', tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    {actions.includes('order') && (
                      <TableCell style={{ width: '10%' }} />
                    )}
                    {columns.map((column) => {
                      const cellWidth =
                        (100 - actions.length * 10) / (columns.length || 1)
                      return (
                        <TableCell
                          key={column.label}
                          style={{ width: `${cellWidth}%` }}
                        >
                          {column.label}
                        </TableCell>
                      )
                    })}
                    {actions.includes('edit') && (
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    )}
                    {actions.includes('delete') && (
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <Draggable
                        key={row.id}
                        draggableId={row.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {actions.includes('order') && (
                              <TableCell style={{ width: '10%' }}>
                                <IconButton>
                                  <DragHandleIcon />
                                </IconButton>
                              </TableCell>
                            )}
                            {columns.map((column) => {
                              const cellWidth =
                                (100 - actions.length * 10) /
                                (columns.length || 1)
                              return (
                                <TableCell
                                  key={row[column.name]}
                                  style={{
                                    width: `${cellWidth}%`,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {row[column.name]}
                                </TableCell>
                              )
                            })}
                            {actions.includes('edit') && (
                              <TableCell style={{ width: '10%' }}>
                                <IconButton onClick={() => handleEdit(row)}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            )}
                            {actions.includes('delete') && (
                              <TableCell style={{ width: '10%' }}>
                                <IconButton
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <DeleteIcon color='error' />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default DraggableTable
