import React, { useState } from 'react'
import {
  Button,
  Grid,
  TextField,
  Modal,
  makeStyles
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'

import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import DraggableTable from '../../DraggableTable/DraggableTable'
import uniqueId from 'lodash/uniqueId'

function getModalStyle () {
  const top = 40
  const left = 40

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '@media (max-width: 550px)': {
      width: '80%'
    }
  }
}))

export default function ArticlesCreate () {
  const history = useHistory()
  const [article, setArticle] = useState({
    title: '',
    url: '',
    body: ''
  })
  const [questions, setQuestions] = useState([])
  const [action, setAction] = useState('Add')
  const [question, setQuestion] = useState({
    body: '',
    correctAnswers: '',
    wrongAnswers: ''

  })
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(true)

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  const handleCreate = () => {
    setLoaded(false)
    const newQuestions = questions.map(question => {
      // remove the temporary id used for draggable table
      if (question.id.includes('temp-id-')) {
        const { id, ...rest } = question
        return rest
      } else {
        return question
      }
    })
    const newArticle = { ...article, questions: newQuestions }
    onboarder.createArticle(newArticle, (error, response) => {
      if (error) {
        setThrownError(error)
        setLoaded(true)
        return
      }
      setLoaded(true)
      history.push(`/onboarder/articles/${response[0].id}`)
    })
  }

  const handleAddQuestion = () => {
    if (question.body) {
      const id = uniqueId('temp-id-')
      setQuestions(questions => [...questions, { ...question, id }])
    }
    setQuestion({
      body: '',
      correctAnswers: '',
      wrongAnswers: ''
    })
    setOpen(false)
  }

  const handleUpdateQuestion = () => {
    if (question.body) {
      setQuestions(prevQuestion => {
        return prevQuestion.map((ques) => {
          if (ques.id === question.id) {
            const id = question.id.includes('temp-id-')
              ? uniqueId('temp-id-')
              : question.id
            return { ...question, id }
          }
          return ques
        })
      })
    }
    setQuestion({
      body: '',
      correctAnswers: '',
      wrongAnswers: ''
    })
    setOpen(false)
  }
  const columns = [
    { name: 'body', label: 'Body' },
    { name: 'correctAnswers', label: 'Correct Answers' },
    { name: 'wrongAnswers', label: 'Wrong Answers' }
  ]
  const handleTableEdit = (rowData) => {
    setQuestion(rowData)
    setAction('Update')
    setOpen(true)
  }

  return (
    <Grid>
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label='Title'
                value={article.title}
                onChange={e => setArticle({ ...article, title: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='URL'
                value={article.url}
                onChange={e => setArticle({ ...article, url: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ maxWidth: '80ch' }}>
              <TextField
                label='Body'
                multiline
                rows={24}
                variant='outlined'
                value={article.body}
                onChange={e => setArticle({ ...article, body: e.target.value })}
                fullWidth
              />
            </Grid>
            {questions?.length
              ? (
                <DraggableTable
                  columns={columns}
                  rows={questions}
                  setRows={setQuestions}
                  actions={['order', 'delete', 'edit']}
                  handleEdit={handleTableEdit}
                />
                )
              : null}
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='default'
                startIcon={<AddIcon />}
                onClick={() => {
                  setAction('Add')
                  setOpen(true)
                }}
              >
                Add questions
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleCreate}
              >
                Create Article
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={modalStyle} className={classes.paper}>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label='Body'
                  value={question.body}
                  onChange={e => setQuestion({ ...question, body: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Correct Answers'
                  multiline
                  minRows={5}
                  variant='outlined'
                  value={question.correctAnswers}
                  onChange={e => setQuestion({ ...question, correctAnswers: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Wrong Answers'
                  multiline
                  minRows={5}
                  variant='outlined'
                  value={question.wrongAnswers}
                  onChange={e => setQuestion({ ...question, wrongAnswers: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ margin: '0px auto' }}
                  onClick={() => {
                    if (action === 'Update') {
                      handleUpdateQuestion()
                    } else {
                      handleAddQuestion()
                    }
                  }}
                >
                  {`${action} Question`}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </Grid>
  )
}
