
import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'

import { getPRs } from '../../api'

import StateHandler from '../StateHandler'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  projectName: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  prList: {
    marginTop: '0px',
    marginBottom: '0px',
    paddingTop: '0px',
    paddingBottom: '0px'
  }
}))

const prSortIndex = [
  'feat', 'fix', 'refactor', 'style', 'test', 'docs', 'perf', 'build', 'ci'
]

const GroupTitle = ({ title, prs, companyId }) => {
  const classes = useStyles()
  const issues = _
    .chain(title.split(','))
    .flatMap(t => _.flatMap(prs, 'linkedEpics')
      .find(e => e.name === t))
    .compact()
    .value()

  return (
    <div>
      <Typography variant='h6' variantMapping={{ h6: 'h3' }} className={classes.projectName}>
        {issues.length > 0
          ? (
              issues.map((issue, index) => (
                <a key={index} href={_.get(issue, 'githubUrl')} target='_blank' rel='noopener noreferrer'>
                  {
                  (issues.length > 1 && index + 1 !== issues.length)
                    ? `${issue.name}, `
                    : issue.name
                }
                </a>
              ))
            )
          : title}
      </Typography>
    </div>
  )
}

const PullRequests = ({
  dateStart,
  dateEnd,
  companyId,
  prLoaded,
  setPrLoaded,
  prCount,
  setPrCount,
  pullReqErr,
  setPullReqErr
}) => {
  const classes = useStyles()

  const [records, setRecords] = useState([])

  const fn = useCallback((error, response) => {
    if (error) return setPullReqErr(error.message || error.toString())

    const { data = [] } = response
    const prsExceptAnalytics = _.filter(
      data, pr => pr.link.split('/')[4] !== 'analytics-dbt-models'
    )
    setPrCount(prsExceptAnalytics.length)
    setRecords(categorizePRs(prsExceptAnalytics))
    setPullReqErr(null)

    setPrLoaded(true)
  }, [])   // eslint-disable-line

  const fetchPullRequests = useCallback((dateStart, dateEnd) => {
    setPrLoaded(false)
    setPullReqErr(null)
    setPrCount(0)

    getPRs({
      companyId,
      dateStart,
      dateEnd
    }, fn)
  }, [companyId, fn]) // eslint-disable-line

  useEffect(() => {
    fetchPullRequests(dateStart, dateEnd)
  }, [companyId, dateStart, dateEnd, fetchPullRequests])

  return (
    <div>
      <h2>Pull Requests ({prCount})</h2>
      <StateHandler isErr={pullReqErr} isLoaded={prLoaded}>
        {_.map(records, (categories, categoryKey) => {
          return (
            <div key={categoryKey}>
              {_.map(categories, (prs, title) => {
                return (
                  <div key={title}>
                    <GroupTitle title={title} prs={prs} companyId={companyId} />
                    <ul className={classes.prList}>
                      {_.map(prs, (pr, index) => {
                        return (
                          <li key={index}>
                            <a href={pr.link} target='_blank' rel='noopener noreferrer'>
                              {pr.title}
                            </a>
                          </li>
                        )
                      })}
                    </ul>

                  </div>
                )
              })}
            </div>
          )
        })}
      </StateHandler>
    </div>
  )
}

function categorizePRs (prs) {
  const sortedPrs = _
    .chain(prs)
    .sortBy(sortByCommitType)
    .groupBy(groupByCategory)
    .value()

  const rankedProjects = _
    .chain(sortedPrs.projects)
    .flatMap(pr => pr.linkedEpics)
    .groupBy('name')
    .map((prjs, name) => ({
      name, count: prjs.length, url: prjs[0].githubUrl, prs: []
    }))
    .orderBy(['count'], ['asc'])
    .value()

  const finalizedProjects = _
    .chain(sortedPrs.projects)
    .reduce((acc, pr) => {
      const topIndex = _.chain(pr)
        .thru(pr => _.flatMap(pr.linkedEpics))
        .map(project => _.findIndex(rankedProjects, { name: project.name }))
        .max()
        .value()

      const topProject = rankedProjects[topIndex].name
      if (!acc[topProject]) acc[topProject] = []
      acc[topProject].push(pr)
      return acc
    }, {})
    .value()

  return [
    finalizedProjects,
    { 'Bugs/Misc': sortedPrs.bugsMisc }
  ]
}

function sortByCommitType (pr) {
  const commitType = (pr.title || '').substring(0, 10)
  const index = _.findIndex(prSortIndex, type => commitType.includes(type))
  return index === -1 ? prSortIndex.length : index
}

function groupByCategory (pr) {
  if (pr.linkedEpics.length > 0) return 'projects'
  return 'bugsMisc'
}

export default PullRequests
