import React from 'react'
import { getCompanyEngineers } from '../../api'
import { mdToHtml } from '../../utils/answerHandlers'
import StateHandler from '../StateHandler'

const replaceUserId = (engineers, answer) => {
  let updatedAnswer = answer

  engineers.forEach(engineer => {
    if (answer.includes(engineer.standuply_user_id)) {
      const regex = new RegExp(`(<@${engineer.standuply_user_id}>)`, 'g')
      updatedAnswer = answer.replace(regex, engineer.standuply_username)
    }
  })

  return updatedAnswer
}

const VagueWordingReport = ({ companyId, userReport }) => {
  const [error, setError] = React.useState(null)
  const [engineers, setEngineers] = React.useState([])

  const engineerFn = (error, response) => {
    if (error) setError(error)
    else if (response) setEngineers(response.data)
  }

  React.useEffect(() => {
    getCompanyEngineers(companyId, engineerFn)
  }, [companyId])

  return (
    <div>
      <h3> Vague Wording </h3>
      <StateHandler isErr={error} isLoaded>
        {userReport.map(user => (
          user.vagueWording.length
            ? (
              <div key={user.name}>
                <p>{user.name} - {user.vagueWording.length}</p>
                <ul>
                  {user.vagueWording.map((answer, index) => (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: mdToHtml(replaceUserId(engineers, answer.answer))
                      }}
                    />
                  ))}
                </ul>
              </div>
              )
            : ''
        ))}
      </StateHandler>
    </div>
  )
}

export default VagueWordingReport
