import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  TextField
} from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'

export default function SlackWorkspacesEdit () {
  document.title = 'Edit Slack Workspace | Superstruct Platform'

  const { id } = useParams()
  const [slackWorkspace, setSlackWorkspace] = useState(null)
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (slackWorkspace) setLoaded(true)
  }, [slackWorkspace])

  useEffect(() => {
    onboarder.listSlackWorkspaces({ id }, (error, response) => {
      if (error) return setThrownError(error)
      if (response) setSlackWorkspace(response[0])
    })
  }, [id])

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  const handleUpdate = () => {
    setLoaded(false)
    onboarder.updateSlackWorkspace(slackWorkspace, (error, response) => {
      if (error) {
        setThrownError(error)
        setLoaded(true)
        return
      }
      setSlackWorkspace(response)
      setLoaded(true)
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setSlackWorkspace((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <Grid>
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name='Name'
                label='Name'
                value={slackWorkspace.Name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='slackWebhookUrl'
                label='Slack Webhook URL'
                value={slackWorkspace.slackWebhookUrl}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='teamId'
                label='Team ID'
                value={slackWorkspace.teamId}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='zenhubWorkspaceId'
                label='Zenhub Workspace ID'
                value={slackWorkspace.zenhubWorkspaceId}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='zenhubToken'
                label='Zenhub Token'
                value={slackWorkspace.zenhubToken}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='zenhubAlertSlackChannel'
                label='Slack Channel for Zenhub Notifications'
                value={slackWorkspace.zenhubAlertSlackChannel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Grid>
  )
}
