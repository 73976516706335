import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'

const Spinner = ({ p = 10 }) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      p={p}
    >
      <CircularProgress />
    </Box>
  )
}

export default Spinner
