export const ACTION_TYPES = {
  FETCH_COMPANIES: 'GET_COMPANIES',
  CREATE_COMPANY: 'CREATE_COMPANY',
  SELECT_COMPANY: 'SELECT_COMPANY'
}

const CompanyReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_COMPANIES:
      return {
        ...state,
        companies: action.payload
      }

    case ACTION_TYPES.CREATE_COMPANY:
      return {
        ...state,
        companies: [...state.companies, action.payload]
      }

    case ACTION_TYPES.SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload
      }

    default:
      return state
  }
}

export default CompanyReducer
