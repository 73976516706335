const _ = { without: require('lodash/without') }
const React = require('react')
const createReactClass = require('create-react-class')

module.exports = createReactClass({
  getDefaultProps () {
    return {
      hiddenColumns: [],
      onChange () {}
    }
  },

  render () {
    if (this.props.hiddenColumns.length === 0) return ''

    return (
      <div className='reactPivot-columnControl'>
        <select value='' onChange={this.handleShowColumn}>
          <option value=''>Hidden Columns</option>
          {this.props.hiddenColumns.map(column => (
            <option key={column}>{column}</option>
          ))}
        </select>
      </div>
    )
  },

  handleShowColumn (evt) {
    const col = evt.target.value
    const hidden = _.without(this.props.hiddenColumns, col)
    this.props.onChange(hidden)
  }
})
