import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Button, Modal, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import MaterialTable from 'material-table'

import { tableIcons } from '../Devs/icons'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'
import { getNameFromEmail } from '../../../utils/commonUtils'
import ProjectInput from './ProjectInput'
import ProjectsEdit from '../Projects/Edit'
import DevEdit from '../Devs/Edit'
import DevNameInput from './DevNameInput'
import CustomTableCell from '../../CustomTableCell/CustomTableCell'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '900px',
    height: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    overflowY: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '@media (max-width: 600px)': {
      width: '80%'
    }
  }
}))

export default function SlackWorkspacesView () {
  const { id } = useParams()
  const classes = useStyles()
  const [devs, setDevs] = useState(null)
  const [projects, setProjects] = useState([])
  const [open, setOpen] = useState(null)
  const [project, setProject] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [thrownError, setThrownError] = useState(null)
  const [fetch, setFetch] = useState(false)
  const maxBodyHeight = useDynamicMaxBodyHeight(190)
  const [selectedDev, setSelectedDev] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [groupFilterApplied, setGroupFilterApplied] = useState(true)

  useEffect(() => {
    onboarder.listDevs({ slackWorkspaceId: id }, (error, response) => {
      setLoaded(true)
      if (error) return setThrownError(error)
      if (response) {
        const removeDevArchived = response.filter(dev => !dev.archived)
        const devNamesAdded = removeDevArchived.map((dev) => {
          if (!dev.name) dev.name = getNameFromEmail(dev.email)
          dev.groupNumber = dev.groupNumber || 'Unassigned'
          return dev
        })
        setDevs(devNamesAdded)
      }
    })
    onboarder.listProjects({}, (error, response) => {
      setLoaded(true)
      if (error) return setThrownError(error)
      if (response) {
        const projects = response.filter(project => {
          return project.slackWorkspaceId === id && !project.done
        })
        setProjects(projects)
      }
    })
  }, [id, fetch])

  function updateDevProject (e, devData) {
    const { name: index, value: projectId } = e.target

    const prevProjectIds = devData.projectQueues.map(proj => {
      return proj ? proj.id : null
    })
    const projectData = projects.find(project => project.id === projectId)
    if (!devData.projectQueues[index]?.id) {
      const newDevData = { ...devData }
      newDevData.projectQueues[index] = projectData
      const projectQueues = [...prevProjectIds]
      projectQueues[index] = projectData.id

      onboarder.updateDev({ ...newDevData, projectQueues }, (error, res) => {
        if (error) return setThrownError(error)

        updateDevProjectsUI(newDevData)
        setProjectAssignee(projectData, devData.id)
      })
      return
    }

    removeProjectAssignee(devData.id, devData.projectQueues[index]?.id)

    const newDevData = { ...devData }
    const projectQueues = devData.projectQueues.filter(proj => {
      return proj?.id !== projectId
    })
    projectQueues.splice(index, 1, projectData)
    newDevData.projectQueues = projectQueues.map(proj => {
      return proj ? proj.id : null
    })
    onboarder.updateDev(newDevData, (error, response) => {
      if (error) return setThrownError(error)

      updateDevProjectsUI({ ...newDevData, projectQueues })
      if (prevProjectIds.includes(projectId)) return

      setProjectAssignee(projectData, devData.id)
    })
  }

  function removeProjectAssignee (dev, projectId) {
    if (!projectId) return

    const project = projects.find(p => p.id === projectId)
    project.assignees = project.assignees.filter(id => id !== dev.id)
    onboarder.updateProject(project, (error, response) => {
      if (error) return setThrownError(error)
      onboarder.listDevs({ email: dev.email }, (error, response) => {
        if (error) return setThrownError(error)
        if (response) {
          const dev = response[0]
          updateDevProjectsUI(dev)
        }
      })
    })
  }

  function setProjectAssignee (projectData, devId) {
    if (!projectData.assignees) {
      projectData.assignees = []
    }
    projectData.assignees.push(devId)
    onboarder.updateProject(projectData, (error, response) => {
      if (error) return setThrownError(error)
    })
  }

  function updateDevProjectsUI (devData) {
    setDevs(devs.map(dev => dev.id === devData.id ? devData : dev))
  }

  function handleRemove (index, dev) {
    const projectQueues = [...dev.projectQueues]
    const projectData = projectQueues[index]
    projectQueues.splice(index, 1)
    removeProjectAssignee(dev, projectData.id)
  }

  function handleReorder (rowData) {
    const newDevData = { ...rowData }
    newDevData.projectQueues = rowData.projectQueues
      .filter(Boolean)
      .map(p => p.id)
    onboarder.updateDev(newDevData, (error, response) => {
      if (error) return setThrownError(error)
      if (response) {
        updateDevProjectsUI(response)
      }
    })
  }

  function handleEdit (index, dev) {
    const projectQueues = [...dev.projectQueues]
    const projectData = projectQueues[index]
    setProject(projectData)
    setOpen(1)
  }

  function handleEditDev (rowData) {
    setSelectedDev(rowData)
    setOpen(2)
  }

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded || !devs) return <Spinner />

  return (
    <Grid container>
      <Grid item style={{ width: '100%' }}>
        <MaterialTable
          style={{ fontSize: isMobile ? '90%' : '75%' }}
          icons={tableIcons}
          title={
            `Developer Allocation View | ${devs[0]?.slackWorkspace?.Name || ''}`
          }
          columns={[
            {
              title: 'Name',
              field: 'name',
              render: (rowData) => (
                <DevNameInput
                  devData={rowData}
                  handleEditDev={handleEditDev}
                />
              )
            },
            {
              title: 'Tech Stack',
              field: 'techStack',
              lookup: devs.reduce((acc, dev) => {
                if (dev.techStack) acc[dev.techStack] = dev.techStack
                return acc
              }, {})
            },
            {
              title: 'Group Number',
              field: 'groupNumber',
              defaultGroupOrder: groupFilterApplied ? 0 : undefined
            },
            {
              title: '1',
              field: 'projectQueues[0].title',
              render: (rowData, index) => (
                <ProjectInput
                  rowData={rowData}
                  projects={projects}
                  handleRemove={handleRemove}
                  handleEdit={handleEdit}
                  updateDevProject={updateDevProject}
                  index={0}
                />
              )
            },
            {
              title: '2',
              field: 'projectQueues[1].title',
              render: (rowData) => (
                <ProjectInput
                  rowData={rowData}
                  projects={projects}
                  handleRemove={handleRemove}
                  handleEdit={handleEdit}
                  updateDevProject={updateDevProject}
                  index={1}
                />
              )
            },
            {
              title: '3',
              field: 'projectQueues[2].title',
              render: (rowData) => (
                <ProjectInput
                  rowData={rowData}
                  projects={projects}
                  handleRemove={handleRemove}
                  handleEdit={handleEdit}
                  updateDevProject={updateDevProject}
                  index={2}
                />
              )
            },
            {
              title: '',
              field: 'c',
              filtering: false,
              render: (rowData) => rowData?.projectQueues?.length
                ? (
                  <Button
                    color='default'
                    onClick={() => handleReorder(rowData)}
                  >
                    Shift
                  </Button>
                  )
                : null
            }
          ]}
          onGroupRemoved={() => setGroupFilterApplied(false)}
          data={devs}
          options={{
            search: true,
            paging: false,
            filtering: true,
            grouping: true,
            maxBodyHeight
          }}
          components={{
            Cell: (props) =>
              <CustomTableCell props={props} isMobile={isMobile} />
          }}
        />
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          setFetch(prev => !prev)
          setOpen(null)
          setSelectedDev(null)
        }}
      >
        <div className={classes.paper}>
          {open === 1 && <ProjectsEdit projectData={project} />}
          {open === 2 && <DevEdit devData={selectedDev} />}
        </div>
      </Modal>
    </Grid>
  )
}
