export const PLANS_FILTER = [
  {
    field: 'date',
    type: 'string'
  },
  {
    field: 'plan',
    type: 'string'
  },
  {
    field: 'gptFlagged',
    type: 'array'
  },
  {
    field: 'gptComment',
    type: 'string'
  },
  {
    field: 'wronglyFlaggedReason',
    type: 'string'
  },
  {
    field: 'planAchieved',
    type: 'array'
  },
  {
    field: 'planNotAchievedReason',
    type: 'string'
  },
  {
    field: 'revisionCount',
    type: 'string'
  }
]
