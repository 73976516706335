export {
  formatSeconds,
  convertDateToPT,
  getTimeDifference
}

function formatSeconds (seconds) {
  if (!seconds) return '0'
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const formattedSeconds = String(Math.floor(remainingSeconds)).padStart(
    2,
    '0'
  )
  if (minutes > 0) return `${minutes} min ${formattedSeconds} sec`
  return `${formattedSeconds} sec`
}

function convertDateToPT (date) {
  return new Date(date).toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'America/Los_Angeles'
  })
}

function getTimeDifference (time1, time2) {
  if (!time1 || !time2) return 0
  const timeDiff = new Date(time2) - new Date(time1)
  return timeDiff / 1000
}
