import React, { useRef, useEffect } from 'react'
import { Container, Paper } from '@material-ui/core'

export default function (fn, ...args) {
  return function () {
    const element = useRef(null)

    useEffect(() => {
      const el = fn(...args) || document.createElement('div')
      element.current.appendChild(el)
    }, [element])

    return (
      <>
        <Container maxWidth='sm'>
          <Paper elevation={1}>
            <div ref={element} />
          </Paper>
        </Container>
      </>
    )
  }
}
