import React, { useMemo } from 'react'
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Input,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import Refresh from '@material-ui/icons/Refresh'
import cuid from 'cuid'

import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import { monthsBetween } from '../../../utils/dateUtils'
import { DAYS_OF_WEEK } from '../../../constants/date'
import { TECH_STACKS } from '../../../constants/devs'

export default function DevEdit ({ devData }) {
  const params = useParams()

  const email = devData?.email || params.email

  if (!devData?.email) {
    document.title = `${email} | Devs | Superstruct Platform`
  }

  const [dev, setDev] = React.useState(devData)
  const [scripts, setScripts] = React.useState([])
  const [slackWorkspaces, setSlackWorkspaces] = React.useState([])
  const [managers, setManagers] = React.useState([])
  const [thrownError, setThrownError] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const [errors, setErrors] = React.useState({
    email: '',
    checkInChannelId: '',
    slackUserId: '',
    slackWorkspace: ''
  })
  const slackWorkspaceId = dev?.slackWorkspace?.id

  const validateForm = () => {
    const newErrors = {}
    if (!dev.email) {
      newErrors.email = 'Email is required'
    }
    if (!dev.checkInChannelId) {
      newErrors.checkInChannelId = 'Check-in channel ID is required'
    }
    if (!dev.slackUserId) {
      newErrors.slackUserId = 'Slack user ID is required'
    }
    if (!dev.slackWorkspace.id) {
      newErrors.slackWorkspace = 'Slack workspace is required'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  React.useEffect(() => {
    if (dev && scripts.length && slackWorkspaces.length) setLoaded(true)
  }, [dev, scripts, slackWorkspaces])

  React.useEffect(() => {
    onboarder.listDevs({ email }, (error, response) => {
      if (error) return setThrownError(error)
      if (response) setDev(response[0])
    })

    onboarder.listScripts((error, response) => {
      if (error) return setThrownError(error)
      setScripts(response)
    })

    onboarder.listSlackWorkspaces((error, response) => {
      if (error) return setThrownError(error)
      setSlackWorkspaces(response)
    })

    onboarder.listManagers((error, response) => {
      if (error) return setThrownError(error)
      setManagers(response)
    })
  }, [email])

  React.useEffect(() => {
    if (slackWorkspaceId) {
      const defaultManager = managers.find(
        manager => manager.slackWorkspaceId === slackWorkspaceId
      )
      setDev(prev => ({
        ...prev,
        manager: defaultManager
      }))
    }
  }, [slackWorkspaceId, managers])

  const filteredManagers = useMemo(() => {
    return managers.filter(
      manager => manager.slackWorkspaceId === slackWorkspaceId
    )
  }, [slackWorkspaceId, managers])

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  const handleUpdate = () => {
    if (!validateForm()) {
      return
    }
    setLoaded(false)
    dev.projectQueues = dev.projectQueues.map(proj => proj?.id)
    onboarder.updateDev(dev, (error, response) => {
      if (error) {
        setThrownError(error)
        setLoaded(true)
        return
      }
      setDev(response)
      setLoaded(true)
    })
  }

  return (
    <Grid>
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label='Name'
                value={dev.name}
                onChange={e => setDev({ ...dev, name: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.email}
                helperText={errors.email}
                label='Email'
                value={dev.email}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tech Stack</InputLabel>
                <Select
                  value={dev.techStack}
                  onChange={e => setDev({ ...dev, techStack: e.target.value })}
                >
                  {TECH_STACKS.map(stack => (
                    <MenuItem key={stack.value} value={stack.value}>
                      {stack.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Group Number'
                type='number'
                value={dev.groupNumber}
                onChange={e => {
                  setDev({ ...dev, groupNumber: parseInt(e.target.value) })
                }}
                onKeyPress={e => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Location'
                value={dev.location}
                onChange={e => setDev({ ...dev, location: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Upwork profile URL'
                type='url'
                value={dev.upworkProfileUrl}
                onChange={e => setDev({ ...dev, upworkProfileUrl: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Rate'
                type='number'
                value={dev.rate}
                onChange={e => setDev({ ...dev, rate: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <FormControl fullWidth>
                  <InputLabel>Key</InputLabel>
                  <Input
                    value={dev.key}
                    onChange={e => setDev({ ...dev, key: e.target.value })}
                    fullWidth
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() =>
                            setDev({ ...dev, key: cuid.slug().toUpperCase() })}
                        >
                          <Refresh />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dev.aiPlanReview}
                    onChange={e =>
                      setDev({ ...dev, aiPlanReview: e.target.checked })}
                  />
                }
                label='AI Plan Review'
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Check In Day</InputLabel>
                    <Select
                      name='checkInDay'
                      value={dev.checkInDay || ''}
                      onChange={e => {
                        setDev({ ...dev, checkInDay: e.target.value })
                      }}
                    >
                      {DAYS_OF_WEEK.map(day => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                  <TextField
                    label='Check In Time - Pacific Time Zone (PT)'
                    InputLabelProps={{
                      shrink: true
                    }}
                    name='checkInTime'
                    type='time'
                    onChange={e => setDev({ ...dev, checkInTime: e.target.value })}
                    value={dev.checkInTime}
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                  <TextField
                    label='Check In Duration(Minutes)'
                    InputLabelProps={{
                      shrink: true
                    }}
                    name='checkInDuration'
                    type='number'
                    onChange={e => setDev({ ...dev, checkInDuration: e.target.value })}
                    value={dev.checkInDuration}
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.checkInChannelId}
                helperText={errors.checkInChannelId}
                label='Check In Channel ID'
                value={dev.checkInChannelId}
                onChange={e =>
                  setDev({ ...dev, checkInChannelId: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='AI Alert Channel ID'
                value={dev.aiAlertChannelId}
                onChange={e =>
                  setDev({ ...dev, aiAlertChannelId: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.slackUserId}
                helperText={errors.slackUserId}
                label='Slack User ID'
                value={dev.slackUserId}
                onChange={e => setDev({ ...dev, slackUserId: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Slack Workspace</InputLabel>
                    <Select
                      required
                      error={!!errors.slackWorkspace}
                      value={slackWorkspaces.length && dev.slackWorkspace?.id}
                      onChange={e => {
                        const selectedWorkspace = slackWorkspaces.find(
                          workspace => workspace.id === e.target.value
                        )
                        setDev({ ...dev, slackWorkspace: selectedWorkspace })
                      }}
                    >
                      {slackWorkspaces.map(workspace => (
                        <MenuItem key={workspace.id} value={workspace.id}>
                          {workspace.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Manager</InputLabel>
                    <Select
                      value={dev.manager?.id || ''}
                      onChange={e => {
                        const selectedManager = managers.find(
                          manager => manager.id === e.target.value
                        )
                        setDev({ ...dev, manager: selectedManager })
                      }}
                    >
                      {filteredManagers.map((manager) => (
                        <MenuItem key={manager.id} value={manager.id}>
                          {manager.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Script</InputLabel>
                <Select
                  value={scripts.length && dev.script?.id}
                  onChange={e => {
                    const selectedScript = scripts.find(
                      script => script.id === e.target.value
                    )
                    setDev({ ...dev, script: selectedScript })
                  }}
                >
                  {scripts.map(script => (
                    <MenuItem key={script.id} value={script.id}>
                      {script.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <InputLabel>Last raised: </InputLabel>
                </Grid>
                <Grid item>
                  <TextField
                    name='lastRaised'
                    type='date'
                    onChange={e => setDev({ ...dev, lastRaised: e.target.value })}
                    defaultValue={dev.lastRaised}
                    variant='outlined'
                    size='small'
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    {dev.lastRaised && monthsBetween(dev.lastRaised)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <InputLabel>Onboarded: </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='onboarded'
                    type='date'
                    onChange={e => setDev({ ...dev, onboarded: e.target.value })}
                    defaultValue={dev.onboarded}
                    variant='outlined'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <InputLabel>Offboarded: </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='offboarded'
                    type='date'
                    onChange={e => setDev({ ...dev, offboarded: e.target.value })}
                    defaultValue={dev.offboarded}
                    variant='outlined'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Archived</InputLabel>
                <Select
                  value={dev.archived}
                  onChange={e =>
                    setDev({ ...dev, archived: e.target.value })}
                >
                  <MenuItem value>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Grid>
  )
}
