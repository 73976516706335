import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

import MaterialTable from 'material-table'
import { tableIcons } from '../Devs/icons'
import { mdToHtml } from '../../../utils/answerHandlers'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'

export default function PlanAnalysis ({ plans, filters, handleFilterChange }) {
  const [achievedRate, setAchievedRate] = useState(0)
  const maxBodyHeight = useDynamicMaxBodyHeight(400)

  useEffect(() => { // set achieved rate
    const achieved = plans.filter(plan => plan.planAchieved)
    const achievedRate = (achieved.length / plans.length) * 100

    setAchievedRate(Math.round(achievedRate) || 0)
  }, [plans])

  return (
    <Grid item xs={12}>
      <MaterialTable
        onFilterChange={handleFilterChange}
        style={{ fontSize: '75%' }}
        icons={tableIcons}
        title={`Achieved Rate  ${achievedRate}%`}
        columns={[
          {
            title: 'Date',
            field: 'date',
            defaultSort: 'desc',
            defaultFilter: filters?.date || ''
          },
          {
            title: 'Plan',
            field: 'plan',
            defaultFilter: filters?.plan || '',
            render: rowData => (
              <div
                dangerouslySetInnerHTML={{
                  __html: `- ${mdToHtml(rowData.plan)}`
                }}
              />
            )
          },
          {
            title: 'AI Accepted',
            field: 'gptFlagged',
            defaultFilter: filters?.gptFlagged || '',
            lookup: { true: '🚫', false: '✅' }
          },
          {
            title: 'Incorrectly flagged reason',
            field: 'wronglyFlaggedReason',
            defaultFilter: filters.wronglyFlaggedReason
          },
          {
            title: 'Plan Achieved',
            field: 'planAchieved',
            defaultFilter: filters?.planAchieved,
            lookup: { true: '✅', false: '🚫', Unavailable: '⏳' }
          },
          {
            title: 'AI comment',
            field: 'gptComment',
            defaultFilter: filters?.gptComment || ''
          },
          {
            title: 'Reason',
            field: 'planNotAchievedReason',
            defaultFilter: filters?.planNotAchievedReason || ''
          },
          {
            title: 'Revisions',
            field: 'revisionCount',
            defaultFilter: filters?.revisionCount || ''
          }
        ]}
        data={plans}
        options={{
          paging: false,
          filtering: true,
          maxBodyHeight
        }}
      />
    </Grid>
  )
}
