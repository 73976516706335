import React from 'react'
import { onboarder } from '../../../api'
import { Grid, Typography, Box } from '@material-ui/core'
import { slackToHtml } from '../../../utils/formattingUtils'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { replaceMentions } from '../../../utils/checkInUtils'

const Message = ({ message, dev }) => {
  const time = new Date(message.timestamp_utc).toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/Los_Angeles'
  })

  return (
    <Grid
      style={{
        backgroundColor: message.name ? '#E5E5E5' : '#2E9DFB',
        color: message.name ? '#000000' : '#FFFFFF',
        borderRadius: '8px',
        padding: '10px',
        width: 'fit-content',
        margin: '5px 0px'
      }}
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='subtitle2' style={{ marginRight: '10px' }}>
          {message.name || 'Manager'}
        </Typography>
        <Typography variant='caption'>{time}</Typography>
      </Box>
      {message.files?.map((file) => {
        if (!file.mimetype?.startsWith('image/')) {
          return (
            <Box
              key={file.id}
              style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              <AttachmentIcon />
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={onboarder.fetchSlackFile(file.url_private, file.title)}
              >
                {file.title}
              </a>
            </Box>
          )
        }
        return (
          <div key={file.id}>
            <img
              src={onboarder.fetchSlackFile(file.url_private, file.title)}
              alt='slack'
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        )
      })}
      <div
        dangerouslySetInnerHTML={{
          __html: slackToHtml(replaceMentions(message.text, dev))
        }}
      />
    </Grid>
  )
}

export default Message
