import URLON from 'urlon'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Grid,
  Typography,
  TextField,
  Paper,
  makeStyles,
  Box
} from '@material-ui/core'
import accounting from 'accounting'
import WildEmitter from 'wildemitter'
import Tooltip from '@material-ui/core/Tooltip'
import Spinner from '../../Spinner/Spinner'
import { onboarder } from '../../../api'
import { getNDaysAgo } from '../../../utils/dateUtils'
import { formatSeconds } from '../../../utils/timeUtils'
import { getCheckInStats } from '../../../utils/checkInUtils'

const Pivot = require('../../Pivot/index.jsx')

const useStyles = makeStyles(theme => ({
  buttons: {
    '& > *': {
      marginTop: '20px'
    }
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '20px'
  }
}))

const CheckInsList = () => {
  document.title = 'Check-Ins | Superstruct Platform'

  const location = useLocation()
  const pivotState = location.hash.substring(1)
    ? URLON.parse(location.hash.substring(1))
    : {}

  console.log(location.hash.substring(1), decodeURIComponent(location.hash.substring(1)))

  console.log(pivotState)

  const classes = useStyles()
  const history = useHistory()
  const defaultFilters = {
    dateStart: pivotState.dateStart || getNDaysAgo(14),
    dateEnd: pivotState.dateEnd || getNDaysAgo(0)
  }

  const [checkIns, setCheckIns] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [thrownError, setThrownError] = useState(null)
  const [dateStart, setDateStart] = useState(defaultFilters.dateStart)
  const [dateEnd, setDateEnd] = useState(defaultFilters.dateEnd)

  useEffect(onDateEffect, [dateStart, dateEnd])

  function onDateEffect () {
    pivotState.dateStart = dateStart
    pivotState.dateEnd = dateEnd
    history.replace(`#${URLON.stringify(pivotState)}`)
    console.log(URLON.stringify(pivotState))

    onboarder.listCheckIns({ dateStart, dateEnd }, function (err, res) {
      setLoaded(true)

      if (!res || err) return setThrownError(err)
      const filteredCheckIns = res
        .map(row => {
          const stats = getCheckInStats(row.messages)
          return {
            ...row,
            name: row.dev.name,
            email: row.dev.email,
            managerName: row.dev.manager?.name,
            slackWorkspace: row.dev.slackWorkspace.Name,
            messagesCount: row.messages.length,
            gptChecklist: getChecklistCoverage(row.gptChecklist),
            devMessagesCount: stats.wordCount.dev.count,
            totalWordCount: stats.wordCount.total,
            devWordCount: stats.wordCount.dev.total,
            averageDevResponseTime: stats.responseTime.dev.average
          }
        })
        .filter(row => row.devMessagesCount > 0)

      setCheckIns(filteredCheckIns)
    })
  }

  function handleDateStartChange (newDateStart) {
    if (newDateStart > dateEnd) setDateEnd(newDateStart)
    setDateStart(newDateStart)
  }

  function handleDateEndChange (newDateEnd) {
    if (newDateEnd < dateStart) setDateStart(newDateEnd)
    setDateEnd(newDateEnd)
  }
  function getChecklistCoverage (checklist) {
    checklist = checklist || { covered: [], notCovered: [] }

    const coverage = {}

    checklist.covered.forEach(item => {
      const key = item.split(':')[0]
      coverage[key] = true
    })

    checklist.notCovered.forEach(item => {
      const key = item.split(':')[0]
      coverage[key] = false
    })

    return coverage
  }

  if (thrownError) return renderError(thrownError)
  if (!loaded) return <Spinner />

  const rows = checkIns

  const dimensions = [
    {
      value: '_id',
      title: 'ID',
      template: v => (
        <a
          href={`/onboarder/check-ins/${v}`}
          target='_blank'
          className='monospace'
          rel='noopener noreferrer'
        >
          {v.slice(-8)}
        </a>
      )
    },
    {
      title: 'Date',
      value: 'date'
    },
    {
      title: 'Name',
      value: 'name'
    },
    {
      title: 'Email',
      value: 'email'
    },
    {
      title: 'Slack Workspace',
      value: 'slackWorkspace'
    },
    {
      title: 'Manager',
      value: 'managerName'
    }
  ]

  const calculations = [
    {
      title: 'Check-Ins',
      value: 'checkInCount',
      template: accounting.formatNumber
    },
    {
      title: 'Messages',
      value: 'averageMessagesCount',
      template: v => accounting.formatNumber(v, 1)
    },
    {
      title: 'Messages (Dev)',
      value: 'averageDevMessagesCount',
      template: v => accounting.formatNumber(v, 1)
    },
    // {
    //   title: 'Messages (Mgmt)',
    //   value: 'averageManagerMessagesCount',
    //   template: v => accounting.formatNumber(v, 1)
    // },
    {
      title: 'Word Count',
      value: 'averageWordCount',
      template: v => accounting.formatNumber(v, 0)
    },
    {
      title: 'Word Count (Dev)',
      value: 'averageDevWordCount',
      template: v => accounting.formatNumber(v, 0)
    },
    // {
    //   title: 'Word Count (Mgmt)',
    //   value: 'averageManagerWordCount',
    //   template: v => accounting.formatNumber(v, 1)
    // },
    {
      title: 'Words Per Msg (Dev)',
      value: 'wordsPerMessageDev',
      template: v => accounting.formatNumber(v, 1)
    },
    {
      title: 'Response Time (Dev)',
      value: 'averageDevResponseTime',
      template: v => formatSeconds(v)
    },
    {
      title: 'Covered Checklist',
      value: 'checklistCovered',
      template: (v, m) => (
        <div title=''>
          <Tooltip title={
            <pre style={{ fontSize: '1.2em' }}>
              {_.reduce(m.checklistCoverage, (items, val, key) => (
                val ? `✅ ${key}\n` + items : items + `❌ ${key}\n`
              ), '')}
            </pre>
            }
          >
            <p title=''>{v}/{m.checklistTotal}</p>
          </Tooltip>
        </div>
      )
    }
  ]

  const reduce = function (row, memo) {
    memo.checkInCount = (memo.checkInCount || 0) + 1
    memo.messagesCountAll = memo.messagesCountAll || []
    memo.messagesCountAll.push(row.messagesCount)
    memo.averageMessagesCount = avgArray(memo.messagesCountAll)

    memo.devMessagesCountAll = memo.devMessagesCountAll || []
    memo.devMessagesCountAll.push(row.devMessagesCount)
    memo.averageDevMessagesCount = avgArray(memo.devMessagesCountAll)

    memo.averageManagerMessagesCount =
      memo.averageMessagesCount - memo.averageDevMessagesCount

    memo.wordCountAll = memo.wordCountAll || []
    memo.wordCountAll.push(row.totalWordCount)
    memo.averageWordCount = avgArray(memo.wordCountAll)

    memo.wordCountDev = memo.wordCountDev || []
    memo.wordCountDev.push(row.devWordCount)
    memo.averageDevWordCount = avgArray(memo.wordCountDev)

    memo.averageManagerWordCount =
      memo.averageWordCount - memo.averageDevWordCount

    memo.checklistCoverage = memo.checklistCoverage || {}
    Object.keys(row.gptChecklist).forEach(key => {
      memo.checklistCoverage[key] =
        memo.checklistCoverage[key] || row.gptChecklist[key]
    })
    memo.checklistCovered = Object.values(memo.checklistCoverage).filter(
      Boolean
    ).length

    memo.checklistTotal = Object.values(memo.checklistCoverage).length

    memo.averageDevResponseTimes = memo.averageDevResponseTimes || []
    memo.averageDevResponseTimes.push(row.averageDevResponseTime)
    memo.averageDevResponseTime = avgArray(memo.averageDevResponseTimes)

    memo.wordsPerMessageDev =
      memo.averageDevWordCount / memo.averageDevMessagesCount
    memo.wordsPerMessageManager =
      memo.averageManagerWordCount / memo.averageManagerMessagesCount

    return memo
  }

  const eventBus = new WildEmitter()
  eventBus.on('*', (event, value) => {
    pivotState[event] = value
    history.replace(`#${URLON.stringify(pivotState)}`)
  })

  return (
    <Box>
      {renderDateFilter()}
      <Pivot
        sortBy={pivotState.sortBy}
        sortDir={pivotState.sortDir}
        solo={pivotState.solo}
        rows={rows}
        dimensions={dimensions}
        calculations={calculations}
        reduce={reduce}
        eventBus={eventBus}
        activeDimensions={
          pivotState.activeDimensions || ['Slack Workspace', 'Name']
        }
        compact
        nPaginateRows={Infinity}
      />
    </Box>
  )

  function renderError (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  function renderDateFilter () {
    return (
      <Box>
        <Paper className={classes.paper} variant='outlined' elevation={3}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2}>
                <Typography
                  variant='subtitle1'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Date Range:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  label='Date Start'
                  id='dateStart'
                  name='dateStart'
                  type='date'
                  value={dateStart}
                  onChange={e => handleDateStartChange(e.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  label='Date End'
                  id='dateEnd'
                  name='dateEnd'
                  type='date'
                  value={dateEnd}
                  onChange={e => handleDateEndChange(e.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    )
  }
}

function avgArray (arr) {
  const filteredArr = arr.filter(Number.isFinite)
  return filteredArr.reduce((acc, curr) => acc + curr, 0) / filteredArr.length
}

export default CheckInsList
