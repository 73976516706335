import React, { useState } from 'react'
import {
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { makeStyles } from '@material-ui/core/styles'
import { onboarder } from '../../../api'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '700px'
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    padding: '7px'
  },
  cardContent: {
    backgroundColor: theme.palette.grey[100]
  }
}))

const ReviewPlan = () => {
  const classes = useStyles()
  const [planInput, setPlanInput] = useState('')
  const [copyText, setCopyText] = useState('Copy')
  const [isEmptyPlan, setIsEmptyPlan] = useState(false)
  const [disableCopy, setDisableCopy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [output, setOutput] = useState({})
  const [submitError, setSubmitError] = useState('')

  const copyToClipboard = async () => {
    const text = Object.entries(output)
      .map(
        ([key, value]) =>
          `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`
      )
      .join('\n')

    await navigator.clipboard.writeText(text)
    setCopyText('Text Copied')
    setDisableCopy(true)
    setTimeout(() => {
      setCopyText('Copy')
      setDisableCopy(false)
    }, 3000)
  }

  const handleSubmit = () => {
    if (!planInput.trim()) return setIsEmptyPlan(true)
    setLoading(true)
    setSubmitError('')
    onboarder.reviewPlan({ plan: planInput }, (error, res) => {
      if (error) {
        setSubmitError(error?.body?.error || 'Something went wrong')
        setLoading(false)
        return
      }
      setOutput({ plan: planInput, ...res })
      setLoading(false)
    })
  }

  const getColor = (value) => {
    if (value === 'Nebulous Effort') return '#ff0000'
    if (value === 'Verifiable Output') return '#4BB543'
    return null
  }

  return (
    <Grid container spacing={1} direction='column'>
      <Grid item>
        <TextField
          label='Plan'
          error={isEmptyPlan}
          multiline
          rows={5}
          value={planInput}
          onChange={(e) => {
            setPlanInput(e.target.value)
            setIsEmptyPlan(false)
          }}
          variant='outlined'
          FormHelperTextProps={{ error: !!submitError }}
          helperText={submitError}
          style={{ maxWidth: '700px' }}
          fullWidth
        />
      </Grid>
      <Grid item>
        <Button
          variant='contained'
          color='primary'
          style={{ margin: '10px 0px' }}
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </Button>
      </Grid>
      <Grid item>
        <Card className={classes.card} variant='outlined'>
          <CardHeader
            className={classes.cardHeader}
            title={
              <Typography variant='subtitle2' color='textSecondary'>
                AI Output
              </Typography>
            }
            action={
              output.plan && (
                <Button onClick={copyToClipboard} disabled={disableCopy}>
                  <FileCopyIcon
                    fontSize='small'
                    style={{ marginRight: '5px' }}
                  />
                  <Typography variant='caption'>{copyText}</Typography>
                </Button>
              )
            }
          />
          <CardContent className={classes.cardContent}>
            {Object.entries(output).map(([key, value]) => {
              return (
                <Typography
                  variant='body2'
                  key={key}
                  style={{ margin: '5px 0px' }}
                >
                  <span>{`${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  }: `}
                  </span>
                  <span style={{ color: getColor(value) }}>
                    {`${value}`}
                  </span>
                </Typography>
              )
            })}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ReviewPlan
