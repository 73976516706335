import React from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Breadcrumbs
} from '@material-ui/core'
import {
  Link,
  useParams,
  useRouteMatch,
  useHistory,
  Switch,
  Route
} from 'react-router-dom'
import { getCompanyEngineers } from '../../api'
import Spinner from '../Spinner/Spinner'
import CustomTable from '../CustomTable/CustomTable'
import EngineerDetailsView from './EngineerDetailsView'
import EngineerStandupAnswers from '../Report/EngineerStandupAnswers'

const columns = [
  { id: 'no', label: 'No' },
  { id: 'name', label: 'Name' },
  { id: 'action1', label: 'Action' },
  { id: 'action2', label: 'Action' }
]

export default function EngineersView () {
  const { id: companyId } = useParams()
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const [engineers, setEngineers] = React.useState([])
  const [engineer, setEngineer] = React.useState(null)
  const [thrownError, setThrownError] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const [activeStatus, setActiveStatus] = React.useState('Active')
  const [filteredEngineers, setFilteredEngineers] = React.useState([])

  const fn = (error, response) => {
    if (response) {
      setLoaded(true)
      const sortedEngineers = response.data.sort((a, b) => {
        if (a.first_name < b.first_name) return -1
        if (a.first_name > b.first_name) return 1
        return 0
      })
      setEngineers(sortedEngineers)
      setFilteredEngineers(sortedEngineers.filter(e => e.active))
    } else {
      setLoaded(true)
      setThrownError(error)
    }
  }

  React.useEffect(() => {
    getCompanyEngineers(companyId, fn)
  }, []) //eslint-disable-line

  const handleActiveStatusChange = (evt) => {
    setActiveStatus(evt.target.value)
    const filterByActiveStatus = engineers.filter((engineer) => {
      if (evt.target.value === 'All') return true
      return engineer.active === (evt.target.value === 'Active')
    })
    setFilteredEngineers(filterByActiveStatus)
  }
  const handleDetails = (rowNumber) => {
    setEngineer(filteredEngineers[rowNumber])
    history.push(`${url}/details/${filteredEngineers[rowNumber].id}`)
  }

  const handleAnswers = (rowNumber) => {
    setEngineer(filteredEngineers[rowNumber])
    history.push(`${url}/answers/${filteredEngineers[rowNumber].id}`)
  }
  const tableData = filteredEngineers.map((eng, i) => {
    const name = eng.first_name + ' ' + eng.last_name

    return {
      no: i + 1,
      name: name,
      action1: { text: 'Details', handler: handleDetails },
      action2: { text: 'Answers', handler: handleAnswers }
    }
  })

  const currentURL = window.location.href

  return (
    <>
      <Grid>
        {thrownError
          ? (
            <Grid>{`ERROR: ${thrownError}`}</Grid>
            )
          : !loaded
              ? (
                <Spinner />
                )
              : (
                <>
                  {currentURL.includes('answers') ||
            currentURL.includes('details')
                    ? (
                      <>
                        <Breadcrumbs
                          aria-label='breadcrumb'
                          style={{ margin: '10px 0px' }}
                        >
                          <Link
                            component={Link}
                            to='/'
                            color='inherit'
                            onClick={() => history.push(url)}
                          >
                            Engineers
                          </Link>
                          <Link component={Link} to='/child2' color='inherit'>
                            {(engineer?.first_name || '') +
                      ' ' +
                      (engineer?.last_name || '')}
                          </Link>
                        </Breadcrumbs>
                        <Switch>
                          <Route path={`${path}/details/:engineerId`}>
                            <EngineerDetailsView
                              setEngineer={setEngineer}
                              engineer={engineer}
                            />
                          </Route>
                          <Route path={`${path}/answers/:engineerId`}>
                            <EngineerStandupAnswers
                              setEngineer={setEngineer}
                              engineer={engineer}
                            />
                          </Route>
                        </Switch>
                      </>
                      )
                    : (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <FormControl style={{ float: 'left', width: '30%' }}>
                              <InputLabel id='active-status-select-label'>
                                Active Status
                              </InputLabel>
                              <Select
                                labelId='active-status-select-label'
                                id='active-status-select'
                                value={activeStatus}
                                label='Status'
                                onChange={handleActiveStatusChange}
                              >
                                <MenuItem value='Active'>Active</MenuItem>
                                <MenuItem value='Inactive'>Inactive</MenuItem>
                                <MenuItem value='All'>All</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <CustomTable data={tableData} columns={columns} width='600px' />
                      </>
                      )}
                </>
                )}
      </Grid>
    </>
  )
}
