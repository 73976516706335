import React, { useState, useEffect, useRef } from 'react'
import { updateEngineer, getEngineer } from '../../api'
import { useParams } from 'react-router'
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography
} from '@material-ui/core'
import Spinner from '../Spinner/Spinner'

export default function EngineerDetailsView ({ setEngineer, engineer }) {
  const prevValuesRef = useRef({ ...engineer })
  const [formData, setFormData] = useState(engineer)
  const [submitStatus, setSubmitStatus] = useState('')
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const { id: companyId, engineerId } = useParams()

  const engineerFn = (error, response) => {
    const setMessage = (message) => {
      setSubmitStatus(message)
      setTimeout(() => {
        setSubmitStatus('')
      }, 4000)
    }
    if (!error) {
      setMessage('success')
      return
    }
    setMessage('failed')
  }

  const handleFormSubmit = async e => {
    e.preventDefault()

    const details = {}

    for (const key in formData) {
      if (formData[key] !== prevValuesRef.current[key]) {
        details[key] = formData[key]
      }
    }
    if (!Object.keys(details).length) {
      return
    }

    await updateEngineer({ engineerId, companyId, details }, engineerFn)
    prevValuesRef.current = formData
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    const fieldValue = name === 'active' ? value === 'true' : value
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue
    }))
  }

  useEffect(() => {
    if (engineer) {
      setLoaded(true)
    }
  }, [
    engineer,
    setLoaded
  ])

  const getEngineerFn = (error, response) => {
    if (error) {
      setThrownError(error)
      return
    }
    setEngineer(response.data)
    setFormData(response.data)
  }

  useEffect(() => {
    getEngineer(companyId, engineerId, getEngineerFn)
  }, []) // eslint-disable-line

  return (
    <>
      {thrownError
        ? <div>{`ERROR: ${thrownError}`} </div>
        : !loaded
            ? <Spinner />
            : (
              <form onSubmit={handleFormSubmit} style={{ margin: '20px auto' }}>
                <Grid container spacing={2} direction='column' justifyContent='center'>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='First Name'
                      variant='outlined'
                      name='first_name'
                      value={formData.first_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Last Name'
                      variant='outlined'
                      name='last_name'
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='ID'
                      variant='outlined'
                      name='standuply_user_id'
                      value={formData.standuply_user_id}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>Status</InputLabel>
                      <Select
                        name='active'
                        value={formData.active}
                        onChange={handleInputChange}
                        label='Active'
                      >
                        <MenuItem value='true'>Active</MenuItem>
                        <MenuItem value='false'>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Grid>
                      {submitStatus === 'success' && (
                        <Typography variant='subtitle1' style={{ color: 'green' }}>
                          Form submitted successfully!
                        </Typography>
                      )}
                      {submitStatus === 'failed' && (
                        <Typography variant='subtitle1' color='error'>
                          Form submission failed.
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        fullWidth
                        size='large'
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              )}
    </>
  )
}
