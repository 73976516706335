import React from 'react'
import { Grid } from '@material-ui/core'
import { PopulateReports } from './PopulateReports'
export const CompanyReportDetails = (id) => {
  return (
    <Grid>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Grid item>
          <PopulateReports id={id} />
        </Grid>
      </Grid>
    </Grid>
  )
}
