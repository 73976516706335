import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Paper, IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { onboarder } from '../../../api'
import SlackSequenceForm from './SlackSequenceForm'
import Spinner from '../../Spinner/Spinner'

function SlackSequenceCreate () {
  const history = useHistory()
  const [developers, setDevelopers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const initialSequence = {
    name: '',
    timeoutStr: '',
    finishMessage: '',
    nodes: [{
      id: 'intro',
      type: 'statement',
      text: '',
      onSuccess: '',
      onFail: ''
    }]
  }

  useEffect(() => {
    fetchDevelopers()
  }, [])

  const fetchDevelopers = () => {
    onboarder.listDevs((err, data) => {
      if (err) {
        setError('Failed to fetch developers')
      } else {
        setDevelopers(data)
      }
      setIsLoading(false)
    })
  }

  const handleSubmit = (newSequence) => {
    onboarder.createSlackSequence(newSequence, (err, data) => {
      if (err) {
        console.error('Error creating slack sequence:', err)
        setError('Failed to create slack sequence')
      } else {
        history.push('/onboarder/slack-sequences')
      }
    })
  }

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <Paper style={{ padding: '2rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <IconButton onClick={() => history.push('/onboarder/slack-sequences')} aria-label='back'>
          <ArrowBack />
        </IconButton>
        <Typography variant='h4' style={{ marginLeft: '1rem' }}>
          Create Slack Sequence
        </Typography>
      </div>
      <SlackSequenceForm
        initialSequence={initialSequence}
        onSubmit={handleSubmit}
        submitButtonText='Create Sequence'
        developers={developers}
      />
    </Paper>
  )
}

export default SlackSequenceCreate
