import React, { useEffect } from 'react'
import clsx from 'clsx'
import { Switch, Route } from 'react-router-dom'
import {
  makeStyles,
  Drawer,
  Container,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import AuthGuard from './guard/AuthGuard'
import CompanyProvider from './components/CompanyProvider/CompanyProvider'
import CompanyView from './components/CompanyView'
import DevsList from './components/onboarder/Devs/List'
import DevsEdit from './components/onboarder/Devs/Edit'
import DevsCreate from './components/onboarder/Devs/Create'
import ArticlesList from './components/onboarder/Articles/List'
import ArticlesEdit from './components/onboarder/Articles/Edit'
import { Report } from './components/Report/Report'
import { SidebarList } from './components/SidebarList/SidebarList.js'

import './App.css'
import CompanyListView from './components/CompanyListView'
import TitleBar from './components/TitleBar'
import ArticlesCreate from './components/onboarder/Articles/Create.jsx'
import ScriptsList from './components/onboarder/Scripts/List.jsx'
import ScriptsEdit from './components/onboarder/Scripts/Edit.jsx'
import ScriptsCreate from './components/onboarder/Scripts/Create.jsx'
import SlackWorkspacesList from './components/onboarder/SlackWorkspaces/List.jsx'
import SlackWorkspacesEdit from './components/onboarder/SlackWorkspaces/Edit.jsx'
import SlackWorkspacesCreate from './components/onboarder/SlackWorkspaces/Create.jsx'
import SlackWorkspacesView from './components/onboarder/SlackWorkspaces/View.jsx'
import PlansList from './components/onboarder/Plans/List.jsx'
import CheckInsList from './components/onboarder/CheckIns/List.jsx'
import CheckInView from './components/onboarder/CheckIns/View.jsx'
import ProjectsList from './components/onboarder/Projects/List.jsx'
import ProjectsEdit from './components/onboarder/Projects/Edit.jsx'
import ProjectsCreate from './components/onboarder/Projects/Create.jsx'
import ManagersList from './components/onboarder/Managers/List.jsx'
import ManagersEdit from './components/onboarder/Managers/Edit.jsx'
import ManagersCreate from './components/onboarder/Managers/Create.jsx'
import Insights from './components/onboarder/DevInsights/Insights.jsx'
import SlackSequencesList from './components/onboarder/SlackSequences/List.jsx'
import SlackSequencesCreate from './components/onboarder/SlackSequences/Create.jsx'
import SlackSequencesEdit from './components/onboarder/SlackSequences/Edit.jsx'
import SlackSequencesReport from './components/onboarder/SlackSequences/Report.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 20
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  headerLink: {
    color: 'white',
    textDecoration: 'none'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(9)
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}))

function App () {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setOpen(!isMobile)
  }, [isMobile])

  return (
    <AuthGuard>
      <CompanyProvider>
        <div className={classes.root}>
          <TitleBar open={open} setOpen={setOpen} />
          <Drawer
            variant='permanent'
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              )
            }}
            open={open}
          >
            <div className={classes.appBarSpacer} />
            <SidebarList />
          </Drawer>

          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth='xl' className={classes.container}>
              <Switch>
                <Route
                  path='/onboarder/articles/create'
                  render={() => <ArticlesCreate />}
                />
                <Route
                  path='/onboarder/articles/:id'
                  render={() => <ArticlesEdit />}
                />
                <Route
                  path='/onboarder/articles'
                  render={() => <ArticlesList />}
                />
                <Route
                  path='/onboarder/devs/create'
                  render={() => <DevsCreate />}
                />
                <Route
                  path='/onboarder/devs/:email'
                  render={() => <DevsEdit />}
                />
                <Route path='/onboarder/devs' render={() => <DevsList />} />
                <Route
                  path='/onboarder/dev-insight/:id'
                  render={() => <Insights />}
                />
                <Route
                  path='/onboarder/scripts/create'
                  render={() => <ScriptsCreate />}
                />
                <Route
                  path='/onboarder/scripts/:id'
                  render={() => <ScriptsEdit />}
                />
                <Route
                  path='/onboarder/scripts'
                  render={() => <ScriptsList />}
                />
                <Route
                  path='/onboarder/slack-workspaces/create'
                  render={() => <SlackWorkspacesCreate />}
                />
                <Route
                  path='/onboarder/slack-workspaces/view/:id'
                  render={() => <SlackWorkspacesView />}
                />
                <Route
                  path='/onboarder/slack-workspaces/:id'
                  render={() => <SlackWorkspacesEdit />}
                />
                <Route
                  path='/onboarder/slack-workspaces'
                  render={() => <SlackWorkspacesList />}
                />
                <Route path='/onboarder/plans' render={() => <PlansList />} />
                <Route
                  path='/onboarder/check-ins/:id'
                  render={() => <CheckInView />}
                />
                <Route
                  path='/onboarder/check-ins'
                  render={() => <CheckInsList />}
                />
                <Route
                  path='/onboarder/projects/create'
                  render={() => <ProjectsCreate />}
                />
                <Route
                  path='/onboarder/projects/:id'
                  render={() => <ProjectsEdit />}
                />
                <Route
                  path='/onboarder/projects'
                  render={() => <ProjectsList />}
                />
                <Route
                  path='/onboarder/managers/create'
                  render={() => <ManagersCreate />}
                />
                <Route
                  path='/onboarder/managers/:id'
                  render={() => <ManagersEdit />}
                />
                <Route
                  path='/onboarder/managers'
                  render={() => <ManagersList />}
                />
                <Route
                  path='/onboarder/slack-sequences/create'
                  render={() => <SlackSequencesCreate />}
                />
                <Route
                  path='/onboarder/slack-sequences/:id/report'
                  render={() => <SlackSequencesReport />}
                />
                <Route
                  path='/onboarder/slack-sequences/:id'
                  render={() => <SlackSequencesEdit />}
                />
                <Route
                  path='/onboarder/slack-sequences'
                  render={() => <SlackSequencesList />}
                />
                <Route path='/company/:id' render={() => <CompanyView />} />
                <Route
                  path='/reports/:companyId/:date/:reportId'
                  render={() => <Report />}
                />

                <Route path='/' render={() => <CompanyListView />} />
              </Switch>
            </Container>
          </main>
        </div>
      </CompanyProvider>
    </AuthGuard>
  )
}

export default App
