import _ from 'lodash'

export {
  calculateMedian
}

function calculateMedian (numbers) {
  if (!numbers.length) return
  const sortedNumbers = _.sortBy(numbers)
  const middleIndex = Math.floor(sortedNumbers.length / 2)

  if (sortedNumbers.length % 2 === 0) {
    return (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2
  }
  return sortedNumbers[middleIndex]
}
