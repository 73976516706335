import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'

export default function ProjectsEdit ({ projectData }) {
  if (!projectData?.id) {
    document.title = 'Edit Project | Superstruct Platform'
  }

  const { id } = useParams()
  const projectId = projectData?.id || id
  const [project, setProject] = useState(projectData)
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [slackWorkspaces, setSlackWorkspaces] = useState([])
  const [projects, setProjects] = useState([])
  const [epics, setEpics] = useState([])
  const [devs, setDevs] = useState([])
  const slackWorkspaceId = project?.slackWorkspaceId
  const [errors, setErrors] = useState({
    email: '',
    checkInChannelId: '',
    slackUserId: '',
    slackWorkspace: ''
  })

  const validateForm = () => {
    const newErrors = {}
    if (!project.title) {
      newErrors.title = 'Title is required'
    }
    if (!project.slackWorkspaceId) {
      newErrors.slackWorkspaceId = 'Slack Workspace is required'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  useEffect(() => {
    if (project && slackWorkspaces?.length && projects?.length) setLoaded(true)
  }, [project, slackWorkspaces, projects])

  const handleEpicsOptions = useCallback((slackWorkspaceID, allProjects) => {
    const currentProjectAndDifferentWorkspacesRemoved = allProjects.filter((project) => (
      project.id !== projectId &&
      project.slackWorkspaceId === slackWorkspaceID
    ))
    currentProjectAndDifferentWorkspacesRemoved.unshift({ id: '', title: 'None' })
    setEpics(currentProjectAndDifferentWorkspacesRemoved)
  }, [projectId])

  useEffect(() => {
    onboarder.listProjects({}, (error, response) => {
      if (error) return setThrownError(error)
      if (response) {
        const currentProject = response.find((project) => project.id === projectId)
        handleEpicsOptions(currentProject.slackWorkspaceId, response)
        setProjects(response)
        setProject(currentProject)
      }
    })
    onboarder.listSlackWorkspaces((error, response) => {
      if (error) return setThrownError(error)
      setSlackWorkspaces(response)
    })
  }, [projectId, handleEpicsOptions])

  useEffect(() => {
    if (slackWorkspaceId) {
      onboarder.listDevs({ slackWorkspaceId },
        (error, response) => {
          if (error) return setThrownError(error)
          if (response) {
            setDevs(response)
          }
        })
    }
  }, [slackWorkspaceId])

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  const handleUpdate = () => {
    if (!validateForm()) {
      return
    }
    setLoaded(false)
    onboarder.updateProject(project, (error, response) => {
      if (error) {
        setThrownError(error)
        setLoaded(true)
        return
      }
      setProject(response)
      setLoaded(true)
    })
  }

  const handleChange = (e) => {
    let { name, value } = e.target
    if (
      ['currentWeekHours',
        'previousWeekHours',
        'estimatedHours'].includes(name)) value = parseInt(value)
    if (name === 'slackWorkspaceId') {
      handleEpicsOptions(value, projects)
      setProject((prevProject) => ({
        ...prevProject,
        epicId: ''
      }))
    }
    setProject((prevProject) => ({
      ...prevProject,
      [name]: value
    }))
  }

  return (
    <Grid>
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.title}
                helperText={errors.title}
                label='Title'
                name='title'
                value={project.title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Project Link'
                name='link'
                value={project.link}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Planning Document Link'
                name='planningDocLink'
                value={project.planningDocLink}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>Slack Workspace</InputLabel>
                <Select
                  required
                  error={!!errors.slackWorkspaceId}
                  helperText={errors.slackWorkspaceId}
                  value={project.slackWorkspaceId}
                  name='slackWorkspaceId'
                  onChange={handleChange}
                  style={{ minWidth: '200px' }}
                >
                  {slackWorkspaces.map((workspace) => (
                    <MenuItem key={workspace.id} value={workspace.id}>
                      {workspace.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>Assignees</InputLabel>
                <Select
                  multiple
                  value={project.assignees || []}
                  name='assignees'
                  onChange={handleChange}
                  style={{ minWidth: '200px' }}
                >
                  {devs.map((dev) => (
                    <MenuItem key={dev.id} value={dev.id}>
                      {dev.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={7}>
              <TextField
                inputProps={{ type: 'number' }}
                label={`Current ${"Week's"} Hours`}
                name='currentWeekHours'
                value={project.currentWeekHours}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                inputProps={{ type: 'number' }}
                label={`Previous ${"Week's"} Hours`}
                name='previousWeekHours'
                value={project.previousWeekHours}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                inputProps={{ type: 'number' }}
                label='Estimated Hours'
                name='estimatedHours'
                value={project.estimatedHours}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>Epic</InputLabel>
                <Select
                  value={project.epicId}
                  name='epicId'
                  onChange={handleChange}
                  style={{ minWidth: '200px' }}
                >
                  {epics.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Start Date'
                InputLabelProps={{
                  shrink: true
                }}
                name='startDate'
                type='date'
                onChange={handleChange}
                variant='outlined'
                style={{ minWidth: '200px' }}
                defaultValue={project.startDate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Estimated End Date'
                InputLabelProps={{
                  shrink: true
                }}
                name='estimatedEndDate'
                type='date'
                onChange={handleChange}
                variant='outlined'
                style={{ minWidth: '200px' }}
                defaultValue={project.estimatedEndDate}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={project.done}
                    onChange={e =>
                      setProject({ ...project, done: e.target.checked })}
                  />
                }
                label='Done'
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Grid>
  )
}
