import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  responseContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  questionResponse: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '95%'
  },
  answerFlagged: {
    backgroundColor: '#dc655c'
  },
  answerFlaggedNotes: {
    width: '98%',
    margin: '2px 4px'
  }
}))
