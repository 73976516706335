import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'

export default function ManagersEdit () {
  document.title = 'Edit Manager | Superstruct Platform'

  const { id } = useParams()
  const [manager, setManager] = useState(null)
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [slackWorkspaces, setSlackWorkspaces] = useState([])
  const [errors, setErrors] = useState({
    name: '',
    slackWorkspaceId: '',
    slackUserId: ''
  })

  const validateForm = () => {
    const newErrors = {}
    if (!manager.name) {
      newErrors.name = 'Manager name is required'
    }
    if (!manager.slackUserId) {
      newErrors.slackUserId = 'Slack User ID is required'
    }
    if (!manager.slackWorkspaceId) {
      newErrors.slackWorkspaceId = 'Slack Workspace is required'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  useEffect(() => {
    if (manager && slackWorkspaces?.length) setLoaded(true)
  }, [manager, slackWorkspaces])

  useEffect(() => {
    onboarder.listManagers({ id }, (error, response) => {
      if (error) return setThrownError(error)
      if (response) {
        setManager(response[0])
      }
    })
    onboarder.listSlackWorkspaces((error, response) => {
      if (error) return setThrownError(error)
      setSlackWorkspaces(response)
    })
  }, [id])

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  const handleUpdate = () => {
    if (!validateForm()) {
      return
    }
    setLoaded(false)
    onboarder.updateManager(manager, (error, response) => {
      if (error) {
        setThrownError(error)
        setLoaded(true)
        return
      }
      setManager(response)
      setLoaded(true)
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setManager((prevManager) => ({
      ...prevManager,
      [name]: value
    }))
  }

  return (
    <Grid>
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.name}
                helperText={errors.name}
                label='Name'
                name='name'
                value={manager.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={!!errors.slackUserId}
                helperText={errors.slackUserId}
                label='Slack User ID'
                name='slackUserId'
                value={manager.slackUserId}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>Slack Workspace</InputLabel>
                <Select
                  required
                  error={!!errors.slackWorkspaceId}
                  value={manager.slackWorkspaceId}
                  name='slackWorkspaceId'
                  onChange={handleChange}
                  style={{ minWidth: '200px' }}
                >
                  {slackWorkspaces.map((workspace) => (
                    <MenuItem key={workspace.id} value={workspace.id}>
                      {workspace.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Grid>
  )
}
