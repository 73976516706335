import React, { useState } from 'react'
import { Grid, TextField } from '@material-ui/core'

import StateHandler from '../StateHandler'

const MiscKPIs = ({
  prCount,
  prLoaded,
  pullReqErr,
  displayContent
}) => {
  const [formData, setFormData] = useState({
    prsClosed: '',
    fivexxErrors: '',
    meanTimeBetweenFailures: ''
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }
  return (
    <div>
      <h2>Misc KPIs</h2>
      <StateHandler isErr={pullReqErr} isLoaded={prLoaded}>
        {displayContent
          ? (
            <ul>
              <li>PRs Closed: {formData.prsClosed || prCount}</li>
              <li>5xx Errors: {formData.fivexxErrors}</li>
              <li>Mean Time Between Failures: {formData.meanTimeBetweenFailures}</li>
            </ul>
            )
          : (
            < >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label='PRs Closed'
                    type='number'
                    variant='outlined'
                    name='prsClosed'
                    value={formData.prsClosed || prCount}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label='5xx Errors'
                    type='number'
                    variant='outlined'
                    name='fivexxErrors'
                    value={formData.fivexxErrors}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='Mean Time Between Failures'
                    variant='outlined'
                    name='meanTimeBetweenFailures'
                    value={formData.meanTimeBetweenFailures}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item />
              </Grid>
            </>
            )}
      </StateHandler>
    </div>
  )
}

export default MiscKPIs
