import qs from 'query-string'
import React, { useState, useEffect } from 'react'
import { Grid, Button, useTheme, useMediaQuery } from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'
import MaterialTable from 'material-table'
import { tableIcons } from '../Devs/icons'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'
import CustomTableCell from '../../CustomTableCell/CustomTableCell'

const FILTERS = [
  {
    field: 'name',
    type: 'string'
  },
  {
    field: 'slackWorkspace',
    type: 'array'
  },
  {
    field: 'slackUserId',
    type: 'string'
  }
]

export default function ManagersList () {
  document.title = 'Managers | Superstruct Platform'
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const defaultFilters = {}
  FILTERS.forEach((filter) => {
    const urlValue = searchParams.get(filter.field) || filter.default || ''
    defaultFilters[filter.field] =
      filter.type === 'array' ? urlValue.split(',').filter((x) => x) : urlValue
  })

  const [managers, setManagers] = useState([])
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [filters, setFilters] = useState(defaultFilters)
  const maxBodyHeight = useDynamicMaxBodyHeight(230)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const fn = (error, response) => {
    if (response) {
      const filteredManagers = response.map((row) => ({
        ...row,
        slackWorkspace: row.slackWorkspace?.Name
      }))
      setManagers(filteredManagers)
      setLoaded(true)
    } else {
      setLoaded(true)
      setThrownError(error)
    }
  }

  function onFilterEffect () {
    const query = {}

    FILTERS.forEach((filter) => {
      const value = filters[filter.field]
      if (!value || !value.length) return

      query[filter.field] = filter.type === 'array' ? value.join(',') : value
    })

    history.replace(`?${qs.stringify(query)}`)
  }

  function handleFilterChange (changes) {
    const newFilters = {}

    FILTERS.forEach((filter) => {
      const change = changes.find(
        (change) => change.column.field === filter.field
      )
      newFilters[filter.field] = change ? change.value : ''
    })

    setFilters(newFilters)
  }

  useEffect(() => {
    onboarder.listManagers(fn)
  }, [])

  useEffect(onFilterEffect, [filters])

  if (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  return (
    <>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginBottom: '10px' }}
            component={Link}
            to='/onboarder/managers/create'
          >
            Add Managers
          </Button>
          <MaterialTable
            onFilterChange={handleFilterChange}
            style={{ fontSize: isMobile ? '90%' : '75%' }}
            icons={tableIcons}
            title='Managers'
            columns={[
              {
                title: 'Name',
                field: 'name',
                defaultFilter: filters.name
              },
              {
                title: 'Slack Workspace',
                field: 'slackWorkspace',
                defaultFilter: filters.slackWorkspace,
                lookup: managers.reduce((acc, project) => {
                  acc[project.slackWorkspace] = project.slackWorkspace
                  return acc
                }, {})
              },
              {
                title: 'Slack User ID',
                field: 'slackUserId',
                defaultFilter: filters.slackUserId
              }
            ]}
            data={managers}
            options={{
              search: true,
              paging: false,
              filtering: true,
              maxBodyHeight
            }}
            actions={[
              (rowData) => ({
                icon: () => (
                  <Link
                    to={`/onboarder/managers/${rowData.id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <tableIcons.Edit />
                  </Link>
                ),
                tooltip: 'Edit'
              })
            ]}
            components={{
              Cell: (props) =>
                <CustomTableCell props={props} isMobile={isMobile} />
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
