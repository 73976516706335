import React from 'react'

export default function useStartEndDates () {
  const [startDate, setStartDate] = React.useState(() => {
    const now = new Date()
    now.setDate(now.getDate() - 7)
    now.setHours(0)
    now.setMinutes(1)

    return now
  })
  const [endDate, setEndDate] = React.useState(new Date())

  return {
    endDate,
    setEndDate,
    setStartDate,
    startDate
  }
}
