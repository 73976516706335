import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import {
  Grid,
  Typography,
  TextField,
  Paper,
  makeStyles,
  Tabs,
  Tab
} from '@material-ui/core'
import qs from 'query-string'

import { onboarder } from '../../../api'
import { getNDaysAgo } from '../../../utils/dateUtils'
import CheckInAnalysis from './CheckInAnalysis'
import EngagementMetrics from './EngagementMetrics'
import PlanAnalysis from './PlanAnalysis'
import Spinner from '../../Spinner/Spinner'
import { ENGAGEMENT_METRICS_FILTER } from '../../../constants/filter/devInsights'
import { PLANS_FILTER } from '../../../constants/filter/plansFilters'
import { getDevInsightsHeader } from '../../../utils/checkInUtils'
import { handleTableFilterChange } from '../../../utils/commonUtils'

const useStyles = makeStyles(theme => ({
  dateTextField: {
    '& > *': {
      marginLeft: '10px'
    }
  },
  buttons: {
    '& > *': {
      marginTop: '20px'
    }
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '20px'
  }
}))

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  )
}

const Insights = () => {
  document.title = 'Dev Insights | Superstruct Platform'

  const { id } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const defaultFilters = {
    dateStart: searchParams.get('dateStart') || getNDaysAgo(90),
    dateEnd: searchParams.get('dateEnd') || getNDaysAgo(0),
    tabValue: Number(searchParams.get('tabValue')) || 0
  }

  const ALL_FILTERS = [...ENGAGEMENT_METRICS_FILTER, ...PLANS_FILTER]
  ALL_FILTERS.forEach(filter => {
    const urlValue = searchParams.get(filter.field) || filter.default || ''
    defaultFilters[filter.field] =
      filter.type === 'array' ? urlValue.split(',').filter(x => x) : urlValue
  })

  const [checkIns, setCheckIns] = useState([])
  const [plans, setPlans] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [thrownError, setThrownError] = useState(null)
  const [dateStart, setDateStart] = useState(defaultFilters.dateStart)
  const [dateEnd, setDateEnd] = useState(defaultFilters.dateEnd)
  const [tabValue, setTabValue] = useState(defaultFilters.tabValue)
  const [filters, setFilters] = useState(defaultFilters)

  useEffect(onDateEffect, [dateStart, dateEnd])
  useEffect(onFilterEffect, [dateStart, dateEnd, tabValue, filters])

  function onDateEffect () {
    onboarder.listCheckIns({ devId: id, dateStart, dateEnd }, function (err, res) {
      setLoaded(true)

      if (!res || err) return setThrownError(err)
      setCheckIns(res)
    })

    onboarder.listPlans({ devId: id, dateStart, dateEnd }, function (err, res) {
      if (!res || err) return setThrownError(err)
      const updatedPlans = res.map(row => ({
        ...row,
        gptComment: row.gptFlagged ? row.gptComment : '',
        revisionCount: row.revisions?.length ? `${row.revisions.length - 1}` : '0'
      }))
      setPlans(updatedPlans)
    })
  }

  function onFilterEffect () {
    const query = {}
    if (dateStart) query.dateStart = dateStart
    if (dateEnd) query.dateEnd = dateEnd
    if (tabValue) query.tabValue = tabValue

    ALL_FILTERS.forEach(filter => {
      const value = filters[filter.field]
      if (!value || !value.length) return

      query[filter.field] = filter.type === 'array' ? value.join(',') : value
    })

    history.replace(`?${qs.stringify(query)}`)
  }

  function handleDateStartChange (newDateStart) {
    if (newDateStart > dateEnd) setDateEnd(newDateStart)
    setDateStart(newDateStart)
  }

  function handleDateEndChange (newDateEnd) {
    if (newDateEnd < dateStart) setDateStart(newDateEnd)
    setDateEnd(newDateEnd)
  }

  function handleFilterChange (changes, FILTERS) {
    const newFilter = handleTableFilterChange(
      changes, FILTERS
    )
    setFilters({ ...filters, ...newFilter })
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  if (thrownError) return renderError(thrownError)
  if (!loaded) return <Spinner />

  function renderError (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  function renderDateFilter () {
    return (
      <Grid item xs={12}>
        <Typography variant='h4' color='primary' gutterBottom>
          {`${getDevInsightsHeader(checkIns)}`}
        </Typography>
        <Paper className={classes.paper} variant='outlined' elevation={3}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid md={1} item>
                <Typography
                  variant='subtitle1'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Date Range:
                </Typography>
              </Grid>
              <Grid className={classes.dateTextField} item>
                <TextField
                  label='Date Start'
                  id='dateStart'
                  name='dateStart'
                  type='date'
                  value={dateStart}
                  onChange={e => handleDateStartChange(e.target.value)}
                  variant='outlined'
                  size='small'
                />

                <TextField
                  label='Date End'
                  id='dateEnd'
                  name='dateEnd'
                  type='date'
                  value={dateEnd}
                  onChange={e => handleDateEndChange(e.target.value)}
                  variant='outlined'
                  size='small'
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    )
  }

  return (
    <Grid container style={{ minWidth: '500px' }}>
      {renderDateFilter()}
      <Grid container direction='column' spacing={1}>
        <Grid item>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor='primary'
          >
            <Tab label='Check-in Analysis' />
            <Tab label='Engagement Metrics' />
            <Tab label='Plan Completion and Quality' />
          </Tabs>
        </Grid>
        <Grid item>
          <TabPanel value={tabValue} index={0}>
            <CheckInAnalysis checkIns={checkIns} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <EngagementMetrics
              checkIns={checkIns}
              handleFilterChange={(e) => handleFilterChange(e, ENGAGEMENT_METRICS_FILTER)}
              filters={filters}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <PlanAnalysis
              plans={plans}
              handleFilterChange={(e) => handleFilterChange(e, PLANS_FILTER)}
              filters={filters}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Insights
