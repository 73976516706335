import MarkdownIt from 'markdown-it'

const md = new MarkdownIt({ breaks: true })
export function slackToHtml (text) {
  // links formatting
  let markdown = text.replace(/<([^|>]+)\|([^>]+)>/g, '[$2]($1)')

  // user mentions
  markdown = markdown.replace(/<@([A-Z0-9]+)>/g, '@$1')

  // bold formatting
  markdown = markdown.replace(/\*(.*?)\*/g, '**$1**')

  // italic formatting
  markdown = markdown.replace(/_(.*?)_/g, '*$1*')

  // strikethrough formatting
  markdown = markdown.replace(/~(.*?)~/g, '~~$1~~')

  return md.renderInline(markdown)
}
