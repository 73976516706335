
import React, { useCallback, useEffect, useState } from 'react'

import { getIssues } from '../../api'
import StateHandler from '../StateHandler'

const Issues = ({
  dateStart,
  dateEnd,
  companyId,
  issueLoaded,
  setIssueLoaded
}) => {
  const [err, setErr] = useState(null)
  const [records, setRecords] = useState({
    totalOpen: [],
    closedInRange: [],
    openedInRange: []
  })

  const fn = useCallback((error, response) => {
    if (error) return setErr(error.message || error.toString())

    const { data } = response
    setErr(null)
    setRecords(data)

    setIssueLoaded(true)
  }, []) // eslint-disable-line

  const fetchIssues = useCallback((dateStart, dateEnd) => {
    setIssueLoaded(false)
    setErr(null)

    getIssues({
      companyId,
      repo: 'calculus-static',
      label: 'student-reported',
      dateStart,
      dateEnd
    }, fn)
  }, [companyId, fn]) // eslint-disable-line

  useEffect(() => {
    fetchIssues(dateStart, dateEnd)
  }, [companyId, dateStart, dateEnd, fetchIssues])

  return (
    <div>
      <h2>Student Reported Issues</h2>
      <StateHandler isErr={err} isLoaded={issueLoaded}>
        <div>

          <section>
            <h3> Total Open: {records.totalOpen.length} </h3>
            <ul>
              {records?.totalOpen?.map(record => (
                <li key={record.title} style={{ paddingBottom: '10px' }}>
                  <a href={record.url}>{record.title || ''}</a>
                </li>
              ))}
            </ul>
          </section>

          <section>
            <h3> Opened: {records.openedInRange.length} </h3>
            <ul>
              {records.openedInRange.map(record => (
                <li key={record.title} style={{ paddingBottom: '10px' }}>
                  <a href={record.url}>{record.title || ''}</a>
                </li>
              ))}
            </ul>
          </section>

          <section>
            <h3> Closed: {records.closedInRange.length} </h3>
            <ul>
              {records.closedInRange.map(record => (
                <li key={record.title} style={{ paddingBottom: '10px' }}>
                  <a href={record.url}>{record.title || ''}</a>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </StateHandler>
    </div>
  )
}

export default Issues
