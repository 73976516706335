const _ = require('lodash')

export function getNameFromEmail (email) {
  if (!email) return ''
  const userPart = _.split(email, '@')[0]
  const nameParts = _.split(userPart, '.')

  const firstName = _.capitalize(nameParts[0])
  const lastName = _.capitalize(nameParts[1])

  return `${firstName} ${lastName}`.trim()
}

export function handleTableFilterChange (changes, filters) {
  const newFilters = {}

  filters.forEach(filter => {
    const change = changes.find(
      change => change.column.field === filter.field
    )
    newFilters[filter.field] = change ? change.value : ''
  })

  return newFilters
}
