const _ = { compact: require('lodash/compact') }
const React = require('react')
const createReactClass = require('create-react-class')
const partial = require('./partial')

module.exports = createReactClass({
  getDefaultProps () {
    return {
      dimensions: [],
      selectedDimensions: [],
      onChange () {},
      subDimensionText: 'Sub Dimension...',
      downloadCSV: {},
      rows: []
    }
  },

  render () {
    const self = this
    const {
      subDimensionText,
      selectedDimensions,
      dimensions,
      downloadCSV,
      rows
    } = this.props
    const nSelected = selectedDimensions.length

    return (
      <div className='reactPivot-dimensions'>
        {selectedDimensions.map(this.renderDimension)}

        <select value='' onChange={partial(self.toggleDimension, nSelected)}>
          <option value=''>{subDimensionText}</option>
          {dimensions.map(dimension => (
            <option key={dimension.title}>{dimension.title}</option>
          ))}
        </select>
        <div className='reactPivot-csvExport'>
          <button onClick={partial(downloadCSV, rows)}>
            Export CSV
          </button>
        </div>
      </div>
    )
  },

  renderDimension (selectedDimension, i) {
    return (
      <select
        value={selectedDimension}
        onChange={partial(this.toggleDimension, i)}
        key={selectedDimension}
      >
        <option />
        {this.props.dimensions.map(dimension => (
          <option value={dimension.title} key={dimension.title}>
            {dimension.title}
          </option>
        ))}
      </select>
    )
  },

  toggleDimension (iDimension, evt) {
    const dimension = evt.target.value
    const dimensions = this.props.selectedDimensions

    const curIdx = dimensions.indexOf(dimension)
    if (curIdx >= 0) dimensions[curIdx] = null
    dimensions[iDimension] = dimension

    const updatedDimensions = _.compact(dimensions)

    this.props.onChange(updatedDimensions)
  }
})
