import URLON from 'urlon'
import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import {
  Typography,
  Paper,
  makeStyles,
  TextField,
  Grid,
  Box
} from '@material-ui/core'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import { getNDaysAgo } from '../../../utils/dateUtils'
import WildEmitter from 'wildemitter'
import accounting from 'accounting'
import _ from 'lodash'

const Pivot = require('../../Pivot/index.jsx')

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '20px'
  },
  dateInputs: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontSize: '1rem'
  },
  pivotContainer: {
    '& table': {
      fontSize: '0.8rem'
    },
    '& th': {
      fontSize: '0.85rem',
      fontWeight: 'bold'
    }
  }
}))

function SlackSequencesReport () {
  const classes = useStyles()
  const { id } = useParams()
  const location = useLocation()
  const history = useHistory()
  const pivotState = useMemo(() => {
    return location.hash.substring(1)
      ? URLON.parse(location.hash.substring(1))
      : {}
  }, [location.hash])

  const [dateStart, setDateStart] = useState(
    pivotState.dateStart || getNDaysAgo(7)
  )
  const [dateEnd, setDateEnd] = useState(pivotState.dateEnd || getNDaysAgo(0))
  const [report, setReport] = useState(null)
  const [sequenceDefinition, setSequenceDefinition] = useState(null)
  const [devs, setDevs] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (id) {
      fetchSequenceDefinition()
    }
  }, [id])

  useEffect(() => {
    const newPivotState = {
      ...pivotState,
      dateStart:
        dateStart instanceof Date
          ? dateStart.toISOString().split('T')[0]
          : dateStart,
      dateEnd:
        dateEnd instanceof Date ? dateEnd.toISOString().split('T')[0] : dateEnd
    }
    history.replace(`${location.pathname}#${URLON.stringify(newPivotState)}`)
  }, [dateStart, dateEnd, pivotState, history, location.pathname])

  useEffect(() => {
    if (sequenceDefinition) {
      fetchReport()
    }
  }, [sequenceDefinition, dateStart, dateEnd])

  const fetchSequenceDefinition = () => {
    onboarder.listSlackSequences({ id }, (err, data) => {
      if (err) {
        console.error('Error fetching slack sequence definition:', err)
        setError('Failed to load slack sequence definition. Please try again.')
      } else {
        setSequenceDefinition(data[0]) // Assuming the API returns an array
        fetchDevs(data[0].devIds)
      }
    })
  }

  const fetchDevs = devIds => {
    onboarder.listDevs({ ids: devIds.join(',') }, (err, data) => {
      if (err) {
        console.error('Error fetching devs:', err)
      } else {
        const devsMap = data.reduce((acc, dev) => {
          acc[dev.id] = dev
          return acc
        }, {})
        setDevs(devsMap)
      }
    })
  }

  const fetchReport = () => {
    setLoading(true)
    const formattedDateStart =
      dateStart instanceof Date
        ? dateStart.toISOString().split('T')[0]
        : dateStart
    const formattedDateEnd =
      dateEnd instanceof Date ? dateEnd.toISOString().split('T')[0] : dateEnd

    onboarder.getSlackSequenceReport(
      id,
      { dateStart: formattedDateStart, dateEnd: formattedDateEnd },
      (err, data) => {
        setLoading(false)
        if (err) {
          console.error('Error fetching slack sequence report:', err)
          setError('Failed to load slack sequence report. Please try again.')
        } else {
          // console.log('Received report data:', data)
          setReport(data)
        }
      }
    )
  }

  function handleDateStartChange (newDateStart) {
    const date = new Date(newDateStart)
    if (date > new Date(dateEnd)) setDateEnd(date.toISOString().split('T')[0])
    setDateStart(date.toISOString().split('T')[0])
  }

  function handleDateEndChange (newDateEnd) {
    const date = new Date(newDateEnd)
    if (date < new Date(dateStart)) {
      setDateStart(date.toISOString().split('T')[0])
    }
    setDateEnd(date.toISOString().split('T')[0])
  }

  // Function to extract month from date
  const getMonth = dateString => {
    const date = new Date(dateString)
    return String(date.getMonth() + 1).padStart(2, '0')
  }

  const getWeek = dateString => {
    const date = new Date(dateString)
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
    const pastDaysOfYear =
      (date.getTime() - firstDayOfYear.getTime()) / 86400000
    return String(
      Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
    ).padStart(2, '0')
  }

  // Process the report data
  const rows = useMemo(() => {
    if (!report) return []
    return report.map(instance => {
      const dev = devs[instance.devId] || {}
      const context = instance.context

      const data = {
        devId: instance.devId,
        devName: dev.name || 'Unknown',
        devEmail: dev.email || 'Unknown',
        isArchived: dev.archived || false,
        slackWorkspace: dev.slackWorkspace?.Name || 'Unknown',
        month: getMonth(instance.createdAt),
        week: getWeek(instance.createdAt),
        date: instance.createdAt.split('T')[0],
        timedOut: instance.isTimeout
      }

      const mapping = {
        prevPlan: 'prev.planRequest.response',
        prevPlanAccepted: 'prev.planRequest.evaluation.answer',
        prevPlanAcceptedReason: 'prev.planRequest.evaluation.reason',
        prevPlanAchieved: 'completePreviousPlan.evaluation.answer',
        prevPlanIncompleteReason: 'incompletePlanReason.response',
        curPlan: 'planRequest.response',
        curPlanAccepted: 'planRequest.evaluation.answer',
        curPlanAcceptedReason: 'planRequest.evaluation.reason',
        curPlanAgreed: 'badPlan.evaluation.answer',
        curPlanDisagreedReason: 'disagreement.response'
      }

      Object.keys(mapping).forEach(key => {
        data[key] = _.get(context, mapping[key], null)
      })

      return data
    })
  }, [report, devs])

  const dimensions = useMemo(
    () => [
      { title: 'Dev Name', value: 'devName' },
      { title: 'Dev Email', value: 'devEmail' },
      { title: 'Dev Archived', value: 'isArchived' },
      { title: 'Slack Workspace', value: 'slackWorkspace' },
      { title: 'Month', value: 'month' },
      { title: 'Week', value: 'week' },
      { title: 'Date', value: 'date' },
      { title: 'Timed Out', value: 'timedOut' },
      { title: 'Prev Plan', value: 'prevPlan' },
      { title: 'Prev Plan Accepted', value: 'prevPlanAccepted' },
      { title: 'Prev Plan Achieved', value: 'prevPlanAchieved' },
      {
        title: 'Prev Plan Incomplete Reason',
        value: 'prevPlanIncompleteReason'
      },
      { title: 'Cur Plan', value: 'curPlan' },
      { title: 'Cur Plan AI Accepted', value: 'curPlanAccepted' },
      { title: 'Cur Plan AI Reason', value: 'curPlanAcceptedReason' },
      { title: 'Cur Plan Disagreed', value: 'curPlanDisagreed' },
      { title: 'Cur Plan Disagreed Reason', value: 'curPlanDisagreedReason' }
    ],
    []
  )

  const reduce = useMemo(
    () =>
      function (row, memo) {
        memo.totalInstances = (memo.totalInstances || 0) + 1

        const counts = {
          timedOutInstances: 'timedOut',
          prevPlanAchieved: 'prevPlanAchieved',
          planAccepted: 'planAccepted',
          devDisagreed: 'devDisagreed'
        }

        Object.entries(counts).forEach(([key, value]) => {
          memo[key] = (memo[key] || 0) + (row[value] ? 1 : 0)
        })

        const ratios = {
          timedOutPercentage: ['timedOutInstances', 'totalInstances'],
          prevPlanAchievedPercentage: ['prevPlanAchieved', 'totalInstances'],
          planAcceptedPercentage: ['planAccepted', 'totalInstances'],
          devDisagreedPercentage: ['devDisagreed', 'totalInstances']
        }

        Object.entries(ratios).forEach(([key, values]) => {
          const [numerator, denominator] = values
          memo[key] = ((memo[numerator] || 0) / (memo[denominator] || 1)) * 100
        })

        return memo
      },
    []
  )

  const calculations = useMemo(
    () => [
      {
        title: 'Prev Achieved',
        value: 'prevPlanAchieved',
        template: v => accounting.formatNumber(v)
      },
      {
        title: 'Prev Achieved %',
        value: 'prevPlanAchievedPercentage',
        template: v => accounting.formatNumber(v, 1) + '%'
      },
      {
        title: 'Accepted %',
        value: 'planAcceptedPercentage',
        template: v => accounting.formatNumber(v, 1) + '%'
      },
      {
        title: 'Dev Disagreements %',
        value: 'devDisagreedPercentage',
        template: v => accounting.formatNumber(v, 1) + '%'
      },
      {
        title: 'Timed Out %',
        value: 'timedOutPercentage',
        template: v => accounting.formatNumber(v, 1) + '%'
      },
      {
        title: 'Total Instances',
        value: 'totalInstances',
        template: accounting.formatNumber
      }
    ],
    []
  )

  const eventBus = useMemo(() => {
    const bus = new WildEmitter()
    bus.on('*', (event, value) => {
      pivotState[event] = value
      history.replace(`#${URLON.stringify(pivotState)}`)
    })
    return bus
  }, [history, pivotState])

  if (!id) {
    return (
      <div className={classes.root}>
        <Typography variant='h4' color='error'>
          Error: No Sequence ID provided
        </Typography>
      </div>
    )
  }

  if (loading || !sequenceDefinition) {
    return <Spinner />
  }

  if (error) {
    return (
      <div className={classes.root}>
        <Typography variant='h4' color='error'>
          {error}
        </Typography>
      </div>
    )
  }

  const renderPivot = () => {
    if (!rows.length) {
      return (
        <Typography variant='body1' className={classes.subtitle}>
          No data available for the selected date range.
        </Typography>
      )
    }
    return (
      <div className={classes.pivotContainer}>
        <Pivot
          rows={rows}
          dimensions={dimensions}
          reduce={reduce}
          calculations={calculations}
          eventBus={eventBus}
          activeDimensions={
            pivotState.activeDimensions || [
              'Date',
              'Slack Workspace',
              'Dev Name'
            ]
          }
          sortBy={pivotState.sortBy}
          sortDir={pivotState.sortDir}
          solo={pivotState.solo}
          // compact
          nPaginateRows={Infinity}
        />
      </div>
    )
  }

  return (
    <Box className={classes.root}>
      <Typography variant='h4' gutterBottom className={classes.title}>
        Slack Sequence Report: {sequenceDefinition?.name}
      </Typography>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} lg={2}>
            <Typography
              variant='subtitle1'
              className={classes.subtitle}
              style={{ whiteSpace: 'nowrap' }}
            >
              Date Range:
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              label='Start Date'
              type='date'
              value={dateStart}
              onChange={e => handleDateStartChange(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              label='End Date'
              type='date'
              value={dateEnd}
              onChange={e => handleDateEndChange(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>
      {renderPivot()}
    </Box>
  )
}

export default SlackSequencesReport
