import qs from 'query-string'
import React, { useState, useEffect } from 'react'
import { onboarder } from '../../../api'
import {
  Grid,
  Typography,
  TextField,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Spinner from '../../Spinner/Spinner'
import MaterialTable from 'material-table'
import { tableIcons } from '../Devs/icons'
import { mdToHtml } from '../../../utils/answerHandlers'
import { getNDaysAgo } from '../../../utils/dateUtils'
import { useLocation, useHistory } from 'react-router-dom'
import ReviewPlan from './Review'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'
import CustomTableCell from '../../CustomTableCell/CustomTableCell'

const FILTERS = [
  {
    field: 'date',
    type: 'string'
  },
  {
    field: 'email',
    type: 'string'
  },
  {
    field: 'plan',
    type: 'string'
  },
  {
    field: 'gptFlagged',
    type: 'array'
  },
  {
    field: 'gptComment',
    type: 'string'
  },
  {
    field: 'wronglyFlaggedReason',
    type: 'string'
  },
  {
    field: 'planAchieved',
    type: 'array'
  },
  {
    field: 'planNotAchievedReason',
    type: 'string'
  },
  {
    field: 'slackWorkspace',
    type: 'array'
  },
  {
    field: 'revisionCount',
    type: 'string'
  }
]

const useStyles = makeStyles(theme => ({
  buttons: {
    '& > *': {
      marginTop: '20px'
    }
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '20px'
  }
}))

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  )
}

const PlansList = () => {
  document.title = 'Plans | Superstruct Platform'

  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const defaultFilters = {
    dateStart: searchParams.get('dateStart') || getNDaysAgo(3),
    dateEnd: searchParams.get('dateEnd') || getNDaysAgo(0)
  }

  FILTERS.forEach(filter => {
    const urlValue = searchParams.get(filter.field) || filter.default || ''
    defaultFilters[filter.field] =
      filter.type === 'array' ? urlValue.split(',').filter(x => x) : urlValue
  })

  const [plans, setPlans] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [thrownError, setThrownError] = useState(null)
  const [dateStart, setDateStart] = useState(defaultFilters.dateStart)
  const [dateEnd, setDateEnd] = useState(defaultFilters.dateEnd)
  const [filters, setFilters] = useState(defaultFilters)
  const [tabValue, setTabValue] = useState(0)
  const maxBodyHeight = useDynamicMaxBodyHeight(330)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(onDateEffect, [dateStart, dateEnd])
  useEffect(onFilterEffect, [dateStart, dateEnd, filters])

  function onDateEffect () {
    onboarder.listPlans({ dateStart, dateEnd }, function (err, res) {
      setLoaded(true)

      if (!res) return setThrownError(err)
      // hide AI comment for AI accepted plans
      const filteredPlans = res.map(row => ({
        ...row,
        gptComment: row.gptFlagged ? row.gptComment : '',
        revisionCount: row.revisions?.length ? row.revisions.length - 1 : 0
      }))
      setPlans(filteredPlans)
    })
  }

  function onFilterEffect () {
    const query = {}
    if (dateStart) query.dateStart = dateStart
    if (dateEnd) query.dateEnd = dateEnd

    FILTERS.forEach(filter => {
      const value = filters[filter.field]
      console.log(filter.field, value)
      if (!value || !value.length) return

      query[filter.field] = filter.type === 'array' ? value.join(',') : value
    })

    history.replace(`?${qs.stringify(query)}`)
  }

  function handleFilterChange (changes) {
    const newFilters = {}

    FILTERS.forEach(filter => {
      const change = changes.find(
        change => change.column.field === filter.field
      )
      newFilters[filter.field] = change ? change.value : ''
    })

    setFilters(newFilters)
  }

  function handleDateStartChange (newDateStart) {
    if (newDateStart > dateEnd) setDateEnd(newDateStart)
    setDateStart(newDateStart)
  }

  function handleDateEndChange (newDateEnd) {
    if (newDateEnd < dateStart) setDateStart(newDateEnd)
    setDateEnd(newDateEnd)
  }

  if (thrownError) return renderError(thrownError)
  if (!loaded) return <Spinner />
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5
      }}
    >
      <Box>
        <Tabs value={tabValue} onChange={handleChange} indicatorColor='primary'>
          <Tab label='Daily plans' />
          <Tab label='Submit Plan for AI review' />
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={tabValue} index={0}>
          <Grid container>
            {renderDateFilter()}
            {renderTable()}
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ReviewPlan />
        </TabPanel>
      </Box>
    </Box>
  )

  function renderError (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  function renderDateFilter () {
    return (
      <Grid item xs={12}>
        <Paper className={classes.paper} variant='outlined' elevation={3}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2}>
                <Typography
                  variant='subtitle1'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Date Range:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  label='Date Start'
                  id='dateStart'
                  name='dateStart'
                  type='date'
                  value={dateStart}
                  onChange={e => handleDateStartChange(e.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField
                  label='Date End'
                  id='dateEnd'
                  name='dateEnd'
                  type='date'
                  value={dateEnd}
                  onChange={e => handleDateEndChange(e.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    )
  }

  function renderTable () {
    return (
      <Grid item xs={12}>
        <MaterialTable
          onFilterChange={handleFilterChange}
          style={{ fontSize: isMobile ? '90%' : '75%' }}
          icons={tableIcons}
          title='Plans'
          columns={[
            {
              title: 'Date',
              field: 'date',
              defaultSort: 'desc',
              defaultFilter: filters.date
            },
            {
              title: 'Email',
              field: 'email',
              defaultFilter: filters.email,
              render: rowData => (
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`/onboarder/devs/${rowData.email}`}
                >
                  {rowData.email}
                </a>
              )
            },
            {
              title: 'Slack Workspace',
              field: 'slackWorkspace',
              defaultFilter: filters.slackWorkspace,
              lookup: plans.reduce((acc, plan) => {
                acc[plan.slackWorkspace] = plan.slackWorkspace
                return acc
              }, {})
            },
            {
              title: 'AI Accepted',
              field: 'gptFlagged',
              defaultFilter: filters.gptFlagged,
              lookup: { true: '🚫', false: '✅' }
            },
            {
              title: 'AI comment',
              field: 'gptComment',
              defaultFilter: filters.gptComment
            },
            {
              title: 'Incorrectly flagged reason',
              field: 'wronglyFlaggedReason',
              defaultFilter: filters.wronglyFlaggedReason
            },
            {
              title: 'Plan Achieved',
              field: 'planAchieved',
              defaultFilter: filters.planAchieved,
              lookup: { true: '✅', false: '🚫', Unavailable: '⏳' }
            },
            {
              title: 'Reason',
              field: 'planNotAchievedReason',
              defaultFilter: filters.planNotAchievedReason
            },
            {
              title: 'Plan',
              field: 'plan',
              defaultFilter: filters.plan,
              render: rowData => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `- ${mdToHtml(rowData.plan)}`
                  }}
                />
              )
            },
            {
              title: 'Revisions',
              field: 'revisionCount',
              defaultFilter: filters.revisionCount
            },
            {
              title: 'Slack Link',
              field: 'slackLink',
              filtering: false,
              render: (rowData) => rowData.slackLink &&
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={rowData.slackLink}
                >
                  <OpenInNewIcon size='large' />
                </a>
            }
          ]}
          data={plans}
          options={{
            paging: false,
            filtering: true,
            maxBodyHeight
          }}
          components={{
            Cell: (props) =>
              <CustomTableCell props={props} isMobile={isMobile} />
          }}
        />
      </Grid>
    )
  }
}

export default PlansList
