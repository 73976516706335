import React from 'react'
import { makeStyles, TextareaAutosize } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  textarea: {
    width: '80%',
    maxWidth: 650,
    minHeight: 40
  }
}))
const Projects = ({ projects, setProjects, displayContent }) => {
  const classes = useStyles()
  return (
    <>
      <h2>Projects</h2>
      <TextareaAutosize
        className={classes.textarea}
        name='projects'
        minRows={3}
        placeholder=''
        value={displayContent ? '' : projects}
        onChange={(e) => setProjects(e.target.value)}
      />
    </>
  )
}

export default Projects
