import React, { useState } from 'react'
import { Grid, TextField } from '@material-ui/core'

const Sem = ({ displayContent }) => {
  const [formData, setFormData] = useState({
    avgPreview: '',
    stDevPreview: '',
    avgCalculus: '',
    stDevCalculus: ''
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  return (
    <>
      <h2>SEM</h2>
      <p>Preview</p>
      {displayContent
        ? (
          <ul>
            <li>Avg: {formData.avgPreview}</li>
            <li>StDev: {formData.stDevPreview}</li>
          </ul>
          )
        : (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label='Avg'
                type='number'
                variant='outlined'
                name='avgPreview'
                value={formData.avgPreview}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label='StDev'
                type='number'
                variant='outlined'
                name='stDevPreview'
                value={formData.stDevPreview}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          )}
      <p>Calculus</p>
      {displayContent
        ? (
          <ul>
            <li>Avg: {formData.avgCalculus}</li>
            <li>StDev: {formData.stDevCalculus}</li>
          </ul>
          )
        : (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label='Avg'
                type='number'
                variant='outlined'
                name='avgCalculus'
                value={formData.avgCalculus}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label='StDev'
                type='number'
                variant='outlined'
                name='stDevCalculus'
                value={formData.stDevCalculus}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          )}
    </>
  )
}

export default Sem
