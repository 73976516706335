import React, { useState } from 'react'
import {
  Button,
  Grid,
  TextField
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'

export default function SlackWorkspacesCreate () {
  document.title = 'Create Slack Workspace | Superstruct Platform'

  const history = useHistory()
  const [slackWorkspace, setSlackWorkspace] = useState({
    Name: '',
    slackWebhookUrl: '',
    teamId: ''
  })
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(true)

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded) {
    return <Spinner />
  }
  const handleCreate = () => {
    setLoaded(false)
    onboarder.createSlackWorkspace(slackWorkspace, (error, response) => {
      if (error) {
        setThrownError(error)
        setLoaded(true)
        return
      }
      setLoaded(true)
      history.push(`/onboarder/slack-workspaces/${response.id}`)
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setSlackWorkspace((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <Grid>
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name='Name'
                label='Name'
                value={slackWorkspace.Name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='slackWebhookUrl'
                label='Slack Webhook URL'
                value={slackWorkspace.slackWebhookUrl}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='teamId'
                label='Team ID'
                value={slackWorkspace.teamId}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='zenhubWorkspaceId'
                label='Zenhub Workspace ID'
                value={slackWorkspace.zenhubWorkspaceId}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='zenhubToken'
                label='Zenhub Token'
                value={slackWorkspace.zenhubToken}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='zenhubAlertSlackChannel'
                label='Slack Channel for Zenhub Notifications'
                value={slackWorkspace.zenhubAlertSlackChannel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleCreate}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Grid>
  )
}
