import React, { useState, useEffect } from 'react'
import { Grid, Button, useMediaQuery, useTheme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'

import { tableIcons } from '../Devs/icons'
import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import useDynamicMaxBodyHeight from '../../../hooks/useDynamicMaxBodyHeight'
import CustomTableCell from '../../CustomTableCell/CustomTableCell'

export default function SlackWorkspacesList () {
  document.title = 'Slack Workspaces | Superstruct Platform'

  const [slackWorkspaces, setSlackWorkspaces] = useState([])
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const maxBodyHeight = useDynamicMaxBodyHeight(230)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const fn = (error, response) => {
    if (response) {
      setLoaded(true)
      setSlackWorkspaces(response)
    } else {
      setLoaded(true)
      setThrownError(error)
    }
  }

  useEffect(() => {
    onboarder.listSlackWorkspaces(fn)
  }, []) //eslint-disable-line

  if (thrownError) {
    return (
      <>
        <Grid>
          <Grid>{`ERROR: ${thrownError}`}</Grid>
        </Grid>
      </>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  return (
    <>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginBottom: '10px' }}
            component={Link}
            to='/onboarder/slack-workspaces/create'
          >
            Add Slack Workspaces
          </Button>
          <MaterialTable
            style={{ fontSize: isMobile ? '90%' : '75%' }}
            icons={tableIcons}
            title='Slack Workspaces'
            columns={[
              {
                title: 'Name',
                field: 'Name'
              },
              { title: 'slackWebhookUrl', field: 'slackWebhookUrl' },
              {
                title: 'Action',
                render: (rowData) =>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`slack-workspaces/view/${rowData._id}`}
                  >
                    Developer Allocation View
                  </a>
              }
            ]}
            data={slackWorkspaces}
            options={{
              search: true,
              paging: false,
              filtering: true,
              maxBodyHeight
            }}
            actions={[
              rowData => ({
                icon: () => (
                  <Link
                    to={`/onboarder/slack-workspaces/${rowData.id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <tableIcons.Edit />
                  </Link>
                ),
                tooltip: 'Edit'
              })
            ]}
            components={{
              Cell: (props) =>
                <CustomTableCell props={props} isMobile={isMobile} />
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
