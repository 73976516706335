import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ViewListIcon from '@material-ui/icons/ViewList'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CodeIcon from '@material-ui/icons/Code'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import EventIcon from '@material-ui/icons/Event'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay' // New import for Sequences icon
import Divider from '@material-ui/core/Divider'
import ListSubheader from '@material-ui/core/ListSubheader'
import { CompanyContext } from '../CompanyProvider/CompanyProvider'

export const SidebarList = props => {
  const { selectedCompany } = useContext(CompanyContext)
  const [active, setActive] = useState(0)
  return (
    <>
      <List
        component='nav'
      >
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/companies'
        >
          <ListItem button selected={active === 1} onClick={() => setActive(1)}>
            <ListItemIcon>
              <ViewListIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Companies' />
          </ListItem>
        </Link>
        <Divider />
        <Link
          key={selectedCompany.id}
          style={{ textDecoration: 'none', color: 'inherit' }}
          to={`/company/${selectedCompany.id}`}
        >
          <ListItem button selected={active === 2} onClick={() => setActive(2)}>
            <ListItemIcon>
              <AssessmentIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Reports' />
          </ListItem>
        </Link>
      </List>

      <List
        component='nav'
        subheader={
          <ListSubheader component='div'>
            Onboarder
          </ListSubheader>
        }
      >
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/devs'
        >
          <ListItem button selected={active === 3} onClick={() => setActive(3)}>
            <ListItemIcon>
              <PeopleIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Devs' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/articles'
        >
          <ListItem button selected={active === 4} onClick={() => setActive(4)}>
            <ListItemIcon>
              <AssignmentIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Articles' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/scripts'
        >
          <ListItem button selected={active === 5} onClick={() => setActive(5)}>
            <ListItemIcon>
              <CodeIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Scripts' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/slack-workspaces'
        >
          <ListItem button selected={active === 6} onClick={() => setActive(6)}>
            <ListItemIcon>
              <AlternateEmailIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Slack Workspaces' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/plans'
        >
          <ListItem button selected={active === 7} onClick={() => setActive(7)}>
            <ListItemIcon>
              <EventIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Plans' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/check-ins'
        >
          <ListItem button selected={active === 8} onClick={() => setActive(8)}>
            <ListItemIcon>
              <AccessTimeIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Check-Ins' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/projects'
        >
          <ListItem button selected={active === 9} onClick={() => setActive(9)}>
            <ListItemIcon>
              <CardTravelIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Projects' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/managers'
        >
          <ListItem
            button
            selected={active === 10}
            onClick={() => setActive(10)}
          >
            <ListItemIcon>
              <PersonOutlineIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Managers' />
          </ListItem>
        </Link>

        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/onboarder/slack-sequences'
        >
          <ListItem
            button
            selected={active === 11}
            onClick={() => setActive(11)}
          >
            <ListItemIcon>
              <PlaylistPlayIcon fontSize='large' />
            </ListItemIcon>
            <ListItemText secondary='Sequences' />
          </ListItem>
        </Link>

        <Divider />
      </List>
    </>
  )
}
