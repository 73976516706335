import React from 'react'
import { Select, Box, MenuItem, IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'

const ProjectInput = (
  {
    rowData,
    updateDevProject,
    handleRemove,
    handleEdit,
    projects,
    index
  }) => {
  const projectQueueIds = rowData.projectQueues.map(proj => {
    return proj ? proj.id : null
  })
  const selectedProjectId = rowData.projectQueues[index]?.id
  const filteredProjects = projectQueueIds.filter(id => {
    return id !== selectedProjectId
  })

  return (
    <Box style={{ display: 'flex' }}>
      <Select
        value={selectedProjectId || ''}
        name={index.toString()}
        onChange={(e) => updateDevProject(e, rowData)}
        style={{ minWidth: '100px' }}
      >
        {projects.map((project) => {
          if (filteredProjects.includes(project.id)) {
            return null
          }
          return (
            <MenuItem key={project.id} value={project.id}>
              {project.title}
            </MenuItem>
          )
        })}
      </Select>
      {selectedProjectId &&
        <>
          <IconButton
            aria-label='delete'
            onClick={() => handleEdit(index, rowData)}
            style={{ padding: '3px' }}
          >
            <EditIcon fontSize='small' />
          </IconButton>
          <IconButton
            aria-label='delete'
            onClick={() => handleRemove(index, rowData)}
            style={{ padding: '3px' }}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        </>}
    </Box>
  )
}

export default ProjectInput
