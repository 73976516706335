import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  Input,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { onboarder } from '../../../api'
import Spinner from '../../Spinner/Spinner'
import DraggableTable from '../../DraggableTable/DraggableTable'

export default function ScriptsCreate () {
  document.title = 'Create Script | Superstruct Platform'

  const history = useHistory()
  const [script, setScript] = useState({
    name: '',
    articles: []
  })
  const [allArticles, setAllArticles] = useState([])
  const [articles, setArticles] = useState([])
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (allArticles) setLoaded(true)
  }, [allArticles])

  useEffect(() => {
    onboarder.listArticles((error, response) => {
      if (error) return setThrownError(error)
      setAllArticles(response.sort((a, b) => a.title.localeCompare(b.title)))
    })
  }, [])

  if (thrownError) {
    return (
      <Grid>
        <Grid>{`ERROR: ${thrownError}`}</Grid>
      </Grid>
    )
  }

  if (!loaded) {
    return <Spinner />
  }

  const handleCreate = () => {
    setLoaded(false)
    const newScript = {
      ...script,
      articles: articles.map((article) => article.id)
    }
    onboarder.createScript(newScript, (error, response) => {
      if (error) {
        setThrownError(error)
        setLoaded(true)
        return
      }
      setLoaded(true)
      history.push(`/onboarder/scripts/${response.id}`)
    })
  }
  const handleChange = (event) => {
    const articleIds = event.target.value
    const articles = articleIds.map((id) =>
      allArticles.find((article) => article.id === id)
    )
    setArticles(articles)
  }

  const columns = [
    { name: 'title', label: 'Title' },
    { name: 'url', label: 'URL' },
    { name: 'body', label: 'Body' }
  ]

  return (
    <Grid>
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label='Name'
                value={script.name}
                onChange={(e) => setScript({ ...script, name: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id='script-articles'>Add articles</InputLabel>
                <Select
                  labelId='script-articles'
                  multiple
                  value={articles.map((a) => a.id)}
                  onChange={handleChange}
                  input={<Input />}
                  fullWidth
                >
                  {allArticles.map((article, index) => (
                    <MenuItem key={index} value={article.id}>
                      {article.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {articles.length
                ? (
                  <DraggableTable
                    columns={columns}
                    rows={articles}
                    setRows={setArticles}
                    actions={['order', 'delete']}
                  />
                  )
                : null}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleCreate}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Grid>
  )
}
