export const ENGAGEMENT_METRICS_FILTER = [
  {
    field: 'date',
    type: 'string'
  },
  {
    field: 'totalWordCount',
    type: 'string'
  },
  {
    field: 'averageWordCount',
    type: 'string'
  },
  {
    field: 'minResponseTime',
    type: 'string'
  },
  {
    field: 'maxResponseTime',
    type: 'string'
  },
  {
    field: 'medianResponseTime',
    type: 'string'
  },
  {
    field: 'averageResponseTime',
    type: 'string'
  }
]
