import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import MaterialTable from 'material-table'

import { tableIcons } from '../Devs/icons'
import { getAverageCoverage, countChecklistOccurence } from '../../../utils/checkInUtils'

const CheckInAnalysis = ({ checkIns }) => {
  const averageCoverage = getAverageCoverage(checkIns)
  const checkInOccurence = countChecklistOccurence(checkIns)

  return (
    <Grid container style={{ padding: '30px' }}>
      <Grid item xs={12}>
        <Paper
          variant='outlined'
          elevation={3}
          style={{ padding: '10px', marginBottom: '20px' }}
        >
          <Typography variant='body2' gutterBottom>
            {`Total number of Check-Ins: ${checkIns?.length}`}
          </Typography>
          <Typography variant='body2' gutterBottom>
            {`Average Checklist Coverage: ${averageCoverage}%`}
          </Typography>
        </Paper>
        <MaterialTable
          style={{ fontSize: '75%' }}
          icons={tableIcons}
          title='Checklist Items Coverage'
          columns={[
            { title: 'Checklist Name', field: 'name' },
            { title: 'Coverage (times)', field: 'occurrence' }
          ]}
          data={checkInOccurence}
          options={{
            search: false,
            paging: false,
            filtering: false
          }}
        />
      </Grid>
    </Grid>
  )
}

export default CheckInAnalysis
