import MarkdownIt from 'markdown-it'

const md = new MarkdownIt()

export function mdToHtml (responseAnswer) {
  const replaced = responseAnswer
    .replace(/<.*?http.*?>/g, match => parseLink(match))
    .split(/\n/)
    .map(line => line.trim().replace(/^•|^-/, ''))
    .join(`\\
  - `)

  return md.renderInline(replaced)
}

function parseLink (str) {
  const [href, text] = str
    .replace('<', '')
    .replace('>', '')
    .split('|')
  return text
    ? `[${text}](${href})`
    : `[${href}](${href})`
}
