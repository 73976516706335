import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { mdToHtml } from '../../utils/answerHandlers'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {
  Collapse,
  IconButton
} from '@material-ui/core'
const useStyles = makeStyles({
  tableContainer: {
    width: (props) => props.width || 'none',
    margin: '20px auto'
  },
  collapsibleRow: {
    '& > *': {
      borderBottom: 'unset',
      borderTop: '1px #e0e0e0 solid'
    }
  },
  clickableLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'blue'
  }
})

function CustomTable ({ columns, data, width, parseLink, collapsible, collapseComponent }) {
  const classes = useStyles({ width })
  const [expandedRow, setExpandedRow] = useState(null)

  const toggleRow = (rowIndex) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null)
    } else {
      setExpandedRow(rowIndex)
    }
  }

  const handleLinkClick = (action, rowNumber) => {
    if (action.handler) {
      action.handler(rowNumber)
    }
  }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {collapsible && <TableCell />}
            {columns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <TableRow className={collapsible ? classes.collapsibleRow : null}>
                {collapsible && (
                  <TableCell>
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => toggleRow(rowIndex)}
                    >
                      {expandedRow === rowIndex
                        ? (
                          <KeyboardArrowUpIcon />
                          )
                        : (
                          <KeyboardArrowDownIcon />
                          )}
                    </IconButton>
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {column.id.includes('action')
                      ? (
                        <span
                          className={classes.clickableLink}
                          onClick={() => handleLinkClick(row[column.id], rowIndex)}
                        >
                          {row[column.id].text}
                        </span>
                        )
                      : column.id.includes('colored')
                        ? parseLink
                            ? (
                              <div
                                style={{ color: row[column.id].color }}
                                dangerouslySetInnerHTML={{
                                  __html: mdToHtml(row[column.id].text)
                                }}
                              />
                              )
                            : (
                              <span style={{ color: row[column.id].color }}>
                                {row[column.id].text}
                              </span>
                              )
                        : parseLink
                          ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: mdToHtml(row[column.id])
                              }}
                            />
                            )
                          : (
                              row[column.id]
                            )}
                  </TableCell>
                ))}
              </TableRow>
              {collapsible && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={columns.length}>
                    <Collapse in={expandedRow === rowIndex}>
                      {React.cloneElement(collapseComponent, { rowIndex })}
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CustomTable
