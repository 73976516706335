export const CHECK_IN_CHECKLIST = [
  'Wandering Off',
  'Dev Work Preference',
  'Priority Checksum',
  'Alignment',
  'Happiness Check',
  'Engineer Growth',
  'Rapport',
  'Feedback to Dev',
  'Feedback to Manager',
  'Solicit Improvements',
  'Vision'
]
