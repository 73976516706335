// We get stand up responses via webhook that is async so the answers that come
// in aren't necessarily sorted in the order they were responded to. These
// variables are used to help with sorting them.
export const DAILY_STANDUP_REPORT_ID_SUFFIX = 'rid-standup-report-v2'
export const DAILY_STANDUP_QUESTION_ORDER = [
  'How many PRs have you approved since the last standup?',
  'How many PRs have you rejected since the last standup?',
  'Keeping the above in mind, what do you plan to accomplish before the next standup? Do not include reviewing PRs as part of your answer.',
  'Look at your plan from the previous standup. Did you successfully complete it?',
  'What prevented you from accomplishing those tasks?'
]
const WEEKLY_STANDUP_QUESTION_ORDER = [
  'What did your group accomplish last week?',
  'What is your group planning to work on this week?',
  'Were you able to complete the work you committed to last week?',
  'What prevented you from accomplishing those tasks?'
]

export function sortAnswers (answers, reportId = '') {
  // 1. Search
  const compileUserObject = () => {
    const uniqueUsers = findUniqueUsers()
    return sortAnswers(uniqueUsers)
  }

  // 2. Filter
  const findUniqueUsers = () => {
    const fullUserObj = []
    const uniqueArr = [...new Set(sortUsers())]
    for (const uniqueUser of uniqueArr) {
      fullUserObj.push(constructUserObj(uniqueUser))
    }
    return fullUserObj
  }
  // 3. Sort
  const sortUsers = () => {
    const userArr = []
    for (const item of answers) {
      userArr.push(item.username)
    }
    return userArr.sort()
  }

  // 4. Concat
  const constructUserObj = (uniqueUser) => {
    const userObj = { user: uniqueUser, responses: [] }
    for (const item of answers) {
      if (item.username === uniqueUser) {
        if (item.answer.charCodeAt(0) === 10) {
          item.answer = 'No response'
        }
        userObj.responses.push({
          created: item.created,
          reportId: item.report_id,
          question: item.question,
          answer: item.answer,
          answerId: item.answer_index,
          flagged: item.flagged,
          flagged_notes: item.flagged_notes,
          user_id: item.user_id
        })
      }
    }
    return userObj
  }

  // 5. Sort Answers
  const sortAnswers = (uniqueUser) => {
    return uniqueUser.map(user => {
      const order = user.responses[0].reportId.indexOf(DAILY_STANDUP_REPORT_ID_SUFFIX) === 0
        ? DAILY_STANDUP_QUESTION_ORDER
        : WEEKLY_STANDUP_QUESTION_ORDER

      return {
        ...user,
        responses: user.responses.sort((a, b) => {
          return order.indexOf(a.question) - order.indexOf(b.question)
        })
      }
    })
  }

  return compileUserObject()
}
