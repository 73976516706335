import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch
} from 'react-router-dom'
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Box,
  makeStyles,
  useTheme
} from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import { CompanyReports } from './CompanyReports/CompanyReports'
import EngineersView from './Engineers/EngineersView'
import { getSingleCompany } from '../api'
import WeeklyReports from './WeeklyReports/WeeklyReports'
import Spinner from './Spinner/Spinner'
import ActivityReports from './ActivityReports/ActivityReports'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))

export default function Company (props) {
  const classes = useStyles()
  const theme = useTheme()
  const { id } = useParams()
  const { path, url } = useRouteMatch()
  const [company, setCompany] = useState({})
  const [thrownError, setThrownError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const fn = (error, response) => {
    if (error) {
      setLoaded(true)
      setThrownError(error)
    } else {
      setLoaded(true)
      setCompany(response.data)
    }
  }
  useEffect(() => {
    getSingleCompany(id, fn)
  }, [id])

  return (
    <Grid>
      {thrownError
        ? (
          <Grid>{`ERROR: ${thrownError}`}</Grid>
          )
        : !loaded
            ? <Spinner />
            : (
              <Grid container direction='column' spacing={5} alignItems='center'>
                <Grid item>
                  <Typography variant='h3'>{company.name}</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction='row' spacing={3}>
                    <Grid item>
                      <Link to={`${url}/engineers`} className={classes.link}>
                        <Card
                          style={{
                            backgroundColor: window.location.pathname.includes(
                              'engineers'
                            )
                              ? theme.palette.action.disabled
                              : 'unset'
                          }}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                p={1}
                              >
                                <PeopleIcon fontSize='large' />
                              </Box>
                              <Typography gutterBottom variant='h5'>
                                Engineers
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to={`${url}/standups`} className={classes.link}>
                        <Card
                          style={{
                            backgroundColor: window.location.pathname.includes(
                              'standups'
                            )
                              ? theme.palette.action.disabled
                              : 'unset'
                          }}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                p={1}
                              >
                                <EventNoteIcon fontSize='large' />
                              </Box>
                              <Typography gutterBottom variant='h5'>
                                Standups
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to={`${url}/weekly-reports`} className={classes.link}>
                        <Card
                          style={{
                            backgroundColor: window.location.pathname.includes(
                              'weekly-reports'
                            )
                              ? theme.palette.action.disabled
                              : 'unset'
                          }}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                p={1}
                              >
                                <ShowChartIcon fontSize='large' />
                              </Box>
                              <Typography gutterBottom variant='h5'>
                                Weekly Reports
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to={`${url}/activity-reports`} className={classes.link}>
                        <Card
                          style={{
                            backgroundColor: window.location.pathname.includes(
                              'activity-reports'
                            )
                              ? theme.palette.action.disabled
                              : 'unset'
                          }}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                p={1}
                              >
                                <AssignmentIndIcon fontSize='large' />
                              </Box>
                              <Typography gutterBottom variant='h5'>
                                Activity Reports
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </Grid>
                  </Grid>
                  <Box style={{ margin: '20px 0px' }}>
                    <Switch>
                      <Route path={`${path}/engineers`}>
                        <EngineersView />
                      </Route>
                      <Route path={`${path}/standups`}>
                        <CompanyReports />
                      </Route>
                      <Route path={`${path}/weekly-reports`}>
                        <WeeklyReports />
                      </Route>
                      <Route path={`${path}/activity-reports`}>
                        <ActivityReports />
                      </Route>
                    </Switch>
                  </Box>
                </Grid>
              </Grid>
              )}
    </Grid>
  )
}
